import { GridColDef } from "@mui/x-data-grid";
import { WardRoundBlock } from "../WardRoundBlock";
import { AbstractDataGrid } from "../../../../ui/datagrid/AbstractDataGrid";
import { formatDateTime } from "../../../../../helpers/formatDateTime";
import { MedicineActionResponse } from "../../../../../model/actions/ActionResponse";

interface Props {
  data: MedicineActionResponse;
}

export const WardRoundMedicineAction = ({ data }: Props) => {
  const columns: GridColDef<MedicineActionResponse>[] = [
    {
      field: "takenAt",
      headerName: "Čas podání",
      flex: 1,
      renderCell: (params) => (
        <strong>{params.row.takenAt ? formatDateTime(params.row.takenAt) : "prášek byl vynechán"}</strong>
      ),
    },
  ];

  return (
    <WardRoundBlock title={data.type}>
      <AbstractDataGrid rows={[data]} columns={columns} getRowId={(row) => row.type} />
    </WardRoundBlock>
  );
};
