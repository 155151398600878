/* tslint:disable */
/* eslint-disable */
/**
 * HealthWarden Backend REST API Specification
 * HealthWarden Backend REST API Specification.
 *
 * The version of the OpenAPI document:
 * Contact: healthwarden@mild.blue
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString
} from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequestArgs, RequiredError } from './base';

/**
 * AccountDtoIn
 * @export
 * @interface AccountDtoIn
 */
export interface AccountDtoIn {
    /**
     *
     * @type {string}
     * @memberof AccountDtoIn
     */
    'username': string;
    /**
     *
     * @type {number}
     * @memberof AccountDtoIn
     */
    'patientsCount'?: number;
}
/**
 * AccountDtoOut
 * @export
 * @interface AccountDtoOut
 */
export interface AccountDtoOut {
    /**
     *
     * @type {string}
     * @memberof AccountDtoOut
     */
    'doctorId': string;
    /**
     *
     * @type {string}
     * @memberof AccountDtoOut
     */
    'username': string;
    /**
     *
     * @type {Array<string>}
     * @memberof AccountDtoOut
     */
    'patients': Array<string>;
}
/**
 *
 * @export
 * @interface ActionDtoIn
 */
export interface ActionDtoIn {
    /**
     *
     * @type {string}
     * @memberof ActionDtoIn
     */
    'id': string;
    /**
     *
     * @type {ActionType}
     * @memberof ActionDtoIn
     */
    'type': ActionType;
    /**
     *
     * @type {string}
     * @memberof ActionDtoIn
     */
    'text': string;
    /**
     *
     * @type {string}
     * @memberof ActionDtoIn
     */
    'description': string;
    /**
     *
     * @type {boolean}
     * @memberof ActionDtoIn
     */
    'enabled': boolean;
}
/**
 *
 * @export
 * @interface ActionDtoOut
 */
export interface ActionDtoOut {
    /**
     *
     * @type {string}
     * @memberof ActionDtoOut
     */
    'id': string;
    /**
     *
     * @type {ActionType}
     * @memberof ActionDtoOut
     */
    'type': ActionType;
    /**
     *
     * @type {string}
     * @memberof ActionDtoOut
     */
    'text': string;
    /**
     *
     * @type {string}
     * @memberof ActionDtoOut
     */
    'description': string;
    /**
     *
     * @type {string}
     * @memberof ActionDtoOut
     */
    'createdAt': string;
    /**
     *
     * @type {boolean}
     * @memberof ActionDtoOut
     */
    'enabled': boolean;
    /**
     *
     * @type {ActionDtoOutUserAnswer}
     * @memberof ActionDtoOut
     */
    'userAnswer'?: ActionDtoOutUserAnswer;
}
/**
 * @type ActionDtoOutUserAnswer
 * @export
 */
export type ActionDtoOutUserAnswer = MedicineActionResponseDtoOut | PhotoActionResponseDtoOut;

/**
 *
 * @export
 * @interface ActionResponseDtoOutBase
 */
export interface ActionResponseDtoOutBase {
    /**
     *
     * @type {string}
     * @memberof ActionResponseDtoOutBase
     */
    'discriminator': string;
    /**
     *
     * @type {ActionType}
     * @memberof ActionResponseDtoOutBase
     */
    'type': ActionType;
}
/**
 *
 * @export
 * @interface ActionTemplateDtoIn
 */
export interface ActionTemplateDtoIn {
    /**
     *
     * @type {ActionType}
     * @memberof ActionTemplateDtoIn
     */
    'type': ActionType;
    /**
     *
     * @type {boolean}
     * @memberof ActionTemplateDtoIn
     */
    'isEnabled': boolean;
    /**
     *
     * @type {string}
     * @memberof ActionTemplateDtoIn
     */
    'text': string;
    /**
     *
     * @type {string}
     * @memberof ActionTemplateDtoIn
     */
    'description': string;
}
/**
 *
 * @export
 * @interface ActionTemplateDtoOut
 */
export interface ActionTemplateDtoOut {
    /**
     *
     * @type {string}
     * @memberof ActionTemplateDtoOut
     */
    'id': string;
    /**
     *
     * @type {ActionType}
     * @memberof ActionTemplateDtoOut
     */
    'type': ActionType;
    /**
     *
     * @type {boolean}
     * @memberof ActionTemplateDtoOut
     */
    'isEnabled': boolean;
    /**
     *
     * @type {string}
     * @memberof ActionTemplateDtoOut
     */
    'text': string;
    /**
     *
     * @type {string}
     * @memberof ActionTemplateDtoOut
     */
    'description': string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ActionType {
    Photo = 'PHOTO',
    Medicine = 'MEDICINE'
}


/**
 * AnswerDtoIn
 * @export
 * @interface AnswerDtoIn
 */
export interface AnswerDtoIn {
    /**
     *
     * @type {string}
     * @memberof AnswerDtoIn
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof AnswerDtoIn
     */
    'text': string;
    /**
     *
     * @type {SeverityStatus}
     * @memberof AnswerDtoIn
     */
    'severity': SeverityStatus;
    /**
     *
     * @type {string}
     * @memberof AnswerDtoIn
     */
    'alertNote'?: string;
}
/**
 * AnswerDtoOut
 * @export
 * @interface AnswerDtoOut
 */
export interface AnswerDtoOut {
    /**
     *
     * @type {string}
     * @memberof AnswerDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof AnswerDtoOut
     */
    'text': string;
    /**
     *
     * @type {SeverityStatus}
     * @memberof AnswerDtoOut
     */
    'severity': SeverityStatus;
    /**
     *
     * @type {string}
     * @memberof AnswerDtoOut
     */
    'alertNote'?: string;
}
/**
 *
 * @export
 * @interface AnswerTemplateDtoIn
 */
export interface AnswerTemplateDtoIn {
    /**
     *
     * @type {string}
     * @memberof AnswerTemplateDtoIn
     */
    'text': string;
    /**
     *
     * @type {SeverityStatus}
     * @memberof AnswerTemplateDtoIn
     */
    'severity': SeverityStatus;
    /**
     *
     * @type {string}
     * @memberof AnswerTemplateDtoIn
     */
    'alertNote'?: string;
}
/**
 *
 * @export
 * @interface AnswerTemplateDtoOut
 */
export interface AnswerTemplateDtoOut {
    /**
     *
     * @type {string}
     * @memberof AnswerTemplateDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof AnswerTemplateDtoOut
     */
    'text': string;
    /**
     *
     * @type {SeverityStatus}
     * @memberof AnswerTemplateDtoOut
     */
    'severity': SeverityStatus;
    /**
     *
     * @type {string}
     * @memberof AnswerTemplateDtoOut
     */
    'alertNote'?: string;
}
/**
 * AnsweredQuestionDtoIn
 * @export
 * @interface AnsweredQuestionDtoIn
 */
export interface AnsweredQuestionDtoIn {
    /**
     *
     * @type {string}
     * @memberof AnsweredQuestionDtoIn
     */
    'questionId': string;
    /**
     *
     * @type {Array<string>}
     * @memberof AnsweredQuestionDtoIn
     */
    'answerIds': Array<string>;
    /**
     *
     * @type {string}
     * @memberof AnsweredQuestionDtoIn
     */
    'responseValue'?: string;
    /**
     *
     * @type {string}
     * @memberof AnsweredQuestionDtoIn
     */
    'note'?: string;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof AnsweredQuestionDtoIn
     */
    'bloodPressure'?: BloodPressureDtoInOut;
}
/**
 * AnsweredQuestionDtoOut
 * @export
 * @interface AnsweredQuestionDtoOut
 */
export interface AnsweredQuestionDtoOut {
    /**
     *
     * @type {QuestionDtoOut}
     * @memberof AnsweredQuestionDtoOut
     */
    'question': QuestionDtoOut;
    /**
     *
     * @type {Array<UserAnswerDtoOut>}
     * @memberof AnsweredQuestionDtoOut
     */
    'userAnswers': Array<UserAnswerDtoOut>;
    /**
     *
     * @type {string}
     * @memberof AnsweredQuestionDtoOut
     */
    'note'?: string;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof AnsweredQuestionDtoOut
     */
    'bloodPressure'?: BloodPressureDtoInOut;
}
/**
 * AnsweredQuestionnaireDtoOut
 * @export
 * @interface AnsweredQuestionnaireDtoOut
 */
export interface AnsweredQuestionnaireDtoOut {
    /**
     *
     * @type {string}
     * @memberof AnsweredQuestionnaireDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof AnsweredQuestionnaireDtoOut
     */
    'title': string;
    /**
     *
     * @type {Array<AnsweredQuestionDtoOut>}
     * @memberof AnsweredQuestionnaireDtoOut
     */
    'questions': Array<AnsweredQuestionDtoOut>;
    /**
     *
     * @type {string}
     * @memberof AnsweredQuestionnaireDtoOut
     */
    'answerAt'?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ApplicationEnvironment {
    Production = 'PRODUCTION',
    Staging = 'STAGING',
    Development = 'DEVELOPMENT'
}


/**
 * ApplicationInfoDto
 * @export
 * @interface ApplicationInfoDto
 */
export interface ApplicationInfoDto {
    /**
     *
     * @type {string}
     * @memberof ApplicationInfoDto
     */
    'version': string;
    /**
     *
     * @type {ApplicationEnvironment}
     * @memberof ApplicationInfoDto
     */
    'environment': ApplicationEnvironment;
}
/**
 *
 * @export
 * @interface AppointmentDeleteDtoIn
 */
export interface AppointmentDeleteDtoIn {
    /**
     *
     * @type {string}
     * @memberof AppointmentDeleteDtoIn
     */
    'externalId': string;
}
/**
 *
 * @export
 * @interface AppointmentDtoOut
 */
export interface AppointmentDtoOut {
    /**
     *
     * @type {string}
     * @memberof AppointmentDtoOut
     */
    'date': string;
    /**
     *
     * @type {string}
     * @memberof AppointmentDtoOut
     */
    'unit': string;
    /**
     *
     * @type {string}
     * @memberof AppointmentDtoOut
     */
    'location': string;
}
/**
 *
 * @export
 * @interface AppointmentUpdateDtoInOut
 */
export interface AppointmentUpdateDtoInOut {
    /**
     *
     * @type {string}
     * @memberof AppointmentUpdateDtoInOut
     */
    'externalId': string;
    /**
     *
     * @type {number}
     * @memberof AppointmentUpdateDtoInOut
     */
    'patientExternalId': number;
    /**
     *
     * @type {string}
     * @memberof AppointmentUpdateDtoInOut
     */
    'date': string;
    /**
     *
     * @type {string}
     * @memberof AppointmentUpdateDtoInOut
     */
    'unit': string;
    /**
     *
     * @type {string}
     * @memberof AppointmentUpdateDtoInOut
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof AppointmentUpdateDtoInOut
     */
    'location': string;
}
/**
 *
 * @export
 * @interface AssignCareDtoIn
 */
export interface AssignCareDtoIn {
    /**
     *
     * @type {string}
     * @memberof AssignCareDtoIn
     */
    'careTemplateId': string;
    /**
     * For care of type PREOPERATIVE, this date refers to the date of the medical procedure. For care of type POSTOPERATIVE, this date refers to the date of the patient\'s discharge from the hospital.
     * @type {string}
     * @memberof AssignCareDtoIn
     */
    'date': string;
}
/**
 *
 * @export
 * @interface BiometryDeleteDtoIn
 */
export interface BiometryDeleteDtoIn {
    /**
     *
     * @type {string}
     * @memberof BiometryDeleteDtoIn
     */
    'externalId': string;
}
/**
 *
 * @export
 * @interface BiometryUpdateDtoInOut
 */
export interface BiometryUpdateDtoInOut {
    /**
     *
     * @type {number}
     * @memberof BiometryUpdateDtoInOut
     */
    'patientExternalId': number;
    /**
     *
     * @type {string}
     * @memberof BiometryUpdateDtoInOut
     */
    'externalId': string;
    /**
     *
     * @type {string}
     * @memberof BiometryUpdateDtoInOut
     */
    'at'?: string;
    /**
     *
     * @type {number}
     * @memberof BiometryUpdateDtoInOut
     */
    'weight'?: number;
    /**
     *
     * @type {number}
     * @memberof BiometryUpdateDtoInOut
     */
    'height'?: number;
}
/**
 *
 * @export
 * @interface BloodPressureChallengeProgressDtoIn
 */
export interface BloodPressureChallengeProgressDtoIn extends ChallengeProgressDtoInBase {
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressureChallengeProgressDtoIn
     */
    'bloodPressure': BloodPressureDtoInOut;
    /**
     *
     * @type {GoalType}
     * @memberof BloodPressureChallengeProgressDtoIn
     */
    'type': GoalType;
}
/**
 *
 * @export
 * @interface BloodPressureChallengeProgressDtoInAllOf
 */
export interface BloodPressureChallengeProgressDtoInAllOf {
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressureChallengeProgressDtoInAllOf
     */
    'bloodPressure'?: BloodPressureDtoInOut;
    /**
     *
     * @type {GoalType}
     * @memberof BloodPressureChallengeProgressDtoInAllOf
     */
    'type'?: GoalType;
}
/**
 *
 * @export
 * @interface BloodPressureChallengeProgressDtoOut
 */
export interface BloodPressureChallengeProgressDtoOut extends ChallengeProgressDtoOutBase {
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressureChallengeProgressDtoOut
     */
    'bloodPressure': BloodPressureDtoInOut;
    /**
     *
     * @type {GoalType}
     * @memberof BloodPressureChallengeProgressDtoOut
     */
    'type': GoalType;
}
/**
 *
 * @export
 * @interface BloodPressureChallengeTemplateDtoIn
 */
export interface BloodPressureChallengeTemplateDtoIn extends ChallengeTemplateDtoInBase {
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressureChallengeTemplateDtoIn
     */
    'minGoal': BloodPressureDtoInOut;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressureChallengeTemplateDtoIn
     */
    'maxGoal': BloodPressureDtoInOut;
}
/**
 *
 * @export
 * @interface BloodPressureChallengeTemplateDtoInAllOf
 */
export interface BloodPressureChallengeTemplateDtoInAllOf {
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressureChallengeTemplateDtoInAllOf
     */
    'minGoal'?: BloodPressureDtoInOut;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressureChallengeTemplateDtoInAllOf
     */
    'maxGoal'?: BloodPressureDtoInOut;
}
/**
 *
 * @export
 * @interface BloodPressureChallengeTemplateDtoOut
 */
export interface BloodPressureChallengeTemplateDtoOut extends ChallengeTemplateDtoOutBase {
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressureChallengeTemplateDtoOut
     */
    'minGoal': BloodPressureDtoInOut;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressureChallengeTemplateDtoOut
     */
    'maxGoal': BloodPressureDtoInOut;
}
/**
 *
 * @export
 * @interface BloodPressureDtoInOut
 */
export interface BloodPressureDtoInOut {
    /**
     *
     * @type {number}
     * @memberof BloodPressureDtoInOut
     */
    'systolic': number;
    /**
     *
     * @type {number}
     * @memberof BloodPressureDtoInOut
     */
    'diastolic': number;
}
/**
 *
 * @export
 * @interface BloodPressurePatientChallengeDtoIn
 */
export interface BloodPressurePatientChallengeDtoIn extends PatientChallengeDtoIn {
    /**
     *
     * @type {string}
     * @memberof BloodPressurePatientChallengeDtoIn
     */
    'id': string;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressurePatientChallengeDtoIn
     */
    'minGoal': BloodPressureDtoInOut;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressurePatientChallengeDtoIn
     */
    'maxGoal': BloodPressureDtoInOut;
}
/**
 *
 * @export
 * @interface BloodPressurePatientChallengeDtoInAllOf
 */
export interface BloodPressurePatientChallengeDtoInAllOf {
    /**
     *
     * @type {string}
     * @memberof BloodPressurePatientChallengeDtoInAllOf
     */
    'id'?: string;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressurePatientChallengeDtoInAllOf
     */
    'minGoal'?: BloodPressureDtoInOut;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressurePatientChallengeDtoInAllOf
     */
    'maxGoal'?: BloodPressureDtoInOut;
}
/**
 *
 * @export
 * @interface BloodPressurePatientChallengeDtoOut
 */
export interface BloodPressurePatientChallengeDtoOut extends PatientChallengeDtoOut {
    /**
     *
     * @type {string}
     * @memberof BloodPressurePatientChallengeDtoOut
     */
    'id': string;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressurePatientChallengeDtoOut
     */
    'minGoal': BloodPressureDtoInOut;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressurePatientChallengeDtoOut
     */
    'maxGoal': BloodPressureDtoInOut;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressurePatientChallengeDtoOut
     */
    'goalValue'?: BloodPressureDtoInOut;
}
/**
 *
 * @export
 * @interface BloodPressurePatientChallengeDtoOutAllOf
 */
export interface BloodPressurePatientChallengeDtoOutAllOf {
    /**
     *
     * @type {string}
     * @memberof BloodPressurePatientChallengeDtoOutAllOf
     */
    'id'?: string;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressurePatientChallengeDtoOutAllOf
     */
    'minGoal'?: BloodPressureDtoInOut;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressurePatientChallengeDtoOutAllOf
     */
    'maxGoal'?: BloodPressureDtoInOut;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressurePatientChallengeDtoOutAllOf
     */
    'goalValue'?: BloodPressureDtoInOut;
}
/**
 *
 * @export
 * @interface BloodPressureProgressSummaryDtoOut
 */
export interface BloodPressureProgressSummaryDtoOut {
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressureProgressSummaryDtoOut
     */
    'minGoal': BloodPressureDtoInOut;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof BloodPressureProgressSummaryDtoOut
     */
    'maxGoal': BloodPressureDtoInOut;
    /**
     *
     * @type {Array<ProgressDtoOutBloodPressureDtoInOut>}
     * @memberof BloodPressureProgressSummaryDtoOut
     */
    'progress': Array<ProgressDtoOutBloodPressureDtoInOut>;
}
/**
 *
 * @export
 * @interface CareDtoOut
 */
export interface CareDtoOut {
    /**
     *
     * @type {string}
     * @memberof CareDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CareDtoOut
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof CareDtoOut
     */
    'description': string;
    /**
     *
     * @type {CareType}
     * @memberof CareDtoOut
     */
    'type': CareType;
    /**
     *
     * @type {string}
     * @memberof CareDtoOut
     */
    'treatmentBegin': string;
    /**
     *
     * @type {string}
     * @memberof CareDtoOut
     */
    'treatmentEnd'?: string;
    /**
     *
     * @type {Array<GetPatientChallenges200ResponseInner>}
     * @memberof CareDtoOut
     */
    'challenges': Array<GetPatientChallenges200ResponseInner>;
    /**
     *
     * @type {Array<GetWardRound200Response>}
     * @memberof CareDtoOut
     */
    'wardRounds': Array<GetWardRound200Response>;
    /**
     *
     * @type {string}
     * @memberof CareDtoOut
     */
    'createdAt': string;
    /**
     *
     * @type {UpcomingWardRoundOccurrenceDtoOut}
     * @memberof CareDtoOut
     */
    'upcomingWardRoundOccurrence': UpcomingWardRoundOccurrenceDtoOut;
    /**
     *
     * @type {DoctorDtoOut}
     * @memberof CareDtoOut
     */
    'assignedBy'?: DoctorDtoOut;
}
/**
 *
 * @export
 * @interface CareTemplateDtoIn
 */
export interface CareTemplateDtoIn {
    /**
     *
     * @type {string}
     * @memberof CareTemplateDtoIn
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof CareTemplateDtoIn
     */
    'description': string;
    /**
     *
     * @type {CareType}
     * @memberof CareTemplateDtoIn
     */
    'type': CareType;
    /**
     *
     * @type {Array<CareTemplateDtoInChallengesInner>}
     * @memberof CareTemplateDtoIn
     */
    'challenges': Array<CareTemplateDtoInChallengesInner>;
    /**
     *
     * @type {Array<WardRoundEntryDtoIn>}
     * @memberof CareTemplateDtoIn
     */
    'wardRounds': Array<WardRoundEntryDtoIn>;
}
/**
 * @type CareTemplateDtoInChallengesInner
 * @export
 */
export type CareTemplateDtoInChallengesInner = BloodPressureChallengeTemplateDtoIn | NumericChallengeTemplateDtoIn;

/**
 *
 * @export
 * @interface CareTemplateDtoOut
 */
export interface CareTemplateDtoOut {
    /**
     *
     * @type {string}
     * @memberof CareTemplateDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CareTemplateDtoOut
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof CareTemplateDtoOut
     */
    'description': string;
    /**
     *
     * @type {CareType}
     * @memberof CareTemplateDtoOut
     */
    'type': CareType;
    /**
     *
     * @type {Array<CareTemplateDtoOutChallengesInner>}
     * @memberof CareTemplateDtoOut
     */
    'challenges': Array<CareTemplateDtoOutChallengesInner>;
    /**
     *
     * @type {Array<WardRoundEntryDtoOut>}
     * @memberof CareTemplateDtoOut
     */
    'wardRounds': Array<WardRoundEntryDtoOut>;
}
/**
 * @type CareTemplateDtoOutChallengesInner
 * @export
 */
export type CareTemplateDtoOutChallengesInner = BloodPressureChallengeTemplateDtoOut | NumericChallengeTemplateDtoOut;

/**
 *
 * @export
 * @interface CareTemplateImportExportDto
 */
export interface CareTemplateImportExportDto {
    /**
     *
     * @type {string}
     * @memberof CareTemplateImportExportDto
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof CareTemplateImportExportDto
     */
    'description': string;
    /**
     *
     * @type {CareType}
     * @memberof CareTemplateImportExportDto
     */
    'type': CareType;
    /**
     *
     * @type {Array<QuestionTemplateExportDto>}
     * @memberof CareTemplateImportExportDto
     */
    'questions': Array<QuestionTemplateExportDto>;
    /**
     *
     * @type {Array<QuestionnaireImportDto>}
     * @memberof CareTemplateImportExportDto
     */
    'questionnaires': Array<QuestionnaireImportDto>;
    /**
     *
     * @type {Array<CareTemplateDtoInChallengesInner>}
     * @memberof CareTemplateImportExportDto
     */
    'challenges': Array<CareTemplateDtoInChallengesInner>;
    /**
     *
     * @type {Array<WardRoundTemplateImportDto>}
     * @memberof CareTemplateImportExportDto
     */
    'wardRoundTemplates': Array<WardRoundTemplateImportDto>;
    /**
     *
     * @type {Array<WardRoundImportDto>}
     * @memberof CareTemplateImportExportDto
     */
    'wardRounds': Array<WardRoundImportDto>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CareType {
    Preoperative = 'PREOPERATIVE',
    Postoperative = 'POSTOPERATIVE'
}


/**
 *
 * @export
 * @interface ChallengeProgressDtoInBase
 */
export interface ChallengeProgressDtoInBase {
    /**
     *
     * @type {string}
     * @memberof ChallengeProgressDtoInBase
     */
    'discriminator': string;
}
/**
 *
 * @export
 * @interface ChallengeProgressDtoOutBase
 */
export interface ChallengeProgressDtoOutBase {
    /**
     *
     * @type {string}
     * @memberof ChallengeProgressDtoOutBase
     */
    'discriminator': string;
}
/**
 *
 * @export
 * @interface ChallengeProgressSummaryDtoOut
 */
export interface ChallengeProgressSummaryDtoOut {
    /**
     *
     * @type {MinProgressSummaryDtoOut}
     * @memberof ChallengeProgressSummaryDtoOut
     */
    'steps': MinProgressSummaryDtoOut;
    /**
     *
     * @type {MinProgressSummaryDtoOut}
     * @memberof ChallengeProgressSummaryDtoOut
     */
    'water': MinProgressSummaryDtoOut;
    /**
     *
     * @type {MinProgressSummaryDtoOut}
     * @memberof ChallengeProgressSummaryDtoOut
     */
    'sleep': MinProgressSummaryDtoOut;
    /**
     *
     * @type {MinMaxProgressSummaryDtoOut}
     * @memberof ChallengeProgressSummaryDtoOut
     */
    'heart': MinMaxProgressSummaryDtoOut;
    /**
     *
     * @type {BloodPressureProgressSummaryDtoOut}
     * @memberof ChallengeProgressSummaryDtoOut
     */
    'bloodPressure': BloodPressureProgressSummaryDtoOut;
}
/**
 *
 * @export
 * @interface ChallengeTemplateDtoInBase
 */
export interface ChallengeTemplateDtoInBase {
    /**
     *
     * @type {GoalType}
     * @memberof ChallengeTemplateDtoInBase
     */
    'goalType': GoalType;
    /**
     *
     * @type {string}
     * @memberof ChallengeTemplateDtoInBase
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof ChallengeTemplateDtoInBase
     */
    'description': string;
    /**
     *
     * @type {boolean}
     * @memberof ChallengeTemplateDtoInBase
     */
    'enabled': boolean;
    /**
     *
     * @type {string}
     * @memberof ChallengeTemplateDtoInBase
     */
    'discriminator': string;
}
/**
 *
 * @export
 * @interface ChallengeTemplateDtoOutBase
 */
export interface ChallengeTemplateDtoOutBase {
    /**
     *
     * @type {string}
     * @memberof ChallengeTemplateDtoOutBase
     */
    'id': string;
    /**
     *
     * @type {GoalType}
     * @memberof ChallengeTemplateDtoOutBase
     */
    'goalType': GoalType;
    /**
     *
     * @type {string}
     * @memberof ChallengeTemplateDtoOutBase
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof ChallengeTemplateDtoOutBase
     */
    'description': string;
    /**
     *
     * @type {boolean}
     * @memberof ChallengeTemplateDtoOutBase
     */
    'enabled': boolean;
    /**
     *
     * @type {string}
     * @memberof ChallengeTemplateDtoOutBase
     */
    'discriminator': string;
}
/**
 *
 * @export
 * @interface ChallengesOverviewDtoOut
 */
export interface ChallengesOverviewDtoOut {
    /**
     *
     * @type {string}
     * @memberof ChallengesOverviewDtoOut
     */
    'id': string;
    /**
     *
     * @type {GoalType}
     * @memberof ChallengesOverviewDtoOut
     */
    'type': GoalType;
    /**
     *
     * @type {boolean}
     * @memberof ChallengesOverviewDtoOut
     */
    'isGoalMet': boolean;
}
/**
 *
 * @export
 * @interface ContactPersonDto
 */
export interface ContactPersonDto {
    /**
     *
     * @type {string}
     * @memberof ContactPersonDto
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof ContactPersonDto
     */
    'title': string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CriticalPatientType {
    Answer = 'CRITICAL_ANSWER',
    HeartRate = 'CRITICAL_HEART_RATE',
    Pressure = 'CRITICAL_PRESSURE'
}


/**
 *
 * @export
 * @interface DeviceDtoIn
 */
export interface DeviceDtoIn {
    /**
     *
     * @type {string}
     * @memberof DeviceDtoIn
     */
    'deviceId': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoIn
     */
    'patientId': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoIn
     */
    'os': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoIn
     */
    'osVersion': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoIn
     */
    'brand': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoIn
     */
    'model': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoIn
     */
    'appVersion': string;
}
/**
 *
 * @export
 * @interface DeviceDtoOut
 */
export interface DeviceDtoOut {
    /**
     *
     * @type {string}
     * @memberof DeviceDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoOut
     */
    'deviceId': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoOut
     */
    'patientId': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoOut
     */
    'os': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoOut
     */
    'osVersion': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoOut
     */
    'brand': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoOut
     */
    'model': string;
    /**
     *
     * @type {string}
     * @memberof DeviceDtoOut
     */
    'appVersion': string;
}
/**
 *
 * @export
 * @interface DiagnosesUpdateDtoInOut
 */
export interface DiagnosesUpdateDtoInOut {
    /**
     *
     * @type {number}
     * @memberof DiagnosesUpdateDtoInOut
     */
    'patientExternalId': number;
    /**
     *
     * @type {Set<string>}
     * @memberof DiagnosesUpdateDtoInOut
     */
    'diagnoses': Set<string>;
}
/**
 * DoctorDtoOut
 * @export
 * @interface DoctorDtoOut
 */
export interface DoctorDtoOut {
    /**
     *
     * @type {string}
     * @memberof DoctorDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof DoctorDtoOut
     */
    'firstName': string;
    /**
     *
     * @type {string}
     * @memberof DoctorDtoOut
     */
    'lastName': string;
}
/**
 *
 * @export
 * @interface EndCareDtoIn
 */
export interface EndCareDtoIn {
    /**
     *
     * @type {string}
     * @memberof EndCareDtoIn
     */
    'treatmentEnd': string;
}
/**
 *
 * @export
 * @interface EndPatientCareDtoIn
 */
export interface EndPatientCareDtoIn {
    /**
     *
     * @type {string}
     * @memberof EndPatientCareDtoIn
     */
    'care-id': string;
    /**
     *
     * @type {number}
     * @memberof EndPatientCareDtoIn
     */
    'patient-id': number;
}
/**
 * @type GetPatientChallenges200ResponseInner
 * @export
 */
export type GetPatientChallenges200ResponseInner = BloodPressurePatientChallengeDtoOut | NumericPatientChallengeDtoOut;

/**
 * @type GetWardRound200Response
 * @export
 */
export type GetWardRound200Response = OneTimeWardRoundOutDto | RecurringWardRoundOutDto;

/**
 *
 * @export
 * @enum {string}
 */

export enum GoalType {
    Steps = 'STEPS',
    Water = 'WATER',
    Sleep = 'SLEEP',
    HeartRate = 'HEART_RATE',
    Pressure = 'PRESSURE'
}


/**
 *
 * @export
 * @interface HeartRateChallengeProgressDtoIn
 */
export interface HeartRateChallengeProgressDtoIn extends ChallengeProgressDtoInBase {
    /**
     *
     * @type {number}
     * @memberof HeartRateChallengeProgressDtoIn
     */
    'heartRate': number;
    /**
     *
     * @type {GoalType}
     * @memberof HeartRateChallengeProgressDtoIn
     */
    'type': GoalType;
}
/**
 *
 * @export
 * @interface HeartRateChallengeProgressDtoInAllOf
 */
export interface HeartRateChallengeProgressDtoInAllOf {
    /**
     *
     * @type {number}
     * @memberof HeartRateChallengeProgressDtoInAllOf
     */
    'heartRate'?: number;
    /**
     *
     * @type {GoalType}
     * @memberof HeartRateChallengeProgressDtoInAllOf
     */
    'type'?: GoalType;
}
/**
 *
 * @export
 * @interface HeartRateChallengeProgressDtoOut
 */
export interface HeartRateChallengeProgressDtoOut extends ChallengeProgressDtoOutBase {
    /**
     *
     * @type {number}
     * @memberof HeartRateChallengeProgressDtoOut
     */
    'heartRate': number;
    /**
     *
     * @type {GoalType}
     * @memberof HeartRateChallengeProgressDtoOut
     */
    'type': GoalType;
}
/**
 *
 * @export
 * @interface HospitalizationDeleteDtoIn
 */
export interface HospitalizationDeleteDtoIn {
    /**
     *
     * @type {string}
     * @memberof HospitalizationDeleteDtoIn
     */
    'externalId': string;
}
/**
 *
 * @export
 * @interface HospitalizationUpdateDtoInOut
 */
export interface HospitalizationUpdateDtoInOut {
    /**
     *
     * @type {string}
     * @memberof HospitalizationUpdateDtoInOut
     */
    'externalId': string;
    /**
     *
     * @type {number}
     * @memberof HospitalizationUpdateDtoInOut
     */
    'patientExternalId': number;
    /**
     *
     * @type {string}
     * @memberof HospitalizationUpdateDtoInOut
     */
    'startedAt': string;
    /**
     *
     * @type {string}
     * @memberof HospitalizationUpdateDtoInOut
     */
    'endedAt'?: string;
    /**
     *
     * @type {string}
     * @memberof HospitalizationUpdateDtoInOut
     */
    'ward': string;
    /**
     *
     * @type {string}
     * @memberof HospitalizationUpdateDtoInOut
     */
    'unit': string;
    /**
     *
     * @type {string}
     * @memberof HospitalizationUpdateDtoInOut
     */
    'bed': string;
}
/**
 *
 * @export
 * @interface InfoNotificationDtoInOut
 */
export interface InfoNotificationDtoInOut {
    /**
     *
     * @type {string}
     * @memberof InfoNotificationDtoInOut
     */
    'messageTime': string;
}
/**
 * LastMqMessageInfoDtoOut
 * @export
 * @interface LastMqMessageInfoDtoOut
 */
export interface LastMqMessageInfoDtoOut {
    /**
     *
     * @type {string}
     * @memberof LastMqMessageInfoDtoOut
     */
    'messageTime': string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum Locale {
    EnUs = 'EN_US',
    CzCs = 'CZ_CS'
}


/**
 * LoginDtoIn
 * @export
 * @interface LoginDtoIn
 */
export interface LoginDtoIn {
    /**
     *
     * @type {string}
     * @memberof LoginDtoIn
     */
    'username': string;
    /**
     *
     * @type {string}
     * @memberof LoginDtoIn
     */
    'password': string;
    /**
     *
     * @type {UserRole}
     * @memberof LoginDtoIn
     */
    'role': UserRole;
}
/**
 *
 * @export
 * @interface MedicineActionResponseDtoOut
 */
export interface MedicineActionResponseDtoOut extends ActionResponseDtoOutBase {
    /**
     *
     * @type {string}
     * @memberof MedicineActionResponseDtoOut
     */
    'takenAt'?: string;
}
/**
 *
 * @export
 * @interface MedicineActionResponseDtoOutAllOf
 */
export interface MedicineActionResponseDtoOutAllOf {
    /**
     *
     * @type {string}
     * @memberof MedicineActionResponseDtoOutAllOf
     */
    'takenAt'?: string;
}
/**
 *
 * @export
 * @interface MedicineIntakeDtoIn
 */
export interface MedicineIntakeDtoIn {
    /**
     *
     * @type {string}
     * @memberof MedicineIntakeDtoIn
     */
    'takenAt'?: string;
}
/**
 *
 * @export
 * @interface MinMaxProgressSummaryDtoOut
 */
export interface MinMaxProgressSummaryDtoOut {
    /**
     *
     * @type {number}
     * @memberof MinMaxProgressSummaryDtoOut
     */
    'minGoal': number;
    /**
     *
     * @type {number}
     * @memberof MinMaxProgressSummaryDtoOut
     */
    'maxGoal': number;
    /**
     *
     * @type {Array<ProgressDtoOutDouble>}
     * @memberof MinMaxProgressSummaryDtoOut
     */
    'progress': Array<ProgressDtoOutDouble>;
}
/**
 *
 * @export
 * @interface MinProgressSummaryDtoOut
 */
export interface MinProgressSummaryDtoOut {
    /**
     *
     * @type {number}
     * @memberof MinProgressSummaryDtoOut
     */
    'minGoal': number;
    /**
     *
     * @type {Array<ProgressDtoOutDouble>}
     * @memberof MinProgressSummaryDtoOut
     */
    'progress': Array<ProgressDtoOutDouble>;
}
/**
 *
 * @export
 * @interface NumericChallengeTemplateDtoIn
 */
export interface NumericChallengeTemplateDtoIn extends ChallengeTemplateDtoInBase {
    /**
     *
     * @type {number}
     * @memberof NumericChallengeTemplateDtoIn
     */
    'minGoal': number;
    /**
     *
     * @type {number}
     * @memberof NumericChallengeTemplateDtoIn
     */
    'maxGoal': number;
}
/**
 *
 * @export
 * @interface NumericChallengeTemplateDtoInAllOf
 */
export interface NumericChallengeTemplateDtoInAllOf {
    /**
     *
     * @type {number}
     * @memberof NumericChallengeTemplateDtoInAllOf
     */
    'minGoal'?: number;
    /**
     *
     * @type {number}
     * @memberof NumericChallengeTemplateDtoInAllOf
     */
    'maxGoal'?: number;
}
/**
 *
 * @export
 * @interface NumericChallengeTemplateDtoOut
 */
export interface NumericChallengeTemplateDtoOut extends ChallengeTemplateDtoOutBase {
    /**
     *
     * @type {number}
     * @memberof NumericChallengeTemplateDtoOut
     */
    'minGoal': number;
    /**
     *
     * @type {number}
     * @memberof NumericChallengeTemplateDtoOut
     */
    'maxGoal': number;
}
/**
 *
 * @export
 * @interface NumericPatientChallengeDtoIn
 */
export interface NumericPatientChallengeDtoIn extends PatientChallengeDtoIn {
    /**
     *
     * @type {string}
     * @memberof NumericPatientChallengeDtoIn
     */
    'id': string;
    /**
     *
     * @type {number}
     * @memberof NumericPatientChallengeDtoIn
     */
    'minGoal': number;
    /**
     *
     * @type {number}
     * @memberof NumericPatientChallengeDtoIn
     */
    'maxGoal': number;
}
/**
 *
 * @export
 * @interface NumericPatientChallengeDtoInAllOf
 */
export interface NumericPatientChallengeDtoInAllOf {
    /**
     *
     * @type {string}
     * @memberof NumericPatientChallengeDtoInAllOf
     */
    'id'?: string;
    /**
     *
     * @type {number}
     * @memberof NumericPatientChallengeDtoInAllOf
     */
    'minGoal'?: number;
    /**
     *
     * @type {number}
     * @memberof NumericPatientChallengeDtoInAllOf
     */
    'maxGoal'?: number;
}
/**
 *
 * @export
 * @interface NumericPatientChallengeDtoOut
 */
export interface NumericPatientChallengeDtoOut extends PatientChallengeDtoOut {
    /**
     *
     * @type {string}
     * @memberof NumericPatientChallengeDtoOut
     */
    'id': string;
    /**
     *
     * @type {number}
     * @memberof NumericPatientChallengeDtoOut
     */
    'minGoal': number;
    /**
     *
     * @type {number}
     * @memberof NumericPatientChallengeDtoOut
     */
    'maxGoal': number;
    /**
     *
     * @type {number}
     * @memberof NumericPatientChallengeDtoOut
     */
    'goalValue'?: number;
}
/**
 *
 * @export
 * @interface NumericPatientChallengeDtoOutAllOf
 */
export interface NumericPatientChallengeDtoOutAllOf {
    /**
     *
     * @type {string}
     * @memberof NumericPatientChallengeDtoOutAllOf
     */
    'id'?: string;
    /**
     *
     * @type {number}
     * @memberof NumericPatientChallengeDtoOutAllOf
     */
    'minGoal'?: number;
    /**
     *
     * @type {number}
     * @memberof NumericPatientChallengeDtoOutAllOf
     */
    'maxGoal'?: number;
    /**
     *
     * @type {number}
     * @memberof NumericPatientChallengeDtoOutAllOf
     */
    'goalValue'?: number;
}
/**
 *
 * @export
 * @interface OneTimeWardRoundInDto
 */
export interface OneTimeWardRoundInDto extends WardRoundDtoInBase {
    /**
     *
     * @type {string}
     * @memberof OneTimeWardRoundInDto
     */
    'plannedOn': string;
    /**
     *
     * @type {WardRoundType}
     * @memberof OneTimeWardRoundInDto
     */
    'type': WardRoundType;
}
/**
 *
 * @export
 * @interface OneTimeWardRoundInDtoAllOf
 */
export interface OneTimeWardRoundInDtoAllOf {
    /**
     *
     * @type {string}
     * @memberof OneTimeWardRoundInDtoAllOf
     */
    'plannedOn'?: string;
    /**
     *
     * @type {WardRoundType}
     * @memberof OneTimeWardRoundInDtoAllOf
     */
    'type'?: WardRoundType;
}
/**
 *
 * @export
 * @interface OneTimeWardRoundOutDto
 */
export interface OneTimeWardRoundOutDto extends WardRoundDtoOutBase {
    /**
     *
     * @type {string}
     * @memberof OneTimeWardRoundOutDto
     */
    'plannedOn': string;
    /**
     *
     * @type {WardRoundOccurrenceDtoOut}
     * @memberof OneTimeWardRoundOutDto
     */
    'occurrence': WardRoundOccurrenceDtoOut;
}
/**
 *
 * @export
 * @interface OneTimeWardRoundOutDtoAllOf
 */
export interface OneTimeWardRoundOutDtoAllOf {
    /**
     *
     * @type {string}
     * @memberof OneTimeWardRoundOutDtoAllOf
     */
    'plannedOn'?: string;
    /**
     *
     * @type {WardRoundOccurrenceDtoOut}
     * @memberof OneTimeWardRoundOutDtoAllOf
     */
    'occurrence'?: WardRoundOccurrenceDtoOut;
}
/**
 * PagebleDtoOut
 * @export
 * @interface PagebleDtoOutPatientDtoOut
 */
export interface PagebleDtoOutPatientDtoOut {
    /**
     *
     * @type {Array<PatientDtoOut>}
     * @memberof PagebleDtoOutPatientDtoOut
     */
    'items': Array<PatientDtoOut>;
    /**
     *
     * @type {number}
     * @memberof PagebleDtoOutPatientDtoOut
     */
    'totalElements': number;
    /**
     *
     * @type {number}
     * @memberof PagebleDtoOutPatientDtoOut
     */
    'totalPages': number;
    /**
     *
     * @type {number}
     * @memberof PagebleDtoOutPatientDtoOut
     */
    'pageNumber': number;
    /**
     *
     * @type {number}
     * @memberof PagebleDtoOutPatientDtoOut
     */
    'pageSize': number;
    /**
     *
     * @type {number}
     * @memberof PagebleDtoOutPatientDtoOut
     */
    'offset': number;
}
/**
 *
 * @export
 * @interface PasswordDtoIn
 */
export interface PasswordDtoIn {
    /**
     *
     * @type {string}
     * @memberof PasswordDtoIn
     */
    'password': string;
}
/**
 *
 * @export
 * @interface PasswordDtoOut
 */
export interface PasswordDtoOut {
    /**
     *
     * @type {string}
     * @memberof PasswordDtoOut
     */
    'password': string;
}
/**
 *
 * @export
 * @interface PatientCareDtoIn
 */
export interface PatientCareDtoIn {
    /**
     *
     * @type {number}
     * @memberof PatientCareDtoIn
     */
    'patientExternalId': number;
    /**
     *
     * @type {string}
     * @memberof PatientCareDtoIn
     */
    'careTemplateId': string;
    /**
     *
     * @type {string}
     * @memberof PatientCareDtoIn
     */
    'doctorUsername': string;
}
/**
 *
 * @export
 * @interface PatientCareDtoOut
 */
export interface PatientCareDtoOut {
    /**
     *
     * @type {string}
     * @memberof PatientCareDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof PatientCareDtoOut
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof PatientCareDtoOut
     */
    'description': string;
    /**
     *
     * @type {string}
     * @memberof PatientCareDtoOut
     */
    'treatmentBegin': string;
    /**
     *
     * @type {string}
     * @memberof PatientCareDtoOut
     */
    'treatmentEnd'?: string;
    /**
     *
     * @type {Array<GetPatientChallenges200ResponseInner>}
     * @memberof PatientCareDtoOut
     */
    'challenges': Array<GetPatientChallenges200ResponseInner>;
    /**
     *
     * @type {string}
     * @memberof PatientCareDtoOut
     */
    'createdAt': string;
    /**
     *
     * @type {UpcomingWardRoundOccurrenceDtoOut}
     * @memberof PatientCareDtoOut
     */
    'upcomingWardRoundOccurrence': UpcomingWardRoundOccurrenceDtoOut;
    /**
     *
     * @type {DoctorDtoOut}
     * @memberof PatientCareDtoOut
     */
    'assignedBy'?: DoctorDtoOut;
}
/**
 *
 * @export
 * @interface PatientChallengeDtoIn
 */
export interface PatientChallengeDtoIn {
    /**
     *
     * @type {string}
     * @memberof PatientChallengeDtoIn
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof PatientChallengeDtoIn
     */
    'discriminator': string;
    /**
     *
     * @type {boolean}
     * @memberof PatientChallengeDtoIn
     */
    'enabled': boolean;
}
/**
 *
 * @export
 * @interface PatientChallengeDtoOut
 */
export interface PatientChallengeDtoOut {
    /**
     *
     * @type {string}
     * @memberof PatientChallengeDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof PatientChallengeDtoOut
     */
    'discriminator': string;
    /**
     *
     * @type {boolean}
     * @memberof PatientChallengeDtoOut
     */
    'enabled': boolean;
    /**
     *
     * @type {string}
     * @memberof PatientChallengeDtoOut
     */
    'patientId': string;
    /**
     *
     * @type {GoalType}
     * @memberof PatientChallengeDtoOut
     */
    'goalType': GoalType;
    /**
     *
     * @type {string}
     * @memberof PatientChallengeDtoOut
     */
    'created': string;
}
/**
 * PatientChallengeUpdateOfValuesDtoIn
 * @export
 * @interface PatientChallengeUpdateOfValuesDtoIn
 */
export interface PatientChallengeUpdateOfValuesDtoIn {
    /**
     *
     * @type {string}
     * @memberof PatientChallengeUpdateOfValuesDtoIn
     */
    'id'?: string;
    /**
     *
     * @type {number}
     * @memberof PatientChallengeUpdateOfValuesDtoIn
     */
    'numberValue'?: number;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof PatientChallengeUpdateOfValuesDtoIn
     */
    'bloodPressureValue'?: BloodPressureDtoInOut;
}
/**
 *
 * @export
 * @interface PatientCriticalResolutionDtoIn
 */
export interface PatientCriticalResolutionDtoIn {
    /**
     *
     * @type {string}
     * @memberof PatientCriticalResolutionDtoIn
     */
    'comment': string;
}
/**
 *
 * @export
 * @interface PatientCriticalResolutionDtoOut
 */
export interface PatientCriticalResolutionDtoOut {
    /**
     *
     * @type {DoctorDtoOut}
     * @memberof PatientCriticalResolutionDtoOut
     */
    'resolvedBy': DoctorDtoOut;
    /**
     *
     * @type {string}
     * @memberof PatientCriticalResolutionDtoOut
     */
    'resolvedAt': string;
    /**
     *
     * @type {string}
     * @memberof PatientCriticalResolutionDtoOut
     */
    'comment': string;
}
/**
 *
 * @export
 * @interface PatientDeleteDtoIn
 */
export interface PatientDeleteDtoIn {
    /**
     *
     * @type {number}
     * @memberof PatientDeleteDtoIn
     */
    'externalId': number;
}
/**
 * PatientDetailDtoOut
 * @export
 * @interface PatientDetailDtoOut
 */
export interface PatientDetailDtoOut {
    /**
     *
     * @type {string}
     * @memberof PatientDetailDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof PatientDetailDtoOut
     */
    'firstName': string;
    /**
     *
     * @type {string}
     * @memberof PatientDetailDtoOut
     */
    'lastName': string;
    /**
     *
     * @type {string}
     * @memberof PatientDetailDtoOut
     */
    'dateOfBirth'?: string;
    /**
     *
     * @type {string}
     * @memberof PatientDetailDtoOut
     */
    'personalNumber': string;
    /**
     *
     * @type {string}
     * @memberof PatientDetailDtoOut
     */
    'phoneNumber': string;
    /**
     *
     * @type {boolean}
     * @memberof PatientDetailDtoOut
     */
    'critical': boolean;
    /**
     *
     * @type {SeverityDetailDtoOut}
     * @memberof PatientDetailDtoOut
     */
    'severityDetail'?: SeverityDetailDtoOut;
    /**
     *
     * @type {number}
     * @memberof PatientDetailDtoOut
     */
    'age'?: number;
    /**
     *
     * @type {string}
     * @memberof PatientDetailDtoOut
     */
    'email'?: string;
    /**
     *
     * @type {Sex}
     * @memberof PatientDetailDtoOut
     */
    'sex'?: Sex;
    /**
     *
     * @type {Array<string>}
     * @memberof PatientDetailDtoOut
     */
    'diagnoses': Array<string>;
    /**
     *
     * @type {number}
     * @memberof PatientDetailDtoOut
     */
    'weight'?: number;
    /**
     *
     * @type {number}
     * @memberof PatientDetailDtoOut
     */
    'height'?: number;
    /**
     *
     * @type {number}
     * @memberof PatientDetailDtoOut
     */
    'achievements'?: number;
    /**
     *
     * @type {DoctorDtoOut}
     * @memberof PatientDetailDtoOut
     */
    'doctor': DoctorDtoOut;
    /**
     *
     * @type {ProcedureDtoOut}
     * @memberof PatientDetailDtoOut
     */
    'procedure'?: ProcedureDtoOut;
    /**
     *
     * @type {AppointmentDtoOut}
     * @memberof PatientDetailDtoOut
     */
    'appointment'?: AppointmentDtoOut;
    /**
     *
     * @type {PatientCareDtoOut}
     * @memberof PatientDetailDtoOut
     */
    'care'?: PatientCareDtoOut;
    /**
     *
     * @type {Array<PatientCareDtoOut>}
     * @memberof PatientDetailDtoOut
     */
    'endedCares': Array<PatientCareDtoOut>;
    /**
     *
     * @type {string}
     * @memberof PatientDetailDtoOut
     */
    'firstLoginAt'?: string;
    /**
     *
     * @type {string}
     * @memberof PatientDetailDtoOut
     */
    'lastActivityAt'?: string;
    /**
     *
     * @type {Array<TagDtoOut>}
     * @memberof PatientDetailDtoOut
     */
    'tags': Array<TagDtoOut>;
    /**
     *
     * @type {Array<PatientCriticalResolutionDtoOut>}
     * @memberof PatientDetailDtoOut
     */
    'criticalResolutions': Array<PatientCriticalResolutionDtoOut>;
}
/**
 * PatientDtoOut
 * @export
 * @interface PatientDtoOut
 */
export interface PatientDtoOut {
    /**
     *
     * @type {string}
     * @memberof PatientDtoOut
     */
    'id': string;
    /**
     *
     * @type {number}
     * @memberof PatientDtoOut
     */
    'externalId'?: number;
    /**
     *
     * @type {string}
     * @memberof PatientDtoOut
     */
    'username': string;
    /**
     *
     * @type {string}
     * @memberof PatientDtoOut
     */
    'firstName': string;
    /**
     *
     * @type {string}
     * @memberof PatientDtoOut
     */
    'lastName': string;
    /**
     *
     * @type {string}
     * @memberof PatientDtoOut
     */
    'dateOfBirth'?: string;
    /**
     *
     * @type {string}
     * @memberof PatientDtoOut
     */
    'personalNumber': string;
    /**
     *
     * @type {string}
     * @memberof PatientDtoOut
     */
    'phoneNumber': string;
    /**
     *
     * @type {number}
     * @memberof PatientDtoOut
     */
    'age'?: number;
    /**
     *
     * @type {string}
     * @memberof PatientDtoOut
     */
    'email'?: string;
    /**
     *
     * @type {Sex}
     * @memberof PatientDtoOut
     */
    'sex'?: Sex;
    /**
     *
     * @type {boolean}
     * @memberof PatientDtoOut
     */
    'critical': boolean;
    /**
     *
     * @type {string}
     * @memberof PatientDtoOut
     */
    'firstLoginAt'?: string;
    /**
     *
     * @type {string}
     * @memberof PatientDtoOut
     */
    'lastActivityAt'?: string;
    /**
     *
     * @type {string}
     * @memberof PatientDtoOut
     */
    'careName'?: string;
    /**
     *
     * @type {Array<TagDtoOut>}
     * @memberof PatientDtoOut
     */
    'tags': Array<TagDtoOut>;
    /**
     *
     * @type {ContactPersonDto}
     * @memberof PatientDtoOut
     */
    'contactPerson': ContactPersonDto;
}
/**
 *
 * @export
 * @interface PatientEventDtoOut
 */
export interface PatientEventDtoOut {
    /**
     *
     * @type {string}
     * @memberof PatientEventDtoOut
     */
    'date': string;
    /**
     *
     * @type {PatientEventType}
     * @memberof PatientEventDtoOut
     */
    'type': PatientEventType;
    /**
     *
     * @type {QuestionnaireSummaryDtoOut}
     * @memberof PatientEventDtoOut
     */
    'questionnaire'?: QuestionnaireSummaryDtoOut;
    /**
     *
     * @type {string}
     * @memberof PatientEventDtoOut
     */
    'wardRoundId'?: string;
    /**
     *
     * @type {string}
     * @memberof PatientEventDtoOut
     */
    'wardRoundOccurrenceId'?: string;
    /**
     *
     * @type {Array<ChallengesOverviewDtoOut>}
     * @memberof PatientEventDtoOut
     */
    'challengesOverview': Array<ChallengesOverviewDtoOut>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PatientEventType {
    QuestionnaireAnswered = 'QUESTIONNAIRE_ANSWERED',
    ChallengesOverview = 'CHALLENGES_OVERVIEW'
}


/**
 * PatientFileDto
 * @export
 * @interface PatientFileDto
 */
export interface PatientFileDto {
    /**
     *
     * @type {string}
     * @memberof PatientFileDto
     */
    'id': string;
    /**
     *
     * @type {PatientDtoOut}
     * @memberof PatientFileDto
     */
    'patient': PatientDtoOut;
    /**
     *
     * @type {ActionDtoOut}
     * @memberof PatientFileDto
     */
    'action': ActionDtoOut;
    /**
     *
     * @type {string}
     * @memberof PatientFileDto
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof PatientFileDto
     */
    'type'?: string;
    /**
     *
     * @type {string}
     * @memberof PatientFileDto
     */
    'content': string;
    /**
     *
     * @type {string}
     * @memberof PatientFileDto
     */
    'uploadAt': string;
}
/**
 *
 * @export
 * @interface PatientUpdateDtoInOut
 */
export interface PatientUpdateDtoInOut {
    /**
     *
     * @type {number}
     * @memberof PatientUpdateDtoInOut
     */
    'externalId': number;
    /**
     *
     * @type {string}
     * @memberof PatientUpdateDtoInOut
     */
    'firstName': string;
    /**
     *
     * @type {string}
     * @memberof PatientUpdateDtoInOut
     */
    'username': string;
    /**
     *
     * @type {string}
     * @memberof PatientUpdateDtoInOut
     */
    'lastName': string;
    /**
     *
     * @type {string}
     * @memberof PatientUpdateDtoInOut
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof PatientUpdateDtoInOut
     */
    'personalNumber': string;
    /**
     *
     * @type {string}
     * @memberof PatientUpdateDtoInOut
     */
    'dateOfBirth'?: string;
    /**
     *
     * @type {string}
     * @memberof PatientUpdateDtoInOut
     */
    'sex'?: string;
}
/**
 * PayloadDto
 * @export
 * @interface PayloadDto
 */
export interface PayloadDto {
    /**
     * Form field errors
     * @type {{ [key: string]: string; }}
     * @memberof PayloadDto
     */
    'formFieldErrors'?: { [key: string]: string; };
}
/**
 *
 * @export
 * @interface PhotoActionResponseDtoIn
 */
export interface PhotoActionResponseDtoIn {
    /**
     *
     * @type {string}
     * @memberof PhotoActionResponseDtoIn
     */
    'fileName': string;
    /**
     *
     * @type {string}
     * @memberof PhotoActionResponseDtoIn
     */
    'contentType': string;
    /**
     * Base64 encoded file content
     * @type {string}
     * @memberof PhotoActionResponseDtoIn
     */
    'content': string;
}
/**
 *
 * @export
 * @interface PhotoActionResponseDtoOut
 */
export interface PhotoActionResponseDtoOut extends ActionResponseDtoOutBase {
    /**
     *
     * @type {string}
     * @memberof PhotoActionResponseDtoOut
     */
    'fileId': string;
    /**
     *
     * @type {string}
     * @memberof PhotoActionResponseDtoOut
     */
    'fileName': string;
    /**
     *
     * @type {string}
     * @memberof PhotoActionResponseDtoOut
     */
    'content': string;
}
/**
 *
 * @export
 * @interface PhotoActionResponseDtoOutAllOf
 */
export interface PhotoActionResponseDtoOutAllOf {
    /**
     *
     * @type {string}
     * @memberof PhotoActionResponseDtoOutAllOf
     */
    'fileId'?: string;
    /**
     *
     * @type {string}
     * @memberof PhotoActionResponseDtoOutAllOf
     */
    'fileName'?: string;
    /**
     *
     * @type {string}
     * @memberof PhotoActionResponseDtoOutAllOf
     */
    'content'?: string;
}
/**
 *
 * @export
 * @interface ProcedureDeleteDtoIn
 */
export interface ProcedureDeleteDtoIn {
    /**
     *
     * @type {string}
     * @memberof ProcedureDeleteDtoIn
     */
    'externalId': string;
}
/**
 *
 * @export
 * @interface ProcedureDtoOut
 */
export interface ProcedureDtoOut {
    /**
     *
     * @type {string}
     * @memberof ProcedureDtoOut
     */
    'at'?: string;
    /**
     *
     * @type {string}
     * @memberof ProcedureDtoOut
     */
    'name': string;
}
/**
 *
 * @export
 * @interface ProcedureUpdateDtoInOut
 */
export interface ProcedureUpdateDtoInOut {
    /**
     *
     * @type {string}
     * @memberof ProcedureUpdateDtoInOut
     */
    'externalId': string;
    /**
     *
     * @type {number}
     * @memberof ProcedureUpdateDtoInOut
     */
    'patientExternalId': number;
    /**
     *
     * @type {string}
     * @memberof ProcedureUpdateDtoInOut
     */
    'at'?: string;
    /**
     *
     * @type {string}
     * @memberof ProcedureUpdateDtoInOut
     */
    'name': string;
}
/**
 *
 * @export
 * @interface ProgressDtoOutBloodPressureDtoInOut
 */
export interface ProgressDtoOutBloodPressureDtoInOut {
    /**
     *
     * @type {string}
     * @memberof ProgressDtoOutBloodPressureDtoInOut
     */
    'timestamp': string;
    /**
     *
     * @type {BloodPressureDtoInOut}
     * @memberof ProgressDtoOutBloodPressureDtoInOut
     */
    'value': BloodPressureDtoInOut;
}
/**
 *
 * @export
 * @interface ProgressDtoOutDouble
 */
export interface ProgressDtoOutDouble {
    /**
     *
     * @type {string}
     * @memberof ProgressDtoOutDouble
     */
    'timestamp': string;
    /**
     *
     * @type {number}
     * @memberof ProgressDtoOutDouble
     */
    'value': number;
}
/**
 *
 * @export
 * @interface PushTokenRegistrationDto
 */
export interface PushTokenRegistrationDto {
    /**
     *
     * @type {string}
     * @memberof PushTokenRegistrationDto
     */
    'token': string;
}
/**
 *
 * @export
 * @interface PushTokenRegistrationResponseDto
 */
export interface PushTokenRegistrationResponseDto {
    /**
     *
     * @type {string}
     * @memberof PushTokenRegistrationResponseDto
     */
    'tokenId': string;
}
/**
 *
 * @export
 * @interface QuestionBaseTemplateDtoOut
 */
export interface QuestionBaseTemplateDtoOut {
    /**
     *
     * @type {string}
     * @memberof QuestionBaseTemplateDtoOut
     */
    'id': string;
    /**
     *
     * @type {QuestionType}
     * @memberof QuestionBaseTemplateDtoOut
     */
    'type': QuestionType;
    /**
     *
     * @type {string}
     * @memberof QuestionBaseTemplateDtoOut
     */
    'text': string;
    /**
     *
     * @type {Array<AnswerTemplateDtoOut>}
     * @memberof QuestionBaseTemplateDtoOut
     */
    'answers': Array<AnswerTemplateDtoOut>;
    /**
     *
     * @type {boolean}
     * @memberof QuestionBaseTemplateDtoOut
     */
    'required': boolean;
}
/**
 *
 * @export
 * @interface QuestionConditionExportDto
 */
export interface QuestionConditionExportDto {
    /**
     *
     * @type {number}
     * @memberof QuestionConditionExportDto
     */
    'questionTemplateIndex': number;
    /**
     *
     * @type {number}
     * @memberof QuestionConditionExportDto
     */
    'answerTemplateIndex': number;
}
/**
 *
 * @export
 * @interface QuestionConditionTemplateDtoIn
 */
export interface QuestionConditionTemplateDtoIn {
    /**
     *
     * @type {string}
     * @memberof QuestionConditionTemplateDtoIn
     */
    'answerTemplateId': string;
}
/**
 *
 * @export
 * @interface QuestionConditionTemplateDtoOut
 */
export interface QuestionConditionTemplateDtoOut {
    /**
     *
     * @type {QuestionBaseTemplateDtoOut}
     * @memberof QuestionConditionTemplateDtoOut
     */
    'questionTemplate': QuestionBaseTemplateDtoOut;
    /**
     *
     * @type {AnswerTemplateDtoOut}
     * @memberof QuestionConditionTemplateDtoOut
     */
    'answerTemplate': AnswerTemplateDtoOut;
}
/**
 * QuestionDeleteDtoOut
 * @export
 * @interface QuestionDeleteDtoOut
 */
export interface QuestionDeleteDtoOut {
    /**
     *
     * @type {QuestionDtoOut}
     * @memberof QuestionDeleteDtoOut
     */
    'question': QuestionDtoOut;
    /**
     *
     * @type {string}
     * @memberof QuestionDeleteDtoOut
     */
    'message': string;
    /**
     *
     * @type {string}
     * @memberof QuestionDeleteDtoOut
     */
    'userMessage': string;
    /**
     *
     * @type {Array<QuestionTransitionDtoOut>}
     * @memberof QuestionDeleteDtoOut
     */
    'previousQuestions': Array<QuestionTransitionDtoOut>;
}
/**
 * QuestionDtoIn
 * @export
 * @interface QuestionDtoIn
 */
export interface QuestionDtoIn {
    /**
     *
     * @type {string}
     * @memberof QuestionDtoIn
     */
    'questionnaireId': string;
    /**
     *
     * @type {string}
     * @memberof QuestionDtoIn
     */
    'text': string;
    /**
     *
     * @type {QuestionType}
     * @memberof QuestionDtoIn
     */
    'type': QuestionType;
    /**
     *
     * @type {Array<AnswerDtoIn>}
     * @memberof QuestionDtoIn
     */
    'answers': Array<AnswerDtoIn>;
    /**
     *
     * @type {Array<QuestionTransitionDtoIn>}
     * @memberof QuestionDtoIn
     * @deprecated
     */
    'transitions': Array<QuestionTransitionDtoIn>;
    /**
     *
     * @type {boolean}
     * @memberof QuestionDtoIn
     */
    'required': boolean;
}
/**
 * QuestionDtoOut
 * @export
 * @interface QuestionDtoOut
 */
export interface QuestionDtoOut {
    /**
     *
     * @type {string}
     * @memberof QuestionDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof QuestionDtoOut
     */
    'text': string;
    /**
     *
     * @type {QuestionType}
     * @memberof QuestionDtoOut
     */
    'type': QuestionType;
    /**
     *
     * @type {Array<AnswerDtoOut>}
     * @memberof QuestionDtoOut
     */
    'answers': Array<AnswerDtoOut>;
    /**
     *
     * @type {Array<QuestionTransitionDtoOut>}
     * @memberof QuestionDtoOut
     * @deprecated
     */
    'transitions': Array<QuestionTransitionDtoOut>;
    /**
     * Whether the question should be shown to the user.Based on previous answers within the same ward round occurrence.Null if cannot be determined.
     * @type {boolean}
     * @memberof QuestionDtoOut
     */
    'shouldShow'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof QuestionDtoOut
     */
    'required': boolean;
}
/**
 *
 * @export
 * @interface QuestionTemplateDtoIn
 */
export interface QuestionTemplateDtoIn {
    /**
     *
     * @type {QuestionType}
     * @memberof QuestionTemplateDtoIn
     */
    'type': QuestionType;
    /**
     *
     * @type {string}
     * @memberof QuestionTemplateDtoIn
     */
    'text': string;
    /**
     *
     * @type {Array<AnswerTemplateDtoIn>}
     * @memberof QuestionTemplateDtoIn
     */
    'answers': Array<AnswerTemplateDtoIn>;
    /**
     *
     * @type {Array<QuestionConditionTemplateDtoIn>}
     * @memberof QuestionTemplateDtoIn
     */
    'conditions': Array<QuestionConditionTemplateDtoIn>;
    /**
     *
     * @type {boolean}
     * @memberof QuestionTemplateDtoIn
     */
    'required': boolean;
}
/**
 *
 * @export
 * @interface QuestionTemplateDtoOut
 */
export interface QuestionTemplateDtoOut {
    /**
     *
     * @type {string}
     * @memberof QuestionTemplateDtoOut
     */
    'id': string;
    /**
     *
     * @type {QuestionType}
     * @memberof QuestionTemplateDtoOut
     */
    'type': QuestionType;
    /**
     *
     * @type {string}
     * @memberof QuestionTemplateDtoOut
     */
    'text': string;
    /**
     *
     * @type {Array<AnswerTemplateDtoOut>}
     * @memberof QuestionTemplateDtoOut
     */
    'answers': Array<AnswerTemplateDtoOut>;
    /**
     *
     * @type {boolean}
     * @memberof QuestionTemplateDtoOut
     */
    'required': boolean;
    /**
     *
     * @type {Array<QuestionConditionTemplateDtoOut>}
     * @memberof QuestionTemplateDtoOut
     */
    'conditions': Array<QuestionConditionTemplateDtoOut>;
}
/**
 *
 * @export
 * @interface QuestionTemplateExportDto
 */
export interface QuestionTemplateExportDto {
    /**
     *
     * @type {QuestionType}
     * @memberof QuestionTemplateExportDto
     */
    'type': QuestionType;
    /**
     *
     * @type {string}
     * @memberof QuestionTemplateExportDto
     */
    'text': string;
    /**
     *
     * @type {Array<AnswerTemplateDtoIn>}
     * @memberof QuestionTemplateExportDto
     */
    'answers': Array<AnswerTemplateDtoIn>;
    /**
     *
     * @type {Array<QuestionConditionExportDto>}
     * @memberof QuestionTemplateExportDto
     */
    'conditions': Array<QuestionConditionExportDto>;
    /**
     *
     * @type {boolean}
     * @memberof QuestionTemplateExportDto
     */
    'required': boolean;
}
/**
 * QuestionTransitionDtoIn
 * @export
 * @interface QuestionTransitionDtoIn
 */
export interface QuestionTransitionDtoIn {
    /**
     *
     * @type {Array<string>}
     * @memberof QuestionTransitionDtoIn
     */
    'answersIds': Array<string>;
    /**
     *
     * @type {string}
     * @memberof QuestionTransitionDtoIn
     */
    'questionId': string;
}
/**
 * QuestionTransitionDtoOut
 * @export
 * @interface QuestionTransitionDtoOut
 */
export interface QuestionTransitionDtoOut {
    /**
     *
     * @type {Array<string>}
     * @memberof QuestionTransitionDtoOut
     */
    'answersIds': Array<string>;
    /**
     *
     * @type {QuestionDtoOut}
     * @memberof QuestionTransitionDtoOut
     */
    'question': QuestionDtoOut;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum QuestionType {
    SingleChoice = 'SINGLE_CHOICE',
    MultiChoice = 'MULTI_CHOICE',
    FreeText = 'FREE_TEXT',
    Number = 'NUMBER',
    Rating = 'RATING',
    WellBeing = 'WELL_BEING',
    BloodPressure = 'BLOOD_PRESSURE',
    Information = 'INFORMATION'
}


/**
 * QuestionnaireDtoIn
 * @export
 * @interface QuestionnaireDtoIn
 */
export interface QuestionnaireDtoIn {
    /**
     *
     * @type {string}
     * @memberof QuestionnaireDtoIn
     */
    'title': string;
    /**
     *
     * @type {string}
     * @memberof QuestionnaireDtoIn
     */
    'firstQuestionId'?: string;
    /**
     *
     * @type {string}
     * @memberof QuestionnaireDtoIn
     */
    'patientId': string;
}
/**
 * QuestionnaireDtoOut
 * @export
 * @interface QuestionnaireDtoOut
 */
export interface QuestionnaireDtoOut {
    /**
     *
     * @type {string}
     * @memberof QuestionnaireDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof QuestionnaireDtoOut
     */
    'title': string;
    /**
     *
     * @type {Array<QuestionDtoOut>}
     * @memberof QuestionnaireDtoOut
     */
    'questions': Array<QuestionDtoOut>;
    /**
     *
     * @type {QuestionDtoOut}
     * @memberof QuestionnaireDtoOut
     */
    'firstQuestion'?: QuestionDtoOut;
}
/**
 *
 * @export
 * @interface QuestionnaireImportDto
 */
export interface QuestionnaireImportDto {
    /**
     *
     * @type {string}
     * @memberof QuestionnaireImportDto
     */
    'title': string;
    /**
     *
     * @type {Array<number>}
     * @memberof QuestionnaireImportDto
     */
    'questions': Array<number>;
}
/**
 *
 * @export
 * @interface QuestionnaireSummaryDtoOut
 */
export interface QuestionnaireSummaryDtoOut {
    /**
     *
     * @type {string}
     * @memberof QuestionnaireSummaryDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof QuestionnaireSummaryDtoOut
     */
    'title': string;
    /**
     *
     * @type {boolean}
     * @memberof QuestionnaireSummaryDtoOut
     */
    'isCompleted': boolean;
}
/**
 *
 * @export
 * @interface QuestionnaireTemplateDtoIn
 */
export interface QuestionnaireTemplateDtoIn {
    /**
     *
     * @type {string}
     * @memberof QuestionnaireTemplateDtoIn
     */
    'title': string;
    /**
     *
     * @type {Array<string>}
     * @memberof QuestionnaireTemplateDtoIn
     */
    'questions': Array<string>;
}
/**
 *
 * @export
 * @interface QuestionnaireTemplateDtoOut
 */
export interface QuestionnaireTemplateDtoOut {
    /**
     *
     * @type {string}
     * @memberof QuestionnaireTemplateDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof QuestionnaireTemplateDtoOut
     */
    'title': string;
    /**
     *
     * @type {Array<QuestionTemplateDtoOut>}
     * @memberof QuestionnaireTemplateDtoOut
     */
    'questions': Array<QuestionTemplateDtoOut>;
}
/**
 *
 * @export
 * @interface RecurringWardRoundInDto
 */
export interface RecurringWardRoundInDto extends WardRoundDtoInBase {
    /**
     *
     * @type {number}
     * @memberof RecurringWardRoundInDto
     */
    'repetitionValue': number;
    /**
     *
     * @type {RepetitionGranularity}
     * @memberof RecurringWardRoundInDto
     */
    'repetitionGranularity': RepetitionGranularity;
    /**
     *
     * @type {string}
     * @memberof RecurringWardRoundInDto
     */
    'repetitionStart': string;
    /**
     *
     * @type {string}
     * @memberof RecurringWardRoundInDto
     */
    'repetitionEnd': string;
    /**
     *
     * @type {WardRoundType}
     * @memberof RecurringWardRoundInDto
     */
    'type': WardRoundType;
}
/**
 *
 * @export
 * @interface RecurringWardRoundInDtoAllOf
 */
export interface RecurringWardRoundInDtoAllOf {
    /**
     *
     * @type {number}
     * @memberof RecurringWardRoundInDtoAllOf
     */
    'repetitionValue'?: number;
    /**
     *
     * @type {RepetitionGranularity}
     * @memberof RecurringWardRoundInDtoAllOf
     */
    'repetitionGranularity'?: RepetitionGranularity;
    /**
     *
     * @type {string}
     * @memberof RecurringWardRoundInDtoAllOf
     */
    'repetitionStart'?: string;
    /**
     *
     * @type {string}
     * @memberof RecurringWardRoundInDtoAllOf
     */
    'repetitionEnd'?: string;
    /**
     *
     * @type {WardRoundType}
     * @memberof RecurringWardRoundInDtoAllOf
     */
    'type'?: WardRoundType;
}
/**
 *
 * @export
 * @interface RecurringWardRoundOutDto
 */
export interface RecurringWardRoundOutDto extends WardRoundDtoOutBase {
    /**
     *
     * @type {number}
     * @memberof RecurringWardRoundOutDto
     */
    'repetitionValue': number;
    /**
     *
     * @type {RepetitionGranularity}
     * @memberof RecurringWardRoundOutDto
     */
    'repetitionGranularity': RepetitionGranularity;
    /**
     *
     * @type {string}
     * @memberof RecurringWardRoundOutDto
     */
    'repetitionStart': string;
    /**
     *
     * @type {string}
     * @memberof RecurringWardRoundOutDto
     */
    'repetitionEnd': string;
    /**
     *
     * @type {Array<WardRoundOccurrenceDtoOut>}
     * @memberof RecurringWardRoundOutDto
     */
    'occurrences': Array<WardRoundOccurrenceDtoOut>;
    /**
     *
     * @type {WardRoundOccurrenceDtoOut}
     * @memberof RecurringWardRoundOutDto
     */
    'currentOccurrence'?: WardRoundOccurrenceDtoOut;
}
/**
 *
 * @export
 * @interface RecurringWardRoundOutDtoAllOf
 */
export interface RecurringWardRoundOutDtoAllOf {
    /**
     *
     * @type {number}
     * @memberof RecurringWardRoundOutDtoAllOf
     */
    'repetitionValue'?: number;
    /**
     *
     * @type {RepetitionGranularity}
     * @memberof RecurringWardRoundOutDtoAllOf
     */
    'repetitionGranularity'?: RepetitionGranularity;
    /**
     *
     * @type {string}
     * @memberof RecurringWardRoundOutDtoAllOf
     */
    'repetitionStart'?: string;
    /**
     *
     * @type {string}
     * @memberof RecurringWardRoundOutDtoAllOf
     */
    'repetitionEnd'?: string;
    /**
     *
     * @type {Array<WardRoundOccurrenceDtoOut>}
     * @memberof RecurringWardRoundOutDtoAllOf
     */
    'occurrences'?: Array<WardRoundOccurrenceDtoOut>;
    /**
     *
     * @type {WardRoundOccurrenceDtoOut}
     * @memberof RecurringWardRoundOutDtoAllOf
     */
    'currentOccurrence'?: WardRoundOccurrenceDtoOut;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum RepetitionGranularity {
    Minute = 'MINUTE',
    Hour = 'HOUR',
    Day = 'DAY',
    Week = 'WEEK',
    Month = 'MONTH'
}


/**
 * ResponseDto
 * @export
 * @interface ResponseDto
 */
export interface ResponseDto {
    /**
     * Message
     * @type {string}
     * @memberof ResponseDto
     */
    'message': string;
    /**
     * Status code
     * @type {number}
     * @memberof ResponseDto
     */
    'statusCode': number;
    /**
     * Timestamp
     * @type {string}
     * @memberof ResponseDto
     */
    'timestamp': string;
    /**
     *
     * @type {PayloadDto}
     * @memberof ResponseDto
     */
    'payload': PayloadDto;
    /**
     * User message
     * @type {string}
     * @memberof ResponseDto
     */
    'userMessage'?: string;
}
/**
 * SeverityDetailDtoOut
 * @export
 * @interface SeverityDetailDtoOut
 */
export interface SeverityDetailDtoOut {
    /**
     *
     * @type {Array<string>}
     * @memberof SeverityDetailDtoOut
     */
    'messages': Array<string>;
    /**
     *
     * @type {Array<CriticalPatientType>}
     * @memberof SeverityDetailDtoOut
     */
    'types': Array<CriticalPatientType>;
    /**
     *
     * @type {{ [key: string]: Array<AnsweredQuestionDtoOut>; }}
     * @memberof SeverityDetailDtoOut
     */
    'criticalAnswers': { [key: string]: Array<AnsweredQuestionDtoOut>; };
    /**
     *
     * @type {boolean}
     * @memberof SeverityDetailDtoOut
     */
    'isCritical': boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum SeverityStatus {
    High = 'HIGH',
    Low = 'LOW',
    No = 'NO'
}


/**
 *
 * @export
 * @enum {string}
 */

export enum Sex {
    Male = 'MALE',
    Female = 'FEMALE',
    Undefined = 'UNDEFINED'
}


/**
 * StatusDto
 * @export
 * @interface StatusDto
 */
export interface StatusDto {
    /**
     *
     * @type {string}
     * @memberof StatusDto
     */
    'status': string;
}
/**
 *
 * @export
 * @interface TagCategoryDtoIn
 */
export interface TagCategoryDtoIn {
    /**
     *
     * @type {string}
     * @memberof TagCategoryDtoIn
     */
    'name': string;
}
/**
 *
 * @export
 * @interface TagCategoryDtoOut
 */
export interface TagCategoryDtoOut {
    /**
     *
     * @type {string}
     * @memberof TagCategoryDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof TagCategoryDtoOut
     */
    'name': string;
}
/**
 *
 * @export
 * @interface TagDtoIn
 */
export interface TagDtoIn {
    /**
     *
     * @type {string}
     * @memberof TagDtoIn
     */
    'categoryId': string;
    /**
     *
     * @type {string}
     * @memberof TagDtoIn
     */
    'value': string;
}
/**
 *
 * @export
 * @interface TagDtoOut
 */
export interface TagDtoOut {
    /**
     *
     * @type {string}
     * @memberof TagDtoOut
     */
    'id': string;
    /**
     *
     * @type {TagCategoryDtoOut}
     * @memberof TagDtoOut
     */
    'category': TagCategoryDtoOut;
    /**
     *
     * @type {string}
     * @memberof TagDtoOut
     */
    'value': string;
}
/**
 *
 * @export
 * @interface TokenDeletedResponseDto
 */
export interface TokenDeletedResponseDto {
    /**
     *
     * @type {boolean}
     * @memberof TokenDeletedResponseDto
     */
    'deleted': boolean;
}
/**
 * @type TrackProgressRequest
 * @export
 */
export type TrackProgressRequest = BloodPressureChallengeProgressDtoIn | HeartRateChallengeProgressDtoIn;

/**
 *
 * @export
 * @interface UpcomingWardRoundOccurrenceDtoOut
 */
export interface UpcomingWardRoundOccurrenceDtoOut {
    /**
     *
     * @type {string}
     * @memberof UpcomingWardRoundOccurrenceDtoOut
     */
    'wardRoundId': string;
    /**
     *
     * @type {string}
     * @memberof UpcomingWardRoundOccurrenceDtoOut
     */
    'wardRoundOccurrenceId': string;
    /**
     *
     * @type {string}
     * @memberof UpcomingWardRoundOccurrenceDtoOut
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof UpcomingWardRoundOccurrenceDtoOut
     */
    'occurredAt': string;
}
/**
 * @type UpdatePatientChallengesRequestInner
 * @export
 */
export type UpdatePatientChallengesRequestInner = BloodPressurePatientChallengeDtoIn | NumericPatientChallengeDtoIn;

/**
 * @type UpdateWardRoundRequest
 * @export
 */
export type UpdateWardRoundRequest = OneTimeWardRoundInDto | RecurringWardRoundInDto;

/**
 *
 * @export
 * @interface UpsertPatientDtoIn
 */
export interface UpsertPatientDtoIn {
    /**
     *
     * @type {string}
     * @memberof UpsertPatientDtoIn
     */
    'firstName': string;
    /**
     *
     * @type {string}
     * @memberof UpsertPatientDtoIn
     */
    'lastName': string;
    /**
     *
     * @type {string}
     * @memberof UpsertPatientDtoIn
     */
    'personalNumber'?: string;
    /**
     * List of UUIDs of the tags to assign to the patient.
     * @type {Array<string>}
     * @memberof UpsertPatientDtoIn
     */
    'tags': Array<string>;
    /**
     *
     * @type {string}
     * @memberof UpsertPatientDtoIn
     */
    'phoneNumber'?: string;
    /**
     *
     * @type {string}
     * @memberof UpsertPatientDtoIn
     */
    'email'?: string;
    /**
     *
     * @type {Sex}
     * @memberof UpsertPatientDtoIn
     */
    'sex': Sex;
    /**
     *
     * @type {ContactPersonDto}
     * @memberof UpsertPatientDtoIn
     */
    'contactPerson': ContactPersonDto;
}
/**
 *
 * @export
 * @interface UserAnswerDtoOut
 */
export interface UserAnswerDtoOut {
    /**
     *
     * @type {string}
     * @memberof UserAnswerDtoOut
     */
    'answerId'?: string;
    /**
     *
     * @type {string}
     * @memberof UserAnswerDtoOut
     */
    'answerText': string;
}
/**
 * UserDtoOut
 * @export
 * @interface UserDtoOut
 */
export interface UserDtoOut {
    /**
     *
     * @type {string}
     * @memberof UserDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof UserDtoOut
     */
    'username': string;
    /**
     *
     * @type {Locale}
     * @memberof UserDtoOut
     */
    'locale': Locale;
    /**
     *
     * @type {Set<UserRole>}
     * @memberof UserDtoOut
     */
    'roles': Set<UserRole>;
    /**
     *
     * @type {string}
     * @memberof UserDtoOut
     */
    'firstName': string;
    /**
     *
     * @type {string}
     * @memberof UserDtoOut
     */
    'lastName': string;
    /**
     *
     * @type {DoctorDtoOut}
     * @memberof UserDtoOut
     */
    'doctor'?: DoctorDtoOut;
    /**
     *
     * @type {PatientDtoOut}
     * @memberof UserDtoOut
     */
    'patient'?: PatientDtoOut;
    /**
     *
     * @type {string}
     * @memberof UserDtoOut
     */
    'lastLoginAt'?: string;
    /**
     *
     * @type {string}
     * @memberof UserDtoOut
     */
    'firstLoginAt'?: string;
    /**
     *
     * @type {string}
     * @memberof UserDtoOut
     */
    'lastActivityAt'?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum UserRole {
    Administrator = 'ADMINISTRATOR',
    Doctor = 'DOCTOR',
    Patient = 'PATIENT',
    Service = 'SERVICE'
}


/**
 * UserWithJwtDtoOut
 * @export
 * @interface UserWithJwtDtoOut
 */
export interface UserWithJwtDtoOut {
    /**
     *
     * @type {UserDtoOut}
     * @memberof UserWithJwtDtoOut
     */
    'user': UserDtoOut;
    /**
     *
     * @type {string}
     * @memberof UserWithJwtDtoOut
     */
    'jwt': string;
}
/**
 *
 * @export
 * @interface VitalityTimeSeriesDtoIn
 */
export interface VitalityTimeSeriesDtoIn {
    /**
     *
     * @type {string}
     * @memberof VitalityTimeSeriesDtoIn
     */
    'timestamp': string;
    /**
     *
     * @type {TrackProgressRequest}
     * @memberof VitalityTimeSeriesDtoIn
     */
    'progress': TrackProgressRequest;
}
/**
 *
 * @export
 * @interface WardRoundChallengeDtoInOut
 */
export interface WardRoundChallengeDtoInOut {
    /**
     *
     * @type {GoalType}
     * @memberof WardRoundChallengeDtoInOut
     */
    'type': GoalType;
    /**
     *
     * @type {boolean}
     * @memberof WardRoundChallengeDtoInOut
     */
    'enabled': boolean;
}
/**
 *
 * @export
 * @interface WardRoundChallengeDtoOut
 */
export interface WardRoundChallengeDtoOut {
    /**
     *
     * @type {GoalType}
     * @memberof WardRoundChallengeDtoOut
     */
    'type': GoalType;
    /**
     *
     * @type {boolean}
     * @memberof WardRoundChallengeDtoOut
     */
    'enabled': boolean;
    /**
     *
     * @type {WardRoundChallengeDtoOutUserAnswer}
     * @memberof WardRoundChallengeDtoOut
     */
    'userAnswer'?: WardRoundChallengeDtoOutUserAnswer;
}
/**
 * @type WardRoundChallengeDtoOutUserAnswer
 * @export
 */
export type WardRoundChallengeDtoOutUserAnswer = BloodPressureChallengeProgressDtoOut | HeartRateChallengeProgressDtoOut;

/**
 *
 * @export
 * @interface WardRoundDtoInBase
 */
export interface WardRoundDtoInBase {
    /**
     *
     * @type {string}
     * @memberof WardRoundDtoInBase
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof WardRoundDtoInBase
     */
    'description': string;
    /**
     *
     * @type {Array<string>}
     * @memberof WardRoundDtoInBase
     */
    'questionnaires': Array<string>;
    /**
     *
     * @type {Array<WardRoundChallengeDtoInOut>}
     * @memberof WardRoundDtoInBase
     */
    'challenges': Array<WardRoundChallengeDtoInOut>;
    /**
     *
     * @type {string}
     * @memberof WardRoundDtoInBase
     */
    'discriminator': string;
    /**
     *
     * @type {Array<ActionDtoIn>}
     * @memberof WardRoundDtoInBase
     */
    'actions': Array<ActionDtoIn>;
}
/**
 *
 * @export
 * @interface WardRoundDtoOutBase
 */
export interface WardRoundDtoOutBase {
    /**
     *
     * @type {string}
     * @memberof WardRoundDtoOutBase
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof WardRoundDtoOutBase
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof WardRoundDtoOutBase
     */
    'description': string;
    /**
     *
     * @type {Array<QuestionnaireDtoOut>}
     * @memberof WardRoundDtoOutBase
     */
    'questionnaires': Array<QuestionnaireDtoOut>;
    /**
     *
     * @type {Array<WardRoundChallengeDtoInOut>}
     * @memberof WardRoundDtoOutBase
     */
    'challenges': Array<WardRoundChallengeDtoInOut>;
    /**
     *
     * @type {string}
     * @memberof WardRoundDtoOutBase
     */
    'discriminator': string;
    /**
     *
     * @type {WardRoundType}
     * @memberof WardRoundDtoOutBase
     */
    'type': WardRoundType;
    /**
     *
     * @type {Array<ActionDtoOut>}
     * @memberof WardRoundDtoOutBase
     */
    'actions': Array<ActionDtoOut>;
}
/**
 *
 * @export
 * @interface WardRoundEntryDtoIn
 */
export interface WardRoundEntryDtoIn {
    /**
     *
     * @type {string}
     * @memberof WardRoundEntryDtoIn
     */
    'wardRoundTemplateId': string;
    /**
     * Offset in days from the start of the treatment or previous ward round.
     * @type {number}
     * @memberof WardRoundEntryDtoIn
     */
    'offsetDays': number;
    /**
     * Time of day when the ward round should start.
     * @type {string}
     * @memberof WardRoundEntryDtoIn
     */
    'start'?: string;
    /**
     *
     * @type {boolean}
     * @memberof WardRoundEntryDtoIn
     */
    'isRecurring': boolean;
    /**
     *
     * @type {RepetitionGranularity}
     * @memberof WardRoundEntryDtoIn
     */
    'repetitionGranularity'?: RepetitionGranularity;
    /**
     *
     * @type {number}
     * @memberof WardRoundEntryDtoIn
     */
    'repetitionValue'?: number;
    /**
     *
     * @type {number}
     * @memberof WardRoundEntryDtoIn
     */
    'numberOfRepetitions'?: number;
}
/**
 *
 * @export
 * @interface WardRoundEntryDtoOut
 */
export interface WardRoundEntryDtoOut {
    /**
     *
     * @type {WardRoundTemplateDtoOut}
     * @memberof WardRoundEntryDtoOut
     */
    'wardRoundTemplate': WardRoundTemplateDtoOut;
    /**
     * Offset in days from the start of the treatment or previous ward round.
     * @type {number}
     * @memberof WardRoundEntryDtoOut
     */
    'offsetDays': number;
    /**
     * Time of day when the ward round should start.
     * @type {string}
     * @memberof WardRoundEntryDtoOut
     */
    'start'?: string;
    /**
     *
     * @type {boolean}
     * @memberof WardRoundEntryDtoOut
     */
    'isRecurring': boolean;
    /**
     *
     * @type {RepetitionGranularity}
     * @memberof WardRoundEntryDtoOut
     */
    'repetitionGranularity'?: RepetitionGranularity;
    /**
     *
     * @type {number}
     * @memberof WardRoundEntryDtoOut
     */
    'repetitionValue'?: number;
    /**
     *
     * @type {number}
     * @memberof WardRoundEntryDtoOut
     */
    'numberOfRepetitions'?: number;
    /**
     *
     * @type {number}
     * @memberof WardRoundEntryDtoOut
     */
    'order': number;
}
/**
 *
 * @export
 * @interface WardRoundImportDto
 */
export interface WardRoundImportDto {
    /**
     *
     * @type {number}
     * @memberof WardRoundImportDto
     */
    'wardRoundTemplate': number;
    /**
     *
     * @type {number}
     * @memberof WardRoundImportDto
     */
    'offsetDays': number;
    /**
     *
     * @type {boolean}
     * @memberof WardRoundImportDto
     */
    'isRecurring': boolean;
    /**
     *
     * @type {RepetitionGranularity}
     * @memberof WardRoundImportDto
     */
    'repetitionGranularity'?: RepetitionGranularity;
    /**
     *
     * @type {number}
     * @memberof WardRoundImportDto
     */
    'repetitionValue'?: number;
    /**
     *
     * @type {number}
     * @memberof WardRoundImportDto
     */
    'numberOfRepetitions'?: number;
}
/**
 *
 * @export
 * @interface WardRoundOccurrenceDetailDtoOut
 */
export interface WardRoundOccurrenceDetailDtoOut {
    /**
     *
     * @type {string}
     * @memberof WardRoundOccurrenceDetailDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof WardRoundOccurrenceDetailDtoOut
     */
    'occurredAt': string;
    /**
     *
     * @type {Array<AnsweredQuestionnaireDtoOut>}
     * @memberof WardRoundOccurrenceDetailDtoOut
     */
    'answeredQuestionnaires': Array<AnsweredQuestionnaireDtoOut>;
    /**
     *
     * @type {Array<WardRoundChallengeDtoOutUserAnswer>}
     * @memberof WardRoundOccurrenceDetailDtoOut
     * @deprecated
     */
    'progress': Array<WardRoundChallengeDtoOutUserAnswer>;
    /**
     *
     * @type {Array<ActionDtoOutUserAnswer>}
     * @memberof WardRoundOccurrenceDetailDtoOut
     * @deprecated
     */
    'actionResponses': Array<ActionDtoOutUserAnswer>;
    /**
     *
     * @type {Array<WardRoundChallengeDtoOut>}
     * @memberof WardRoundOccurrenceDetailDtoOut
     */
    'challenges': Array<WardRoundChallengeDtoOut>;
    /**
     *
     * @type {Array<ActionDtoOut>}
     * @memberof WardRoundOccurrenceDetailDtoOut
     */
    'actions': Array<ActionDtoOut>;
    /**
     *
     * @type {boolean}
     * @memberof WardRoundOccurrenceDetailDtoOut
     */
    'isCompleted': boolean;
}
/**
 *
 * @export
 * @interface WardRoundOccurrenceDtoOut
 */
export interface WardRoundOccurrenceDtoOut {
    /**
     *
     * @type {string}
     * @memberof WardRoundOccurrenceDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof WardRoundOccurrenceDtoOut
     */
    'occurredAt': string;
    /**
     *
     * @type {string}
     * @memberof WardRoundOccurrenceDtoOut
     */
    'answeredAt'?: string;
    /**
     *
     * @type {boolean}
     * @memberof WardRoundOccurrenceDtoOut
     */
    'isCompleted': boolean;
    /**
     * True when patient did not answer on time
     * @type {boolean}
     * @memberof WardRoundOccurrenceDtoOut
     */
    'isMissed': boolean;
    /**
     *
     * @type {string}
     * @memberof WardRoundOccurrenceDtoOut
     */
    'wardRoundName': string;
    /**
     *
     * @type {string}
     * @memberof WardRoundOccurrenceDtoOut
     */
    'wardRoundId': string;
}
/**
 *
 * @export
 * @interface WardRoundTemplateDtoIn
 */
export interface WardRoundTemplateDtoIn {
    /**
     *
     * @type {string}
     * @memberof WardRoundTemplateDtoIn
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof WardRoundTemplateDtoIn
     */
    'description': string;
    /**
     *
     * @type {Array<string>}
     * @memberof WardRoundTemplateDtoIn
     */
    'questionnaires': Array<string>;
    /**
     *
     * @type {Array<ActionTemplateDtoIn>}
     * @memberof WardRoundTemplateDtoIn
     */
    'actions': Array<ActionTemplateDtoIn>;
    /**
     *
     * @type {Array<WardRoundChallengeDtoInOut>}
     * @memberof WardRoundTemplateDtoIn
     */
    'challenges': Array<WardRoundChallengeDtoInOut>;
}
/**
 *
 * @export
 * @interface WardRoundTemplateDtoOut
 */
export interface WardRoundTemplateDtoOut {
    /**
     *
     * @type {string}
     * @memberof WardRoundTemplateDtoOut
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof WardRoundTemplateDtoOut
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof WardRoundTemplateDtoOut
     */
    'description': string;
    /**
     *
     * @type {Array<QuestionnaireTemplateDtoOut>}
     * @memberof WardRoundTemplateDtoOut
     */
    'questionnaires': Array<QuestionnaireTemplateDtoOut>;
    /**
     *
     * @type {Array<ActionTemplateDtoOut>}
     * @memberof WardRoundTemplateDtoOut
     */
    'actions': Array<ActionTemplateDtoOut>;
    /**
     *
     * @type {Array<WardRoundChallengeDtoInOut>}
     * @memberof WardRoundTemplateDtoOut
     */
    'challenges': Array<WardRoundChallengeDtoInOut>;
}
/**
 *
 * @export
 * @interface WardRoundTemplateImportDto
 */
export interface WardRoundTemplateImportDto {
    /**
     *
     * @type {string}
     * @memberof WardRoundTemplateImportDto
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof WardRoundTemplateImportDto
     */
    'description': string;
    /**
     *
     * @type {Array<number>}
     * @memberof WardRoundTemplateImportDto
     */
    'questionnaires': Array<number>;
    /**
     *
     * @type {Array<ActionTemplateDtoIn>}
     * @memberof WardRoundTemplateImportDto
     */
    'enabledActions': Array<ActionTemplateDtoIn>;
    /**
     *
     * @type {Array<GoalType>}
     * @memberof WardRoundTemplateImportDto
     */
    'enabledChallengeTypes': Array<GoalType>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum WardRoundType {
    Recurring = 'RECURRING',
    OneTime = 'ONE_TIME'
}



/**
 * AccountControllerApi - axios parameter creator
 * @export
 */
export const AccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create demo doctor account with sample patients. Use given username as account\'s username and password.
         * @summary Create demo doctor account with sample patients.
         * @param {AccountDtoIn} accountDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoDoctorAccount: async (accountDtoIn: AccountDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountDtoIn' is not null or undefined
            assertParamExists('createDemoDoctorAccount', 'accountDtoIn', accountDtoIn)
            const localVarPath = `/api/account/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create demo doctor account with sample patients. Use given username as account\'s username and password.
         * @summary Create demo doctor account with sample patients.
         * @param {AccountDtoIn} accountDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDemoDoctorAccount(accountDtoIn: AccountDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDemoDoctorAccount(accountDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountControllerApiFp(configuration)
    return {
        /**
         * Create demo doctor account with sample patients. Use given username as account\'s username and password.
         * @summary Create demo doctor account with sample patients.
         * @param {AccountDtoIn} accountDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoDoctorAccount(accountDtoIn: AccountDtoIn, options?: any): AxiosPromise<AccountDtoOut> {
            return localVarFp.createDemoDoctorAccount(accountDtoIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * Create demo doctor account with sample patients. Use given username as account\'s username and password.
     * @summary Create demo doctor account with sample patients.
     * @param {AccountDtoIn} accountDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public createDemoDoctorAccount(accountDtoIn: AccountDtoIn, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).createDemoDoctorAccount(accountDtoIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActionControllerApi - axios parameter creator
 * @export
 */
export const ActionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get actions.
         * @summary Get actions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionControllerApi - functional programming interface
 * @export
 */
export const ActionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Get actions.
         * @summary Get actions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActionDtoOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionControllerApi - factory interface
 * @export
 */
export const ActionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionControllerApiFp(configuration)
    return {
        /**
         * Get actions.
         * @summary Get actions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActions(options?: any): AxiosPromise<Array<ActionDtoOut>> {
            return localVarFp.getActions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionControllerApi - object-oriented interface
 * @export
 * @class ActionControllerApi
 * @extends {BaseAPI}
 */
export class ActionControllerApi extends BaseAPI {
    /**
     * Get actions.
     * @summary Get actions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionControllerApi
     */
    public getActions(options?: AxiosRequestConfig) {
        return ActionControllerApiFp(this.configuration).getActions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActuatorControllerApi - axios parameter creator
 * @export
 */
export const ActuatorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Actuator health.
         * @summary Get Actuator health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActuatorHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActuatorControllerApi - functional programming interface
 * @export
 */
export const ActuatorControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActuatorControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Actuator health.
         * @summary Get Actuator health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActuatorHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActuatorHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActuatorControllerApi - factory interface
 * @export
 */
export const ActuatorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActuatorControllerApiFp(configuration)
    return {
        /**
         * Get Actuator health.
         * @summary Get Actuator health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActuatorHealth(options?: any): AxiosPromise<void> {
            return localVarFp.getActuatorHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActuatorControllerApi - object-oriented interface
 * @export
 * @class ActuatorControllerApi
 * @extends {BaseAPI}
 */
export class ActuatorControllerApi extends BaseAPI {
    /**
     * Get Actuator health.
     * @summary Get Actuator health.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorControllerApi
     */
    public getActuatorHealth(options?: AxiosRequestConfig) {
        return ActuatorControllerApiFp(this.configuration).getActuatorHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationInfoControllerApi - axios parameter creator
 * @export
 */
export const ApplicationInfoControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get client configuration.
         * @summary Get client configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get application version info.
         * @summary Get application version info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Report application status.
         * @summary Report application status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationInfoControllerApi - functional programming interface
 * @export
 */
export const ApplicationInfoControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationInfoControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Get client configuration.
         * @summary Get client configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get application version info.
         * @summary Get application version info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Report application status.
         * @summary Report application status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationInfoControllerApi - factory interface
 * @export
 */
export const ApplicationInfoControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationInfoControllerApiFp(configuration)
    return {
        /**
         * Get client configuration.
         * @summary Get client configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfiguration(options?: any): AxiosPromise<void> {
            return localVarFp.getClientConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * Get application version info.
         * @summary Get application version info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: any): AxiosPromise<ApplicationInfoDto> {
            return localVarFp.getVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * Report application status.
         * @summary Report application status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportStatus(options?: any): AxiosPromise<StatusDto> {
            return localVarFp.reportStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationInfoControllerApi - object-oriented interface
 * @export
 * @class ApplicationInfoControllerApi
 * @extends {BaseAPI}
 */
export class ApplicationInfoControllerApi extends BaseAPI {
    /**
     * Get client configuration.
     * @summary Get client configuration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationInfoControllerApi
     */
    public getClientConfiguration(options?: AxiosRequestConfig) {
        return ApplicationInfoControllerApiFp(this.configuration).getClientConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get application version info.
     * @summary Get application version info.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationInfoControllerApi
     */
    public getVersion(options?: AxiosRequestConfig) {
        return ApplicationInfoControllerApiFp(this.configuration).getVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Report application status.
     * @summary Report application status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationInfoControllerApi
     */
    public reportStatus(options?: AxiosRequestConfig) {
        return ApplicationInfoControllerApiFp(this.configuration).reportStatus(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CareTemplateControllerApi - axios parameter creator
 * @export
 */
export const CareTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create care template.
         * @summary Create care template.
         * @param {CareTemplateDtoIn} careTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCareTemplate: async (careTemplateDtoIn: CareTemplateDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'careTemplateDtoIn' is not null or undefined
            assertParamExists('createCareTemplate', 'careTemplateDtoIn', careTemplateDtoIn)
            const localVarPath = `/api/templates/cares`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(careTemplateDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete care template.
         * @summary Delete care template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCareTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCareTemplate', 'id', id)
            const localVarPath = `/api/templates/cares/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Export care template.
         * @summary Export care template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCareTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportCareTemplate', 'id', id)
            const localVarPath = `/api/templates/cares/export/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get care templates.
         * @summary Get care templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCareTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/templates/cares`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get care template.
         * @summary Get care template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareTemplateDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCareTemplateDetail', 'id', id)
            const localVarPath = `/api/templates/cares/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create care template.
         * @summary Import care template.
         * @param {CareTemplateImportExportDto} careTemplateImportExportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCareTemplate: async (careTemplateImportExportDto: CareTemplateImportExportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'careTemplateImportExportDto' is not null or undefined
            assertParamExists('importCareTemplate', 'careTemplateImportExportDto', careTemplateImportExportDto)
            const localVarPath = `/api/templates/cares/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(careTemplateImportExportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update care template.
         * @summary Update care template.
         * @param {string} id
         * @param {CareTemplateDtoIn} careTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCareTemplate: async (id: string, careTemplateDtoIn: CareTemplateDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCareTemplate', 'id', id)
            // verify required parameter 'careTemplateDtoIn' is not null or undefined
            assertParamExists('updateCareTemplate', 'careTemplateDtoIn', careTemplateDtoIn)
            const localVarPath = `/api/templates/cares/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(careTemplateDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CareTemplateControllerApi - functional programming interface
 * @export
 */
export const CareTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CareTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create care template.
         * @summary Create care template.
         * @param {CareTemplateDtoIn} careTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCareTemplate(careTemplateDtoIn: CareTemplateDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCareTemplate(careTemplateDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete care template.
         * @summary Delete care template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCareTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCareTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Export care template.
         * @summary Export care template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCareTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareTemplateImportExportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCareTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get care templates.
         * @summary Get care templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCareTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CareTemplateDtoOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCareTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get care template.
         * @summary Get care template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCareTemplateDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCareTemplateDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create care template.
         * @summary Import care template.
         * @param {CareTemplateImportExportDto} careTemplateImportExportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importCareTemplate(careTemplateImportExportDto: CareTemplateImportExportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importCareTemplate(careTemplateImportExportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update care template.
         * @summary Update care template.
         * @param {string} id
         * @param {CareTemplateDtoIn} careTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCareTemplate(id: string, careTemplateDtoIn: CareTemplateDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCareTemplate(id, careTemplateDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CareTemplateControllerApi - factory interface
 * @export
 */
export const CareTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CareTemplateControllerApiFp(configuration)
    return {
        /**
         * Create care template.
         * @summary Create care template.
         * @param {CareTemplateDtoIn} careTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCareTemplate(careTemplateDtoIn: CareTemplateDtoIn, options?: any): AxiosPromise<CareTemplateDtoOut> {
            return localVarFp.createCareTemplate(careTemplateDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete care template.
         * @summary Delete care template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCareTemplate(id: string, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.deleteCareTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Export care template.
         * @summary Export care template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCareTemplate(id: string, options?: any): AxiosPromise<CareTemplateImportExportDto> {
            return localVarFp.exportCareTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get care templates.
         * @summary Get care templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCareTemplates(options?: any): AxiosPromise<Array<CareTemplateDtoOut>> {
            return localVarFp.getAllCareTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * Get care template.
         * @summary Get care template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCareTemplateDetail(id: string, options?: any): AxiosPromise<CareTemplateDtoOut> {
            return localVarFp.getCareTemplateDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create care template.
         * @summary Import care template.
         * @param {CareTemplateImportExportDto} careTemplateImportExportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCareTemplate(careTemplateImportExportDto: CareTemplateImportExportDto, options?: any): AxiosPromise<CareTemplateDtoOut> {
            return localVarFp.importCareTemplate(careTemplateImportExportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update care template.
         * @summary Update care template.
         * @param {string} id
         * @param {CareTemplateDtoIn} careTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCareTemplate(id: string, careTemplateDtoIn: CareTemplateDtoIn, options?: any): AxiosPromise<CareTemplateDtoOut> {
            return localVarFp.updateCareTemplate(id, careTemplateDtoIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CareTemplateControllerApi - object-oriented interface
 * @export
 * @class CareTemplateControllerApi
 * @extends {BaseAPI}
 */
export class CareTemplateControllerApi extends BaseAPI {
    /**
     * Create care template.
     * @summary Create care template.
     * @param {CareTemplateDtoIn} careTemplateDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareTemplateControllerApi
     */
    public createCareTemplate(careTemplateDtoIn: CareTemplateDtoIn, options?: AxiosRequestConfig) {
        return CareTemplateControllerApiFp(this.configuration).createCareTemplate(careTemplateDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete care template.
     * @summary Delete care template.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareTemplateControllerApi
     */
    public deleteCareTemplate(id: string, options?: AxiosRequestConfig) {
        return CareTemplateControllerApiFp(this.configuration).deleteCareTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Export care template.
     * @summary Export care template.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareTemplateControllerApi
     */
    public exportCareTemplate(id: string, options?: AxiosRequestConfig) {
        return CareTemplateControllerApiFp(this.configuration).exportCareTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get care templates.
     * @summary Get care templates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareTemplateControllerApi
     */
    public getAllCareTemplates(options?: AxiosRequestConfig) {
        return CareTemplateControllerApiFp(this.configuration).getAllCareTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get care template.
     * @summary Get care template.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareTemplateControllerApi
     */
    public getCareTemplateDetail(id: string, options?: AxiosRequestConfig) {
        return CareTemplateControllerApiFp(this.configuration).getCareTemplateDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create care template.
     * @summary Import care template.
     * @param {CareTemplateImportExportDto} careTemplateImportExportDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareTemplateControllerApi
     */
    public importCareTemplate(careTemplateImportExportDto: CareTemplateImportExportDto, options?: AxiosRequestConfig) {
        return CareTemplateControllerApiFp(this.configuration).importCareTemplate(careTemplateImportExportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update care template.
     * @summary Update care template.
     * @param {string} id
     * @param {CareTemplateDtoIn} careTemplateDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CareTemplateControllerApi
     */
    public updateCareTemplate(id: string, careTemplateDtoIn: CareTemplateDtoIn, options?: AxiosRequestConfig) {
        return CareTemplateControllerApiFp(this.configuration).updateCareTemplate(id, careTemplateDtoIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImportControllerApi - axios parameter creator
 * @export
 */
export const ImportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Import data for kladruby.
         * @summary Import data for kladruby.
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importKladruby: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('importKladruby', 'file', file)
            const localVarPath = `/api/import/kladruby`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImportControllerApi - functional programming interface
 * @export
 */
export const ImportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Import data for kladruby.
         * @summary Import data for kladruby.
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importKladruby(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importKladruby(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImportControllerApi - factory interface
 * @export
 */
export const ImportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImportControllerApiFp(configuration)
    return {
        /**
         * Import data for kladruby.
         * @summary Import data for kladruby.
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importKladruby(file: any, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.importKladruby(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImportControllerApi - object-oriented interface
 * @export
 * @class ImportControllerApi
 * @extends {BaseAPI}
 */
export class ImportControllerApi extends BaseAPI {
    /**
     * Import data for kladruby.
     * @summary Import data for kladruby.
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportControllerApi
     */
    public importKladruby(file: any, options?: AxiosRequestConfig) {
        return ImportControllerApiFp(this.configuration).importKladruby(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LastMqMessageControllerApi - axios parameter creator
 * @export
 */
export const LastMqMessageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets information about last received MQ message.
         * @summary Get last MQ message information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastMessageInfoNotification: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/last-mq-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LastMqMessageControllerApi - functional programming interface
 * @export
 */
export const LastMqMessageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LastMqMessageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets information about last received MQ message.
         * @summary Get last MQ message information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastMessageInfoNotification(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastMqMessageInfoDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastMessageInfoNotification(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LastMqMessageControllerApi - factory interface
 * @export
 */
export const LastMqMessageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LastMqMessageControllerApiFp(configuration)
    return {
        /**
         * Gets information about last received MQ message.
         * @summary Get last MQ message information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastMessageInfoNotification(options?: any): AxiosPromise<LastMqMessageInfoDtoOut> {
            return localVarFp.getLastMessageInfoNotification(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LastMqMessageControllerApi - object-oriented interface
 * @export
 * @class LastMqMessageControllerApi
 * @extends {BaseAPI}
 */
export class LastMqMessageControllerApi extends BaseAPI {
    /**
     * Gets information about last received MQ message.
     * @summary Get last MQ message information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LastMqMessageControllerApi
     */
    public getLastMessageInfoNotification(options?: AxiosRequestConfig) {
        return LastMqMessageControllerApiFp(this.configuration).getLastMessageInfoNotification(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginControllerApi - axios parameter creator
 * @export
 */
export const LoginControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Login user into the application.
         * @summary Login user into the application.
         * @param {LoginDtoIn} loginDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginDtoIn: LoginDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDtoIn' is not null or undefined
            assertParamExists('login', 'loginDtoIn', loginDtoIn)
            const localVarPath = `/api/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginControllerApi - functional programming interface
 * @export
 */
export const LoginControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Login user into the application.
         * @summary Login user into the application.
         * @param {LoginDtoIn} loginDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginDtoIn: LoginDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithJwtDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginControllerApi - factory interface
 * @export
 */
export const LoginControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginControllerApiFp(configuration)
    return {
        /**
         * Login user into the application.
         * @summary Login user into the application.
         * @param {LoginDtoIn} loginDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginDtoIn: LoginDtoIn, options?: any): AxiosPromise<UserWithJwtDtoOut> {
            return localVarFp.login(loginDtoIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginControllerApi - object-oriented interface
 * @export
 * @class LoginControllerApi
 * @extends {BaseAPI}
 */
export class LoginControllerApi extends BaseAPI {
    /**
     * Login user into the application.
     * @summary Login user into the application.
     * @param {LoginDtoIn} loginDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginControllerApi
     */
    public login(loginDtoIn: LoginDtoIn, options?: AxiosRequestConfig) {
        return LoginControllerApiFp(this.configuration).login(loginDtoIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogoutControllerApi - axios parameter creator
 * @export
 */
export const LogoutControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Logout user.
         * @summary Logout user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUsingPOST: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogoutControllerApi - functional programming interface
 * @export
 */
export const LogoutControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogoutControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Logout user.
         * @summary Logout user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUsingPOST(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogoutControllerApi - factory interface
 * @export
 */
export const LogoutControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogoutControllerApiFp(configuration)
    return {
        /**
         * Logout user.
         * @summary Logout user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUsingPOST(options?: any): AxiosPromise<void> {
            return localVarFp.logoutUsingPOST(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogoutControllerApi - object-oriented interface
 * @export
 * @class LogoutControllerApi
 * @extends {BaseAPI}
 */
export class LogoutControllerApi extends BaseAPI {
    /**
     * Logout user.
     * @summary Logout user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogoutControllerApi
     */
    public logoutUsingPOST(options?: AxiosRequestConfig) {
        return LogoutControllerApiFp(this.configuration).logoutUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationControllerApi - axios parameter creator
 * @export
 */
export const NotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Process appointment delete.
         * @summary Process appointment delete.
         * @param {AppointmentDeleteDtoIn} appointmentDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAppointmentDelete: async (appointmentDeleteDtoIn: AppointmentDeleteDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentDeleteDtoIn' is not null or undefined
            assertParamExists('processAppointmentDelete', 'appointmentDeleteDtoIn', appointmentDeleteDtoIn)
            const localVarPath = `/api/notifications/appointment-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointmentDeleteDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process appointment update.
         * @summary Process appointment update.
         * @param {AppointmentUpdateDtoInOut} appointmentUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAppointmentUpdate: async (appointmentUpdateDtoInOut: AppointmentUpdateDtoInOut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentUpdateDtoInOut' is not null or undefined
            assertParamExists('processAppointmentUpdate', 'appointmentUpdateDtoInOut', appointmentUpdateDtoInOut)
            const localVarPath = `/api/notifications/appointment-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointmentUpdateDtoInOut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process biometry delete.
         * @summary Process biometry delete.
         * @param {BiometryDeleteDtoIn} biometryDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processBiometryDelete: async (biometryDeleteDtoIn: BiometryDeleteDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biometryDeleteDtoIn' is not null or undefined
            assertParamExists('processBiometryDelete', 'biometryDeleteDtoIn', biometryDeleteDtoIn)
            const localVarPath = `/api/notifications/biometry-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biometryDeleteDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process biometry update.
         * @summary Process biometry update.
         * @param {BiometryUpdateDtoInOut} biometryUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processBiometryUpdate: async (biometryUpdateDtoInOut: BiometryUpdateDtoInOut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biometryUpdateDtoInOut' is not null or undefined
            assertParamExists('processBiometryUpdate', 'biometryUpdateDtoInOut', biometryUpdateDtoInOut)
            const localVarPath = `/api/notifications/biometry-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biometryUpdateDtoInOut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process diagnoses update.
         * @summary Process diagnoses update.
         * @param {DiagnosesUpdateDtoInOut} diagnosesUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processDiagnosesUpdate: async (diagnosesUpdateDtoInOut: DiagnosesUpdateDtoInOut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'diagnosesUpdateDtoInOut' is not null or undefined
            assertParamExists('processDiagnosesUpdate', 'diagnosesUpdateDtoInOut', diagnosesUpdateDtoInOut)
            const localVarPath = `/api/notifications/diagnoses-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(diagnosesUpdateDtoInOut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process patient care update notification.
         * @summary Process patient care update notification.
         * @param {EndPatientCareDtoIn} endPatientCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processEndPatientCare: async (endPatientCareDtoIn: EndPatientCareDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'endPatientCareDtoIn' is not null or undefined
            assertParamExists('processEndPatientCare', 'endPatientCareDtoIn', endPatientCareDtoIn)
            const localVarPath = `/api/notifications/end-patient-care`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endPatientCareDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process hospitalization delete.
         * @summary Process hospitalization delete.
         * @param {HospitalizationDeleteDtoIn} hospitalizationDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processHospitalizationDelete: async (hospitalizationDeleteDtoIn: HospitalizationDeleteDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospitalizationDeleteDtoIn' is not null or undefined
            assertParamExists('processHospitalizationDelete', 'hospitalizationDeleteDtoIn', hospitalizationDeleteDtoIn)
            const localVarPath = `/api/notifications/hospitalization-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hospitalizationDeleteDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process hospitalization update.
         * @summary Process hospitalization update.
         * @param {HospitalizationUpdateDtoInOut} hospitalizationUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processHospitalizationUpdate: async (hospitalizationUpdateDtoInOut: HospitalizationUpdateDtoInOut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospitalizationUpdateDtoInOut' is not null or undefined
            assertParamExists('processHospitalizationUpdate', 'hospitalizationUpdateDtoInOut', hospitalizationUpdateDtoInOut)
            const localVarPath = `/api/notifications/hospitalization-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hospitalizationUpdateDtoInOut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process last MQ message notification.
         * @summary Process last MQ message notification.
         * @param {InfoNotificationDtoInOut} infoNotificationDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processLastMessageInfoNotification: async (infoNotificationDtoInOut: InfoNotificationDtoInOut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'infoNotificationDtoInOut' is not null or undefined
            assertParamExists('processLastMessageInfoNotification', 'infoNotificationDtoInOut', infoNotificationDtoInOut)
            const localVarPath = `/api/notifications/last-mq-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(infoNotificationDtoInOut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process patient care update notification.
         * @summary Process patient care update notification.
         * @param {PatientCareDtoIn} patientCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPatientCareUpdate: async (patientCareDtoIn: PatientCareDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientCareDtoIn' is not null or undefined
            assertParamExists('processPatientCareUpdate', 'patientCareDtoIn', patientCareDtoIn)
            const localVarPath = `/api/notifications/patient-care-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientCareDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process patient delete.
         * @summary Process patient delete.
         * @param {PatientDeleteDtoIn} patientDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPatientDelete: async (patientDeleteDtoIn: PatientDeleteDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientDeleteDtoIn' is not null or undefined
            assertParamExists('processPatientDelete', 'patientDeleteDtoIn', patientDeleteDtoIn)
            const localVarPath = `/api/notifications/patient-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientDeleteDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process patient update.
         * @summary Process patient update.
         * @param {PatientUpdateDtoInOut} patientUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPatientUpdate: async (patientUpdateDtoInOut: PatientUpdateDtoInOut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientUpdateDtoInOut' is not null or undefined
            assertParamExists('processPatientUpdate', 'patientUpdateDtoInOut', patientUpdateDtoInOut)
            const localVarPath = `/api/notifications/patient-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientUpdateDtoInOut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process procedure delete.
         * @summary Process procedure delete.
         * @param {ProcedureDeleteDtoIn} procedureDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProcedureDelete: async (procedureDeleteDtoIn: ProcedureDeleteDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'procedureDeleteDtoIn' is not null or undefined
            assertParamExists('processProcedureDelete', 'procedureDeleteDtoIn', procedureDeleteDtoIn)
            const localVarPath = `/api/notifications/procedure-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(procedureDeleteDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process procedure update.
         * @summary Process procedure update.
         * @param {ProcedureUpdateDtoInOut} procedureUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProcedureUpdate: async (procedureUpdateDtoInOut: ProcedureUpdateDtoInOut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'procedureUpdateDtoInOut' is not null or undefined
            assertParamExists('processProcedureUpdate', 'procedureUpdateDtoInOut', procedureUpdateDtoInOut)
            const localVarPath = `/api/notifications/procedure-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(procedureUpdateDtoInOut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Process appointment delete.
         * @summary Process appointment delete.
         * @param {AppointmentDeleteDtoIn} appointmentDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processAppointmentDelete(appointmentDeleteDtoIn: AppointmentDeleteDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processAppointmentDelete(appointmentDeleteDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process appointment update.
         * @summary Process appointment update.
         * @param {AppointmentUpdateDtoInOut} appointmentUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processAppointmentUpdate(appointmentUpdateDtoInOut: AppointmentUpdateDtoInOut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processAppointmentUpdate(appointmentUpdateDtoInOut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process biometry delete.
         * @summary Process biometry delete.
         * @param {BiometryDeleteDtoIn} biometryDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processBiometryDelete(biometryDeleteDtoIn: BiometryDeleteDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processBiometryDelete(biometryDeleteDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process biometry update.
         * @summary Process biometry update.
         * @param {BiometryUpdateDtoInOut} biometryUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processBiometryUpdate(biometryUpdateDtoInOut: BiometryUpdateDtoInOut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processBiometryUpdate(biometryUpdateDtoInOut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process diagnoses update.
         * @summary Process diagnoses update.
         * @param {DiagnosesUpdateDtoInOut} diagnosesUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processDiagnosesUpdate(diagnosesUpdateDtoInOut: DiagnosesUpdateDtoInOut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processDiagnosesUpdate(diagnosesUpdateDtoInOut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process patient care update notification.
         * @summary Process patient care update notification.
         * @param {EndPatientCareDtoIn} endPatientCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processEndPatientCare(endPatientCareDtoIn: EndPatientCareDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processEndPatientCare(endPatientCareDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process hospitalization delete.
         * @summary Process hospitalization delete.
         * @param {HospitalizationDeleteDtoIn} hospitalizationDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processHospitalizationDelete(hospitalizationDeleteDtoIn: HospitalizationDeleteDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processHospitalizationDelete(hospitalizationDeleteDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process hospitalization update.
         * @summary Process hospitalization update.
         * @param {HospitalizationUpdateDtoInOut} hospitalizationUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processHospitalizationUpdate(hospitalizationUpdateDtoInOut: HospitalizationUpdateDtoInOut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processHospitalizationUpdate(hospitalizationUpdateDtoInOut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process last MQ message notification.
         * @summary Process last MQ message notification.
         * @param {InfoNotificationDtoInOut} infoNotificationDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processLastMessageInfoNotification(infoNotificationDtoInOut: InfoNotificationDtoInOut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processLastMessageInfoNotification(infoNotificationDtoInOut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process patient care update notification.
         * @summary Process patient care update notification.
         * @param {PatientCareDtoIn} patientCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processPatientCareUpdate(patientCareDtoIn: PatientCareDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processPatientCareUpdate(patientCareDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process patient delete.
         * @summary Process patient delete.
         * @param {PatientDeleteDtoIn} patientDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processPatientDelete(patientDeleteDtoIn: PatientDeleteDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processPatientDelete(patientDeleteDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process patient update.
         * @summary Process patient update.
         * @param {PatientUpdateDtoInOut} patientUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processPatientUpdate(patientUpdateDtoInOut: PatientUpdateDtoInOut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processPatientUpdate(patientUpdateDtoInOut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process procedure delete.
         * @summary Process procedure delete.
         * @param {ProcedureDeleteDtoIn} procedureDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processProcedureDelete(procedureDeleteDtoIn: ProcedureDeleteDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processProcedureDelete(procedureDeleteDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process procedure update.
         * @summary Process procedure update.
         * @param {ProcedureUpdateDtoInOut} procedureUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processProcedureUpdate(procedureUpdateDtoInOut: ProcedureUpdateDtoInOut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processProcedureUpdate(procedureUpdateDtoInOut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationControllerApiFp(configuration)
    return {
        /**
         * Process appointment delete.
         * @summary Process appointment delete.
         * @param {AppointmentDeleteDtoIn} appointmentDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAppointmentDelete(appointmentDeleteDtoIn: AppointmentDeleteDtoIn, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processAppointmentDelete(appointmentDeleteDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Process appointment update.
         * @summary Process appointment update.
         * @param {AppointmentUpdateDtoInOut} appointmentUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAppointmentUpdate(appointmentUpdateDtoInOut: AppointmentUpdateDtoInOut, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processAppointmentUpdate(appointmentUpdateDtoInOut, options).then((request) => request(axios, basePath));
        },
        /**
         * Process biometry delete.
         * @summary Process biometry delete.
         * @param {BiometryDeleteDtoIn} biometryDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processBiometryDelete(biometryDeleteDtoIn: BiometryDeleteDtoIn, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processBiometryDelete(biometryDeleteDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Process biometry update.
         * @summary Process biometry update.
         * @param {BiometryUpdateDtoInOut} biometryUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processBiometryUpdate(biometryUpdateDtoInOut: BiometryUpdateDtoInOut, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processBiometryUpdate(biometryUpdateDtoInOut, options).then((request) => request(axios, basePath));
        },
        /**
         * Process diagnoses update.
         * @summary Process diagnoses update.
         * @param {DiagnosesUpdateDtoInOut} diagnosesUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processDiagnosesUpdate(diagnosesUpdateDtoInOut: DiagnosesUpdateDtoInOut, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processDiagnosesUpdate(diagnosesUpdateDtoInOut, options).then((request) => request(axios, basePath));
        },
        /**
         * Process patient care update notification.
         * @summary Process patient care update notification.
         * @param {EndPatientCareDtoIn} endPatientCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processEndPatientCare(endPatientCareDtoIn: EndPatientCareDtoIn, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processEndPatientCare(endPatientCareDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Process hospitalization delete.
         * @summary Process hospitalization delete.
         * @param {HospitalizationDeleteDtoIn} hospitalizationDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processHospitalizationDelete(hospitalizationDeleteDtoIn: HospitalizationDeleteDtoIn, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processHospitalizationDelete(hospitalizationDeleteDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Process hospitalization update.
         * @summary Process hospitalization update.
         * @param {HospitalizationUpdateDtoInOut} hospitalizationUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processHospitalizationUpdate(hospitalizationUpdateDtoInOut: HospitalizationUpdateDtoInOut, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processHospitalizationUpdate(hospitalizationUpdateDtoInOut, options).then((request) => request(axios, basePath));
        },
        /**
         * Process last MQ message notification.
         * @summary Process last MQ message notification.
         * @param {InfoNotificationDtoInOut} infoNotificationDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processLastMessageInfoNotification(infoNotificationDtoInOut: InfoNotificationDtoInOut, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processLastMessageInfoNotification(infoNotificationDtoInOut, options).then((request) => request(axios, basePath));
        },
        /**
         * Process patient care update notification.
         * @summary Process patient care update notification.
         * @param {PatientCareDtoIn} patientCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPatientCareUpdate(patientCareDtoIn: PatientCareDtoIn, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processPatientCareUpdate(patientCareDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Process patient delete.
         * @summary Process patient delete.
         * @param {PatientDeleteDtoIn} patientDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPatientDelete(patientDeleteDtoIn: PatientDeleteDtoIn, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processPatientDelete(patientDeleteDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Process patient update.
         * @summary Process patient update.
         * @param {PatientUpdateDtoInOut} patientUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPatientUpdate(patientUpdateDtoInOut: PatientUpdateDtoInOut, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processPatientUpdate(patientUpdateDtoInOut, options).then((request) => request(axios, basePath));
        },
        /**
         * Process procedure delete.
         * @summary Process procedure delete.
         * @param {ProcedureDeleteDtoIn} procedureDeleteDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProcedureDelete(procedureDeleteDtoIn: ProcedureDeleteDtoIn, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processProcedureDelete(procedureDeleteDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Process procedure update.
         * @summary Process procedure update.
         * @param {ProcedureUpdateDtoInOut} procedureUpdateDtoInOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProcedureUpdate(procedureUpdateDtoInOut: ProcedureUpdateDtoInOut, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.processProcedureUpdate(procedureUpdateDtoInOut, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI {
    /**
     * Process appointment delete.
     * @summary Process appointment delete.
     * @param {AppointmentDeleteDtoIn} appointmentDeleteDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processAppointmentDelete(appointmentDeleteDtoIn: AppointmentDeleteDtoIn, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processAppointmentDelete(appointmentDeleteDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process appointment update.
     * @summary Process appointment update.
     * @param {AppointmentUpdateDtoInOut} appointmentUpdateDtoInOut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processAppointmentUpdate(appointmentUpdateDtoInOut: AppointmentUpdateDtoInOut, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processAppointmentUpdate(appointmentUpdateDtoInOut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process biometry delete.
     * @summary Process biometry delete.
     * @param {BiometryDeleteDtoIn} biometryDeleteDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processBiometryDelete(biometryDeleteDtoIn: BiometryDeleteDtoIn, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processBiometryDelete(biometryDeleteDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process biometry update.
     * @summary Process biometry update.
     * @param {BiometryUpdateDtoInOut} biometryUpdateDtoInOut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processBiometryUpdate(biometryUpdateDtoInOut: BiometryUpdateDtoInOut, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processBiometryUpdate(biometryUpdateDtoInOut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process diagnoses update.
     * @summary Process diagnoses update.
     * @param {DiagnosesUpdateDtoInOut} diagnosesUpdateDtoInOut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processDiagnosesUpdate(diagnosesUpdateDtoInOut: DiagnosesUpdateDtoInOut, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processDiagnosesUpdate(diagnosesUpdateDtoInOut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process patient care update notification.
     * @summary Process patient care update notification.
     * @param {EndPatientCareDtoIn} endPatientCareDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processEndPatientCare(endPatientCareDtoIn: EndPatientCareDtoIn, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processEndPatientCare(endPatientCareDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process hospitalization delete.
     * @summary Process hospitalization delete.
     * @param {HospitalizationDeleteDtoIn} hospitalizationDeleteDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processHospitalizationDelete(hospitalizationDeleteDtoIn: HospitalizationDeleteDtoIn, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processHospitalizationDelete(hospitalizationDeleteDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process hospitalization update.
     * @summary Process hospitalization update.
     * @param {HospitalizationUpdateDtoInOut} hospitalizationUpdateDtoInOut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processHospitalizationUpdate(hospitalizationUpdateDtoInOut: HospitalizationUpdateDtoInOut, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processHospitalizationUpdate(hospitalizationUpdateDtoInOut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process last MQ message notification.
     * @summary Process last MQ message notification.
     * @param {InfoNotificationDtoInOut} infoNotificationDtoInOut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processLastMessageInfoNotification(infoNotificationDtoInOut: InfoNotificationDtoInOut, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processLastMessageInfoNotification(infoNotificationDtoInOut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process patient care update notification.
     * @summary Process patient care update notification.
     * @param {PatientCareDtoIn} patientCareDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processPatientCareUpdate(patientCareDtoIn: PatientCareDtoIn, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processPatientCareUpdate(patientCareDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process patient delete.
     * @summary Process patient delete.
     * @param {PatientDeleteDtoIn} patientDeleteDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processPatientDelete(patientDeleteDtoIn: PatientDeleteDtoIn, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processPatientDelete(patientDeleteDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process patient update.
     * @summary Process patient update.
     * @param {PatientUpdateDtoInOut} patientUpdateDtoInOut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processPatientUpdate(patientUpdateDtoInOut: PatientUpdateDtoInOut, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processPatientUpdate(patientUpdateDtoInOut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process procedure delete.
     * @summary Process procedure delete.
     * @param {ProcedureDeleteDtoIn} procedureDeleteDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processProcedureDelete(procedureDeleteDtoIn: ProcedureDeleteDtoIn, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processProcedureDelete(procedureDeleteDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process procedure update.
     * @summary Process procedure update.
     * @param {ProcedureUpdateDtoInOut} procedureUpdateDtoInOut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public processProcedureUpdate(procedureUpdateDtoInOut: ProcedureUpdateDtoInOut, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).processProcedureUpdate(procedureUpdateDtoInOut, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PasswordsControllerApi - axios parameter creator
 * @export
 */
export const PasswordsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generates a random password.
         * @summary Generates a random password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePassword: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/passwords/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordsControllerApi - functional programming interface
 * @export
 */
export const PasswordsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Generates a random password.
         * @summary Generates a random password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePassword(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePassword(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordsControllerApi - factory interface
 * @export
 */
export const PasswordsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordsControllerApiFp(configuration)
    return {
        /**
         * Generates a random password.
         * @summary Generates a random password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePassword(options?: any): AxiosPromise<PasswordDtoOut> {
            return localVarFp.generatePassword(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordsControllerApi - object-oriented interface
 * @export
 * @class PasswordsControllerApi
 * @extends {BaseAPI}
 */
export class PasswordsControllerApi extends BaseAPI {
    /**
     * Generates a random password.
     * @summary Generates a random password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordsControllerApi
     */
    public generatePassword(options?: AxiosRequestConfig) {
        return PasswordsControllerApiFp(this.configuration).generatePassword(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientCaresControllerApi - axios parameter creator
 * @export
 */
export const PatientCaresControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Assign care to a patient.
         * @summary Assign care to a patient.
         * @param {string} patientId
         * @param {AssignCareDtoIn} assignCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCare: async (patientId: string, assignCareDtoIn: AssignCareDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('assignCare', 'patientId', patientId)
            // verify required parameter 'assignCareDtoIn' is not null or undefined
            assertParamExists('assignCare', 'assignCareDtoIn', assignCareDtoIn)
            const localVarPath = `/api/patients/{patientId}/assign-care`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignCareDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * End care of a patient.
         * @summary End care of a patient.
         * @param {string} patientId
         * @param {EndCareDtoIn} endCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endCare: async (patientId: string, endCareDtoIn: EndCareDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('endCare', 'patientId', patientId)
            // verify required parameter 'endCareDtoIn' is not null or undefined
            assertParamExists('endCare', 'endCareDtoIn', endCareDtoIn)
            const localVarPath = `/api/patients/{patientId}/end-care`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endCareDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get care detail.
         * @summary Get care detail.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCare: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getCare', 'patientId', patientId)
            const localVarPath = `/api/patients/{patientId}/care`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get ward round occurrences for a given care.
         * @summary Get ward round occurrences for a given care.
         * @param {string} patientId
         * @param {string} careId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRoundOccurrences: async (patientId: string, careId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getWardRoundOccurrences', 'patientId', patientId)
            // verify required parameter 'careId' is not null or undefined
            assertParamExists('getWardRoundOccurrences', 'careId', careId)
            const localVarPath = `/api/patients/{patientId}/cares/{careId}/ward-rounds-occurrences`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"careId"}}`, encodeURIComponent(String(careId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientCaresControllerApi - functional programming interface
 * @export
 */
export const PatientCaresControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientCaresControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Assign care to a patient.
         * @summary Assign care to a patient.
         * @param {string} patientId
         * @param {AssignCareDtoIn} assignCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignCare(patientId: string, assignCareDtoIn: AssignCareDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignCare(patientId, assignCareDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * End care of a patient.
         * @summary End care of a patient.
         * @param {string} patientId
         * @param {EndCareDtoIn} endCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endCare(patientId: string, endCareDtoIn: EndCareDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endCare(patientId, endCareDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get care detail.
         * @summary Get care detail.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCare(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCare(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get ward round occurrences for a given care.
         * @summary Get ward round occurrences for a given care.
         * @param {string} patientId
         * @param {string} careId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWardRoundOccurrences(patientId: string, careId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WardRoundOccurrenceDtoOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWardRoundOccurrences(patientId, careId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientCaresControllerApi - factory interface
 * @export
 */
export const PatientCaresControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientCaresControllerApiFp(configuration)
    return {
        /**
         * Assign care to a patient.
         * @summary Assign care to a patient.
         * @param {string} patientId
         * @param {AssignCareDtoIn} assignCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCare(patientId: string, assignCareDtoIn: AssignCareDtoIn, options?: any): AxiosPromise<CareDtoOut> {
            return localVarFp.assignCare(patientId, assignCareDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * End care of a patient.
         * @summary End care of a patient.
         * @param {string} patientId
         * @param {EndCareDtoIn} endCareDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endCare(patientId: string, endCareDtoIn: EndCareDtoIn, options?: any): AxiosPromise<CareDtoOut> {
            return localVarFp.endCare(patientId, endCareDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Get care detail.
         * @summary Get care detail.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCare(patientId: string, options?: any): AxiosPromise<CareDtoOut> {
            return localVarFp.getCare(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get ward round occurrences for a given care.
         * @summary Get ward round occurrences for a given care.
         * @param {string} patientId
         * @param {string} careId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRoundOccurrences(patientId: string, careId: string, options?: any): AxiosPromise<Array<WardRoundOccurrenceDtoOut>> {
            return localVarFp.getWardRoundOccurrences(patientId, careId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientCaresControllerApi - object-oriented interface
 * @export
 * @class PatientCaresControllerApi
 * @extends {BaseAPI}
 */
export class PatientCaresControllerApi extends BaseAPI {
    /**
     * Assign care to a patient.
     * @summary Assign care to a patient.
     * @param {string} patientId
     * @param {AssignCareDtoIn} assignCareDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientCaresControllerApi
     */
    public assignCare(patientId: string, assignCareDtoIn: AssignCareDtoIn, options?: AxiosRequestConfig) {
        return PatientCaresControllerApiFp(this.configuration).assignCare(patientId, assignCareDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * End care of a patient.
     * @summary End care of a patient.
     * @param {string} patientId
     * @param {EndCareDtoIn} endCareDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientCaresControllerApi
     */
    public endCare(patientId: string, endCareDtoIn: EndCareDtoIn, options?: AxiosRequestConfig) {
        return PatientCaresControllerApiFp(this.configuration).endCare(patientId, endCareDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get care detail.
     * @summary Get care detail.
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientCaresControllerApi
     */
    public getCare(patientId: string, options?: AxiosRequestConfig) {
        return PatientCaresControllerApiFp(this.configuration).getCare(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get ward round occurrences for a given care.
     * @summary Get ward round occurrences for a given care.
     * @param {string} patientId
     * @param {string} careId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientCaresControllerApi
     */
    public getWardRoundOccurrences(patientId: string, careId: string, options?: AxiosRequestConfig) {
        return PatientCaresControllerApiFp(this.configuration).getWardRoundOccurrences(patientId, careId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientChallengeControllerApi - axios parameter creator
 * @export
 */
export const PatientChallengeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get patient\'s challenges.
         * @summary Get patient\'s challenges.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientChallenges: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientChallenges', 'patientId', patientId)
            const localVarPath = `/api/patients/{patientId}/challenges`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets patient\'s challenge progress between two dates.
         * @summary Gets patient\'s challenge progress between two dates.
         * @param {string} patientId
         * @param {string} [since]
         * @param {string} [until]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientChallengesProgress: async (patientId: string, since?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientChallengesProgress', 'patientId', patientId)
            const localVarPath = `/api/patients/{patientId}/challenges/progress`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets patient\'s challenge progress between two dates.
         * @summary Gets patient\'s challenge progress between two dates.
         * @param {string} [since]
         * @param {string} [until]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientChallengesProgress1: async (since?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patients/me/challenges/progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get patient\'s challenges.
         * @summary Get patient\'s challenges.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsChallenges: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patients/me/challenges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insert time series of vitality data.
         * @summary Insert time series of vitality data.
         * @param {Array<VitalityTimeSeriesDtoIn>} vitalityTimeSeriesDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTimeSeriesOfVitalityData: async (vitalityTimeSeriesDtoIn: Array<VitalityTimeSeriesDtoIn>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vitalityTimeSeriesDtoIn' is not null or undefined
            assertParamExists('insertTimeSeriesOfVitalityData', 'vitalityTimeSeriesDtoIn', vitalityTimeSeriesDtoIn)
            const localVarPath = `/api/patients/me/challenges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vitalityTimeSeriesDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates patient\'s challenge.
         * @summary Updates patient\'s challenge.
         * @param {string} patientId
         * @param {string} patientChallengeId
         * @param {PatientChallengeUpdateOfValuesDtoIn} patientChallengeUpdateOfValuesDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientChallengeValues: async (patientId: string, patientChallengeId: string, patientChallengeUpdateOfValuesDtoIn: PatientChallengeUpdateOfValuesDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updatePatientChallengeValues', 'patientId', patientId)
            // verify required parameter 'patientChallengeId' is not null or undefined
            assertParamExists('updatePatientChallengeValues', 'patientChallengeId', patientChallengeId)
            // verify required parameter 'patientChallengeUpdateOfValuesDtoIn' is not null or undefined
            assertParamExists('updatePatientChallengeValues', 'patientChallengeUpdateOfValuesDtoIn', patientChallengeUpdateOfValuesDtoIn)
            const localVarPath = `/api/patients/{patientId}/challenges/{patientChallengeId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"patientChallengeId"}}`, encodeURIComponent(String(patientChallengeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientChallengeUpdateOfValuesDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates patient\'s challenges.
         * @summary Updates patient\'s challenges.
         * @param {string} patientId
         * @param {Array<UpdatePatientChallengesRequestInner>} updatePatientChallengesRequestInner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientChallenges: async (patientId: string, updatePatientChallengesRequestInner: Array<UpdatePatientChallengesRequestInner>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updatePatientChallenges', 'patientId', patientId)
            // verify required parameter 'updatePatientChallengesRequestInner' is not null or undefined
            assertParamExists('updatePatientChallenges', 'updatePatientChallengesRequestInner', updatePatientChallengesRequestInner)
            const localVarPath = `/api/patients/{patientId}/challenges`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientChallengesRequestInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates patient\'s challenge.
         * @summary Updates patient\'s challenge.
         * @param {string} patientChallengeId
         * @param {PatientChallengeUpdateOfValuesDtoIn} patientChallengeUpdateOfValuesDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientsChallengeValues: async (patientChallengeId: string, patientChallengeUpdateOfValuesDtoIn: PatientChallengeUpdateOfValuesDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientChallengeId' is not null or undefined
            assertParamExists('updatePatientsChallengeValues', 'patientChallengeId', patientChallengeId)
            // verify required parameter 'patientChallengeUpdateOfValuesDtoIn' is not null or undefined
            assertParamExists('updatePatientsChallengeValues', 'patientChallengeUpdateOfValuesDtoIn', patientChallengeUpdateOfValuesDtoIn)
            const localVarPath = `/api/patients/me/challenges/{patientChallengeId}`
                .replace(`{${"patientChallengeId"}}`, encodeURIComponent(String(patientChallengeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientChallengeUpdateOfValuesDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientChallengeControllerApi - functional programming interface
 * @export
 */
export const PatientChallengeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientChallengeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Get patient\'s challenges.
         * @summary Get patient\'s challenges.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientChallenges(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPatientChallenges200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientChallenges(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets patient\'s challenge progress between two dates.
         * @summary Gets patient\'s challenge progress between two dates.
         * @param {string} patientId
         * @param {string} [since]
         * @param {string} [until]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientChallengesProgress(patientId: string, since?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeProgressSummaryDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientChallengesProgress(patientId, since, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets patient\'s challenge progress between two dates.
         * @summary Gets patient\'s challenge progress between two dates.
         * @param {string} [since]
         * @param {string} [until]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientChallengesProgress1(since?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeProgressSummaryDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientChallengesProgress1(since, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get patient\'s challenges.
         * @summary Get patient\'s challenges.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientsChallenges(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPatientChallenges200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientsChallenges(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Insert time series of vitality data.
         * @summary Insert time series of vitality data.
         * @param {Array<VitalityTimeSeriesDtoIn>} vitalityTimeSeriesDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertTimeSeriesOfVitalityData(vitalityTimeSeriesDtoIn: Array<VitalityTimeSeriesDtoIn>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertTimeSeriesOfVitalityData(vitalityTimeSeriesDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates patient\'s challenge.
         * @summary Updates patient\'s challenge.
         * @param {string} patientId
         * @param {string} patientChallengeId
         * @param {PatientChallengeUpdateOfValuesDtoIn} patientChallengeUpdateOfValuesDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientChallengeValues(patientId: string, patientChallengeId: string, patientChallengeUpdateOfValuesDtoIn: PatientChallengeUpdateOfValuesDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientChallenges200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientChallengeValues(patientId, patientChallengeId, patientChallengeUpdateOfValuesDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates patient\'s challenges.
         * @summary Updates patient\'s challenges.
         * @param {string} patientId
         * @param {Array<UpdatePatientChallengesRequestInner>} updatePatientChallengesRequestInner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientChallenges(patientId: string, updatePatientChallengesRequestInner: Array<UpdatePatientChallengesRequestInner>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPatientChallenges200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientChallenges(patientId, updatePatientChallengesRequestInner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates patient\'s challenge.
         * @summary Updates patient\'s challenge.
         * @param {string} patientChallengeId
         * @param {PatientChallengeUpdateOfValuesDtoIn} patientChallengeUpdateOfValuesDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientsChallengeValues(patientChallengeId: string, patientChallengeUpdateOfValuesDtoIn: PatientChallengeUpdateOfValuesDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientChallenges200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientsChallengeValues(patientChallengeId, patientChallengeUpdateOfValuesDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientChallengeControllerApi - factory interface
 * @export
 */
export const PatientChallengeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientChallengeControllerApiFp(configuration)
    return {
        /**
         * Get patient\'s challenges.
         * @summary Get patient\'s challenges.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientChallenges(patientId: string, options?: any): AxiosPromise<Array<GetPatientChallenges200ResponseInner>> {
            return localVarFp.getPatientChallenges(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets patient\'s challenge progress between two dates.
         * @summary Gets patient\'s challenge progress between two dates.
         * @param {string} patientId
         * @param {string} [since]
         * @param {string} [until]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientChallengesProgress(patientId: string, since?: string, until?: string, options?: any): AxiosPromise<ChallengeProgressSummaryDtoOut> {
            return localVarFp.getPatientChallengesProgress(patientId, since, until, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets patient\'s challenge progress between two dates.
         * @summary Gets patient\'s challenge progress between two dates.
         * @param {string} [since]
         * @param {string} [until]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientChallengesProgress1(since?: string, until?: string, options?: any): AxiosPromise<ChallengeProgressSummaryDtoOut> {
            return localVarFp.getPatientChallengesProgress1(since, until, options).then((request) => request(axios, basePath));
        },
        /**
         * Get patient\'s challenges.
         * @summary Get patient\'s challenges.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsChallenges(options?: any): AxiosPromise<Array<GetPatientChallenges200ResponseInner>> {
            return localVarFp.getPatientsChallenges(options).then((request) => request(axios, basePath));
        },
        /**
         * Insert time series of vitality data.
         * @summary Insert time series of vitality data.
         * @param {Array<VitalityTimeSeriesDtoIn>} vitalityTimeSeriesDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTimeSeriesOfVitalityData(vitalityTimeSeriesDtoIn: Array<VitalityTimeSeriesDtoIn>, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.insertTimeSeriesOfVitalityData(vitalityTimeSeriesDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates patient\'s challenge.
         * @summary Updates patient\'s challenge.
         * @param {string} patientId
         * @param {string} patientChallengeId
         * @param {PatientChallengeUpdateOfValuesDtoIn} patientChallengeUpdateOfValuesDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientChallengeValues(patientId: string, patientChallengeId: string, patientChallengeUpdateOfValuesDtoIn: PatientChallengeUpdateOfValuesDtoIn, options?: any): AxiosPromise<GetPatientChallenges200ResponseInner> {
            return localVarFp.updatePatientChallengeValues(patientId, patientChallengeId, patientChallengeUpdateOfValuesDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates patient\'s challenges.
         * @summary Updates patient\'s challenges.
         * @param {string} patientId
         * @param {Array<UpdatePatientChallengesRequestInner>} updatePatientChallengesRequestInner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientChallenges(patientId: string, updatePatientChallengesRequestInner: Array<UpdatePatientChallengesRequestInner>, options?: any): AxiosPromise<Array<GetPatientChallenges200ResponseInner>> {
            return localVarFp.updatePatientChallenges(patientId, updatePatientChallengesRequestInner, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates patient\'s challenge.
         * @summary Updates patient\'s challenge.
         * @param {string} patientChallengeId
         * @param {PatientChallengeUpdateOfValuesDtoIn} patientChallengeUpdateOfValuesDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientsChallengeValues(patientChallengeId: string, patientChallengeUpdateOfValuesDtoIn: PatientChallengeUpdateOfValuesDtoIn, options?: any): AxiosPromise<GetPatientChallenges200ResponseInner> {
            return localVarFp.updatePatientsChallengeValues(patientChallengeId, patientChallengeUpdateOfValuesDtoIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientChallengeControllerApi - object-oriented interface
 * @export
 * @class PatientChallengeControllerApi
 * @extends {BaseAPI}
 */
export class PatientChallengeControllerApi extends BaseAPI {
    /**
     * Get patient\'s challenges.
     * @summary Get patient\'s challenges.
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientChallengeControllerApi
     */
    public getPatientChallenges(patientId: string, options?: AxiosRequestConfig) {
        return PatientChallengeControllerApiFp(this.configuration).getPatientChallenges(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets patient\'s challenge progress between two dates.
     * @summary Gets patient\'s challenge progress between two dates.
     * @param {string} patientId
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientChallengeControllerApi
     */
    public getPatientChallengesProgress(patientId: string, since?: string, until?: string, options?: AxiosRequestConfig) {
        return PatientChallengeControllerApiFp(this.configuration).getPatientChallengesProgress(patientId, since, until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets patient\'s challenge progress between two dates.
     * @summary Gets patient\'s challenge progress between two dates.
     * @param {string} [since]
     * @param {string} [until]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientChallengeControllerApi
     */
    public getPatientChallengesProgress1(since?: string, until?: string, options?: AxiosRequestConfig) {
        return PatientChallengeControllerApiFp(this.configuration).getPatientChallengesProgress1(since, until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get patient\'s challenges.
     * @summary Get patient\'s challenges.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientChallengeControllerApi
     */
    public getPatientsChallenges(options?: AxiosRequestConfig) {
        return PatientChallengeControllerApiFp(this.configuration).getPatientsChallenges(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insert time series of vitality data.
     * @summary Insert time series of vitality data.
     * @param {Array<VitalityTimeSeriesDtoIn>} vitalityTimeSeriesDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientChallengeControllerApi
     */
    public insertTimeSeriesOfVitalityData(vitalityTimeSeriesDtoIn: Array<VitalityTimeSeriesDtoIn>, options?: AxiosRequestConfig) {
        return PatientChallengeControllerApiFp(this.configuration).insertTimeSeriesOfVitalityData(vitalityTimeSeriesDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates patient\'s challenge.
     * @summary Updates patient\'s challenge.
     * @param {string} patientId
     * @param {string} patientChallengeId
     * @param {PatientChallengeUpdateOfValuesDtoIn} patientChallengeUpdateOfValuesDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientChallengeControllerApi
     */
    public updatePatientChallengeValues(patientId: string, patientChallengeId: string, patientChallengeUpdateOfValuesDtoIn: PatientChallengeUpdateOfValuesDtoIn, options?: AxiosRequestConfig) {
        return PatientChallengeControllerApiFp(this.configuration).updatePatientChallengeValues(patientId, patientChallengeId, patientChallengeUpdateOfValuesDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates patient\'s challenges.
     * @summary Updates patient\'s challenges.
     * @param {string} patientId
     * @param {Array<UpdatePatientChallengesRequestInner>} updatePatientChallengesRequestInner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientChallengeControllerApi
     */
    public updatePatientChallenges(patientId: string, updatePatientChallengesRequestInner: Array<UpdatePatientChallengesRequestInner>, options?: AxiosRequestConfig) {
        return PatientChallengeControllerApiFp(this.configuration).updatePatientChallenges(patientId, updatePatientChallengesRequestInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates patient\'s challenge.
     * @summary Updates patient\'s challenge.
     * @param {string} patientChallengeId
     * @param {PatientChallengeUpdateOfValuesDtoIn} patientChallengeUpdateOfValuesDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientChallengeControllerApi
     */
    public updatePatientsChallengeValues(patientChallengeId: string, patientChallengeUpdateOfValuesDtoIn: PatientChallengeUpdateOfValuesDtoIn, options?: AxiosRequestConfig) {
        return PatientChallengeControllerApiFp(this.configuration).updatePatientsChallengeValues(patientChallengeId, patientChallengeUpdateOfValuesDtoIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientControllerApi - axios parameter creator
 * @export
 */
export const PatientControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add or update patient\'s device.
         * @summary Add or update patient\'s device.
         * @param {DeviceDtoIn} deviceDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateDevice: async (deviceDtoIn: DeviceDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceDtoIn' is not null or undefined
            assertParamExists('addOrUpdateDevice', 'deviceDtoIn', deviceDtoIn)
            const localVarPath = `/api/patients/device-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add new patient.
         * @summary Add new patient.
         * @param {UpsertPatientDtoIn} upsertPatientDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatient: async (upsertPatientDtoIn: UpsertPatientDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertPatientDtoIn' is not null or undefined
            assertParamExists('createPatient', 'upsertPatientDtoIn', upsertPatientDtoIn)
            const localVarPath = `/api/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertPatientDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete patient.
         * @summary Delete patient.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePatient', 'id', id)
            const localVarPath = `/api/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all patient\'s events.
         * @summary Get all patient\'s events.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEvents', 'id', id)
            const localVarPath = `/api/patients/{id}/events`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get patient by id.
         * @summary Get patient by id.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPatient', 'id', id)
            const localVarPath = `/api/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get doctor\'s patient detail.
         * @summary Get doctor\'s patient detail.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPatientDetail', 'id', id)
            const localVarPath = `/api/patients/{id}/patient-detail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get patient detail.
         * @summary Get patient detail.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetail1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patients/me/patient-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get doctor\'s patients.
         * @summary Get doctor\'s patients.
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [personalNumber]
         * @param {string} [fullName]
         * @param {boolean} [isCritical]
         * @param {boolean} [usedApplication]
         * @param {Array<string>} [careIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatients: async (page?: number, size?: number, personalNumber?: string, fullName?: string, isCritical?: boolean, usedApplication?: boolean, careIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (personalNumber !== undefined) {
                localVarQueryParameter['personalNumber'] = personalNumber;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['fullName'] = fullName;
            }

            if (isCritical !== undefined) {
                localVarQueryParameter['isCritical'] = isCritical;
            }

            if (usedApplication !== undefined) {
                localVarQueryParameter['usedApplication'] = usedApplication;
            }

            if (careIds) {
                localVarQueryParameter['careIds'] = careIds;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all patient\'s questionnaires.
         * @summary Get all patient\'s questionnaires.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaires: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuestionnaires', 'id', id)
            const localVarPath = `/api/patients/{id}/questionnaires`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resolve patient\'s critical state.
         * @summary Resolve patient\'s critical state.
         * @param {string} patientId
         * @param {PatientCriticalResolutionDtoIn} patientCriticalResolutionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveCriticalState: async (patientId: string, patientCriticalResolutionDtoIn: PatientCriticalResolutionDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('resolveCriticalState', 'patientId', patientId)
            // verify required parameter 'patientCriticalResolutionDtoIn' is not null or undefined
            assertParamExists('resolveCriticalState', 'patientCriticalResolutionDtoIn', patientCriticalResolutionDtoIn)
            const localVarPath = `/api/patients/{patientId}/critical-resolution`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientCriticalResolutionDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set patient\'s password.
         * @summary Set patient\'s password.
         * @param {string} id
         * @param {PasswordDtoIn} passwordDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPatientPassword: async (id: string, passwordDtoIn: PasswordDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setPatientPassword', 'id', id)
            // verify required parameter 'passwordDtoIn' is not null or undefined
            assertParamExists('setPatientPassword', 'passwordDtoIn', passwordDtoIn)
            const localVarPath = `/api/patients/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update patient.
         * @summary Update patient.
         * @param {string} id
         * @param {UpsertPatientDtoIn} upsertPatientDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatient: async (id: string, upsertPatientDtoIn: UpsertPatientDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePatient', 'id', id)
            // verify required parameter 'upsertPatientDtoIn' is not null or undefined
            assertParamExists('updatePatient', 'upsertPatientDtoIn', upsertPatientDtoIn)
            const localVarPath = `/api/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertPatientDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientControllerApi - functional programming interface
 * @export
 */
export const PatientControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Add or update patient\'s device.
         * @summary Add or update patient\'s device.
         * @param {DeviceDtoIn} deviceDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateDevice(deviceDtoIn: DeviceDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateDevice(deviceDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add new patient.
         * @summary Add new patient.
         * @param {UpsertPatientDtoIn} upsertPatientDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPatient(upsertPatientDtoIn: UpsertPatientDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPatient(upsertPatientDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete patient.
         * @summary Delete patient.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePatient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePatient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all patient\'s events.
         * @summary Get all patient\'s events.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvents(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientEventDtoOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvents(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get patient by id.
         * @summary Get patient by id.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get doctor\'s patient detail.
         * @summary Get doctor\'s patient detail.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get patient detail.
         * @summary Get patient detail.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientDetail1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientDetail1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get doctor\'s patients.
         * @summary Get doctor\'s patients.
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [personalNumber]
         * @param {string} [fullName]
         * @param {boolean} [isCritical]
         * @param {boolean} [usedApplication]
         * @param {Array<string>} [careIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatients(page?: number, size?: number, personalNumber?: string, fullName?: string, isCritical?: boolean, usedApplication?: boolean, careIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagebleDtoOutPatientDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatients(page, size, personalNumber, fullName, isCritical, usedApplication, careIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all patient\'s questionnaires.
         * @summary Get all patient\'s questionnaires.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionnaires(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionnaireDtoOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionnaires(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resolve patient\'s critical state.
         * @summary Resolve patient\'s critical state.
         * @param {string} patientId
         * @param {PatientCriticalResolutionDtoIn} patientCriticalResolutionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resolveCriticalState(patientId: string, patientCriticalResolutionDtoIn: PatientCriticalResolutionDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resolveCriticalState(patientId, patientCriticalResolutionDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set patient\'s password.
         * @summary Set patient\'s password.
         * @param {string} id
         * @param {PasswordDtoIn} passwordDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPatientPassword(id: string, passwordDtoIn: PasswordDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPatientPassword(id, passwordDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update patient.
         * @summary Update patient.
         * @param {string} id
         * @param {UpsertPatientDtoIn} upsertPatientDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatient(id: string, upsertPatientDtoIn: UpsertPatientDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatient(id, upsertPatientDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientControllerApi - factory interface
 * @export
 */
export const PatientControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientControllerApiFp(configuration)
    return {
        /**
         * Add or update patient\'s device.
         * @summary Add or update patient\'s device.
         * @param {DeviceDtoIn} deviceDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateDevice(deviceDtoIn: DeviceDtoIn, options?: any): AxiosPromise<DeviceDtoOut> {
            return localVarFp.addOrUpdateDevice(deviceDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Add new patient.
         * @summary Add new patient.
         * @param {UpsertPatientDtoIn} upsertPatientDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatient(upsertPatientDtoIn: UpsertPatientDtoIn, options?: any): AxiosPromise<PatientDtoOut> {
            return localVarFp.createPatient(upsertPatientDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete patient.
         * @summary Delete patient.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatient(id: string, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.deletePatient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all patient\'s events.
         * @summary Get all patient\'s events.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(id: string, options?: any): AxiosPromise<Array<PatientEventDtoOut>> {
            return localVarFp.getEvents(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get patient by id.
         * @summary Get patient by id.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatient(id: string, options?: any): AxiosPromise<PatientDtoOut> {
            return localVarFp.getPatient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get doctor\'s patient detail.
         * @summary Get doctor\'s patient detail.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetail(id: string, options?: any): AxiosPromise<PatientDetailDtoOut> {
            return localVarFp.getPatientDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get patient detail.
         * @summary Get patient detail.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetail1(options?: any): AxiosPromise<PatientDetailDtoOut> {
            return localVarFp.getPatientDetail1(options).then((request) => request(axios, basePath));
        },
        /**
         * Get doctor\'s patients.
         * @summary Get doctor\'s patients.
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [personalNumber]
         * @param {string} [fullName]
         * @param {boolean} [isCritical]
         * @param {boolean} [usedApplication]
         * @param {Array<string>} [careIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatients(page?: number, size?: number, personalNumber?: string, fullName?: string, isCritical?: boolean, usedApplication?: boolean, careIds?: Array<string>, options?: any): AxiosPromise<PagebleDtoOutPatientDtoOut> {
            return localVarFp.getPatients(page, size, personalNumber, fullName, isCritical, usedApplication, careIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all patient\'s questionnaires.
         * @summary Get all patient\'s questionnaires.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaires(id: string, options?: any): AxiosPromise<Array<QuestionnaireDtoOut>> {
            return localVarFp.getQuestionnaires(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Resolve patient\'s critical state.
         * @summary Resolve patient\'s critical state.
         * @param {string} patientId
         * @param {PatientCriticalResolutionDtoIn} patientCriticalResolutionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveCriticalState(patientId: string, patientCriticalResolutionDtoIn: PatientCriticalResolutionDtoIn, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.resolveCriticalState(patientId, patientCriticalResolutionDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Set patient\'s password.
         * @summary Set patient\'s password.
         * @param {string} id
         * @param {PasswordDtoIn} passwordDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPatientPassword(id: string, passwordDtoIn: PasswordDtoIn, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.setPatientPassword(id, passwordDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Update patient.
         * @summary Update patient.
         * @param {string} id
         * @param {UpsertPatientDtoIn} upsertPatientDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatient(id: string, upsertPatientDtoIn: UpsertPatientDtoIn, options?: any): AxiosPromise<PatientDtoOut> {
            return localVarFp.updatePatient(id, upsertPatientDtoIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientControllerApi - object-oriented interface
 * @export
 * @class PatientControllerApi
 * @extends {BaseAPI}
 */
export class PatientControllerApi extends BaseAPI {
    /**
     * Add or update patient\'s device.
     * @summary Add or update patient\'s device.
     * @param {DeviceDtoIn} deviceDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public addOrUpdateDevice(deviceDtoIn: DeviceDtoIn, options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).addOrUpdateDevice(deviceDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add new patient.
     * @summary Add new patient.
     * @param {UpsertPatientDtoIn} upsertPatientDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public createPatient(upsertPatientDtoIn: UpsertPatientDtoIn, options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).createPatient(upsertPatientDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete patient.
     * @summary Delete patient.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public deletePatient(id: string, options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).deletePatient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all patient\'s events.
     * @summary Get all patient\'s events.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public getEvents(id: string, options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).getEvents(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get patient by id.
     * @summary Get patient by id.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public getPatient(id: string, options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).getPatient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get doctor\'s patient detail.
     * @summary Get doctor\'s patient detail.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public getPatientDetail(id: string, options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).getPatientDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get patient detail.
     * @summary Get patient detail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public getPatientDetail1(options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).getPatientDetail1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get doctor\'s patients.
     * @summary Get doctor\'s patients.
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [personalNumber]
     * @param {string} [fullName]
     * @param {boolean} [isCritical]
     * @param {boolean} [usedApplication]
     * @param {Array<string>} [careIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public getPatients(page?: number, size?: number, personalNumber?: string, fullName?: string, isCritical?: boolean, usedApplication?: boolean, careIds?: Array<string>, options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).getPatients(page, size, personalNumber, fullName, isCritical, usedApplication, careIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all patient\'s questionnaires.
     * @summary Get all patient\'s questionnaires.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public getQuestionnaires(id: string, options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).getQuestionnaires(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resolve patient\'s critical state.
     * @summary Resolve patient\'s critical state.
     * @param {string} patientId
     * @param {PatientCriticalResolutionDtoIn} patientCriticalResolutionDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public resolveCriticalState(patientId: string, patientCriticalResolutionDtoIn: PatientCriticalResolutionDtoIn, options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).resolveCriticalState(patientId, patientCriticalResolutionDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set patient\'s password.
     * @summary Set patient\'s password.
     * @param {string} id
     * @param {PasswordDtoIn} passwordDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public setPatientPassword(id: string, passwordDtoIn: PasswordDtoIn, options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).setPatientPassword(id, passwordDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update patient.
     * @summary Update patient.
     * @param {string} id
     * @param {UpsertPatientDtoIn} upsertPatientDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public updatePatient(id: string, upsertPatientDtoIn: UpsertPatientDtoIn, options?: AxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).updatePatient(id, upsertPatientDtoIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientFileControllerApi - axios parameter creator
 * @export
 */
export const PatientFileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get patient\'s files.
         * @summary Get patient\'s files.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientFiles: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientFiles', 'patientId', patientId)
            const localVarPath = `/api/patients/{patientId}/files`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get patient\'s files.
         * @summary Get patient\'s files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsFiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patients/me/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload file.
         * @summary Upload file.
         * @param {string} actionId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (actionId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionId' is not null or undefined
            assertParamExists('uploadFile', 'actionId', actionId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFile', 'file', file)
            const localVarPath = `/api/patients/me/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (actionId !== undefined) {
                localVarQueryParameter['actionId'] = actionId;
            }


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientFileControllerApi - functional programming interface
 * @export
 */
export const PatientFileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientFileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Get patient\'s files.
         * @summary Get patient\'s files.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientFiles(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientFiles(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get patient\'s files.
         * @summary Get patient\'s files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientsFiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientsFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload file.
         * @summary Upload file.
         * @param {string} actionId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(actionId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(actionId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientFileControllerApi - factory interface
 * @export
 */
export const PatientFileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientFileControllerApiFp(configuration)
    return {
        /**
         * Get patient\'s files.
         * @summary Get patient\'s files.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientFiles(patientId: string, options?: any): AxiosPromise<Array<PatientFileDto>> {
            return localVarFp.getPatientFiles(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get patient\'s files.
         * @summary Get patient\'s files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsFiles(options?: any): AxiosPromise<Array<PatientFileDto>> {
            return localVarFp.getPatientsFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * Upload file.
         * @summary Upload file.
         * @param {string} actionId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(actionId: string, file: any, options?: any): AxiosPromise<PatientFileDto> {
            return localVarFp.uploadFile(actionId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientFileControllerApi - object-oriented interface
 * @export
 * @class PatientFileControllerApi
 * @extends {BaseAPI}
 */
export class PatientFileControllerApi extends BaseAPI {
    /**
     * Get patient\'s files.
     * @summary Get patient\'s files.
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientFileControllerApi
     */
    public getPatientFiles(patientId: string, options?: AxiosRequestConfig) {
        return PatientFileControllerApiFp(this.configuration).getPatientFiles(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get patient\'s files.
     * @summary Get patient\'s files.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientFileControllerApi
     */
    public getPatientsFiles(options?: AxiosRequestConfig) {
        return PatientFileControllerApiFp(this.configuration).getPatientsFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload file.
     * @summary Upload file.
     * @param {string} actionId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientFileControllerApi
     */
    public uploadFile(actionId: string, file: any, options?: AxiosRequestConfig) {
        return PatientFileControllerApiFp(this.configuration).uploadFile(actionId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientWardRoundOccurrencesControllerApi - axios parameter creator
 * @export
 */
export const PatientWardRoundOccurrencesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Answer questionnaire associated to a ward round.
         * @summary Answer questionnaire associated to a ward round.
         * @param {string} wardRoundOccurrenceId
         * @param {string} questionnaireId
         * @param {AnsweredQuestionDtoIn} answeredQuestionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerQuestionnaire: async (wardRoundOccurrenceId: string, questionnaireId: string, answeredQuestionDtoIn: AnsweredQuestionDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wardRoundOccurrenceId' is not null or undefined
            assertParamExists('answerQuestionnaire', 'wardRoundOccurrenceId', wardRoundOccurrenceId)
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('answerQuestionnaire', 'questionnaireId', questionnaireId)
            // verify required parameter 'answeredQuestionDtoIn' is not null or undefined
            assertParamExists('answerQuestionnaire', 'answeredQuestionDtoIn', answeredQuestionDtoIn)
            const localVarPath = `/api/patients/me/ward-rounds/{wardRoundOccurrenceId}/questionnaires/{questionnaireId}/answers`
                .replace(`{${"wardRoundOccurrenceId"}}`, encodeURIComponent(String(wardRoundOccurrenceId)))
                .replace(`{${"questionnaireId"}}`, encodeURIComponent(String(questionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answeredQuestionDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark ward round occurrence as completed.
         * @summary Mark ward round occurrence as completed.
         * @param {string} wardRoundOccurrenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complete: async (wardRoundOccurrenceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wardRoundOccurrenceId' is not null or undefined
            assertParamExists('complete', 'wardRoundOccurrenceId', wardRoundOccurrenceId)
            const localVarPath = `/api/patients/me/ward-rounds/{wardRoundOccurrenceId}/complete`
                .replace(`{${"wardRoundOccurrenceId"}}`, encodeURIComponent(String(wardRoundOccurrenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current patient\'s ward round occurrence detail.
         * @summary Get current patient\'s ward round occurrence detail.
         * @param {string} id
         * @param {string} occurrenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWardRoundOccurrence: async (id: string, occurrenceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMyWardRoundOccurrence', 'id', id)
            // verify required parameter 'occurrenceId' is not null or undefined
            assertParamExists('getMyWardRoundOccurrence', 'occurrenceId', occurrenceId)
            const localVarPath = `/api/patients/me/ward-rounds/{id}/occurrences/{occurrenceId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"occurrenceId"}}`, encodeURIComponent(String(occurrenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get patient\'s ward round occurrence detail.
         * @summary Get patient\'s ward round occurrence detail.
         * @param {string} patientId
         * @param {string} wardRoundId
         * @param {string} occurrenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRoundOccurrence: async (patientId: string, wardRoundId: string, occurrenceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getWardRoundOccurrence', 'patientId', patientId)
            // verify required parameter 'wardRoundId' is not null or undefined
            assertParamExists('getWardRoundOccurrence', 'wardRoundId', wardRoundId)
            // verify required parameter 'occurrenceId' is not null or undefined
            assertParamExists('getWardRoundOccurrence', 'occurrenceId', occurrenceId)
            const localVarPath = `/api/patients/{patientId}/ward-rounds/{wardRoundId}/occurrences/{occurrenceId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"wardRoundId"}}`, encodeURIComponent(String(wardRoundId)))
                .replace(`{${"occurrenceId"}}`, encodeURIComponent(String(occurrenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify patient\'s answer to a questionnaire associated to a ward round.
         * @summary Modify patient\'s answer to a questionnaire associated to a ward round.
         * @param {string} patientId
         * @param {string} wardRoundOccurrenceId
         * @param {string} questionnaireId
         * @param {AnsweredQuestionDtoIn} answeredQuestionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAnswer: async (patientId: string, wardRoundOccurrenceId: string, questionnaireId: string, answeredQuestionDtoIn: AnsweredQuestionDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('modifyAnswer', 'patientId', patientId)
            // verify required parameter 'wardRoundOccurrenceId' is not null or undefined
            assertParamExists('modifyAnswer', 'wardRoundOccurrenceId', wardRoundOccurrenceId)
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('modifyAnswer', 'questionnaireId', questionnaireId)
            // verify required parameter 'answeredQuestionDtoIn' is not null or undefined
            assertParamExists('modifyAnswer', 'answeredQuestionDtoIn', answeredQuestionDtoIn)
            const localVarPath = `/api/patients/{patientId}/ward-rounds/{wardRoundOccurrenceId}/questionnaires/{questionnaireId}/answers`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"wardRoundOccurrenceId"}}`, encodeURIComponent(String(wardRoundOccurrenceId)))
                .replace(`{${"questionnaireId"}}`, encodeURIComponent(String(questionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answeredQuestionDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Track patient\'s medicine intake.
         * @summary Track patient\'s medicine intake.
         * @param {string} wardRoundOccurrenceId
         * @param {MedicineIntakeDtoIn} medicineIntakeDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeMedicine: async (wardRoundOccurrenceId: string, medicineIntakeDtoIn: MedicineIntakeDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wardRoundOccurrenceId' is not null or undefined
            assertParamExists('takeMedicine', 'wardRoundOccurrenceId', wardRoundOccurrenceId)
            // verify required parameter 'medicineIntakeDtoIn' is not null or undefined
            assertParamExists('takeMedicine', 'medicineIntakeDtoIn', medicineIntakeDtoIn)
            const localVarPath = `/api/patients/me/ward-rounds/{wardRoundOccurrenceId}/actions/take-medicine`
                .replace(`{${"wardRoundOccurrenceId"}}`, encodeURIComponent(String(wardRoundOccurrenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicineIntakeDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload patient\'s photo for a ward round photo action.
         * @summary Upload patient\'s photo for a ward round photo action.
         * @param {string} wardRoundOccurrenceId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takePhoto: async (wardRoundOccurrenceId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wardRoundOccurrenceId' is not null or undefined
            assertParamExists('takePhoto', 'wardRoundOccurrenceId', wardRoundOccurrenceId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('takePhoto', 'file', file)
            const localVarPath = `/api/patients/me/ward-rounds/{wardRoundOccurrenceId}/actions/take-photo`
                .replace(`{${"wardRoundOccurrenceId"}}`, encodeURIComponent(String(wardRoundOccurrenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload patient\'s photo (as base64) for a ward round photo action.
         * @summary Upload patient\'s photo (as base64) for a ward round photo action.
         * @param {string} wardRoundOccurrenceId
         * @param {PhotoActionResponseDtoIn} photoActionResponseDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takePhotoAsBase64: async (wardRoundOccurrenceId: string, photoActionResponseDtoIn: PhotoActionResponseDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wardRoundOccurrenceId' is not null or undefined
            assertParamExists('takePhotoAsBase64', 'wardRoundOccurrenceId', wardRoundOccurrenceId)
            // verify required parameter 'photoActionResponseDtoIn' is not null or undefined
            assertParamExists('takePhotoAsBase64', 'photoActionResponseDtoIn', photoActionResponseDtoIn)
            const localVarPath = `/api/patients/me/ward-rounds/{wardRoundOccurrenceId}/actions/take-photo/base64`
                .replace(`{${"wardRoundOccurrenceId"}}`, encodeURIComponent(String(wardRoundOccurrenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(photoActionResponseDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Track patient\'s progress on a challenge within ward round.
         * @summary Track patient\'s progress on a challenge within ward round.
         * @param {string} wardRoundOccurrenceId
         * @param {TrackProgressRequest} trackProgressRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackProgress: async (wardRoundOccurrenceId: string, trackProgressRequest: TrackProgressRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wardRoundOccurrenceId' is not null or undefined
            assertParamExists('trackProgress', 'wardRoundOccurrenceId', wardRoundOccurrenceId)
            // verify required parameter 'trackProgressRequest' is not null or undefined
            assertParamExists('trackProgress', 'trackProgressRequest', trackProgressRequest)
            const localVarPath = `/api/patients/me/ward-rounds/{wardRoundOccurrenceId}/challenges/progress`
                .replace(`{${"wardRoundOccurrenceId"}}`, encodeURIComponent(String(wardRoundOccurrenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackProgressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientWardRoundOccurrencesControllerApi - functional programming interface
 * @export
 */
export const PatientWardRoundOccurrencesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientWardRoundOccurrencesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Answer questionnaire associated to a ward round.
         * @summary Answer questionnaire associated to a ward round.
         * @param {string} wardRoundOccurrenceId
         * @param {string} questionnaireId
         * @param {AnsweredQuestionDtoIn} answeredQuestionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answerQuestionnaire(wardRoundOccurrenceId: string, questionnaireId: string, answeredQuestionDtoIn: AnsweredQuestionDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnsweredQuestionnaireDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.answerQuestionnaire(wardRoundOccurrenceId, questionnaireId, answeredQuestionDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mark ward round occurrence as completed.
         * @summary Mark ward round occurrence as completed.
         * @param {string} wardRoundOccurrenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async complete(wardRoundOccurrenceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.complete(wardRoundOccurrenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get current patient\'s ward round occurrence detail.
         * @summary Get current patient\'s ward round occurrence detail.
         * @param {string} id
         * @param {string} occurrenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyWardRoundOccurrence(id: string, occurrenceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WardRoundOccurrenceDetailDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyWardRoundOccurrence(id, occurrenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get patient\'s ward round occurrence detail.
         * @summary Get patient\'s ward round occurrence detail.
         * @param {string} patientId
         * @param {string} wardRoundId
         * @param {string} occurrenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWardRoundOccurrence(patientId: string, wardRoundId: string, occurrenceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WardRoundOccurrenceDetailDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWardRoundOccurrence(patientId, wardRoundId, occurrenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify patient\'s answer to a questionnaire associated to a ward round.
         * @summary Modify patient\'s answer to a questionnaire associated to a ward round.
         * @param {string} patientId
         * @param {string} wardRoundOccurrenceId
         * @param {string} questionnaireId
         * @param {AnsweredQuestionDtoIn} answeredQuestionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyAnswer(patientId: string, wardRoundOccurrenceId: string, questionnaireId: string, answeredQuestionDtoIn: AnsweredQuestionDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnsweredQuestionnaireDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyAnswer(patientId, wardRoundOccurrenceId, questionnaireId, answeredQuestionDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Track patient\'s medicine intake.
         * @summary Track patient\'s medicine intake.
         * @param {string} wardRoundOccurrenceId
         * @param {MedicineIntakeDtoIn} medicineIntakeDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async takeMedicine(wardRoundOccurrenceId: string, medicineIntakeDtoIn: MedicineIntakeDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicineActionResponseDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.takeMedicine(wardRoundOccurrenceId, medicineIntakeDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload patient\'s photo for a ward round photo action.
         * @summary Upload patient\'s photo for a ward round photo action.
         * @param {string} wardRoundOccurrenceId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async takePhoto(wardRoundOccurrenceId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotoActionResponseDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.takePhoto(wardRoundOccurrenceId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload patient\'s photo (as base64) for a ward round photo action.
         * @summary Upload patient\'s photo (as base64) for a ward round photo action.
         * @param {string} wardRoundOccurrenceId
         * @param {PhotoActionResponseDtoIn} photoActionResponseDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async takePhotoAsBase64(wardRoundOccurrenceId: string, photoActionResponseDtoIn: PhotoActionResponseDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotoActionResponseDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.takePhotoAsBase64(wardRoundOccurrenceId, photoActionResponseDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Track patient\'s progress on a challenge within ward round.
         * @summary Track patient\'s progress on a challenge within ward round.
         * @param {string} wardRoundOccurrenceId
         * @param {TrackProgressRequest} trackProgressRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackProgress(wardRoundOccurrenceId: string, trackProgressRequest: TrackProgressRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPatientChallenges200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackProgress(wardRoundOccurrenceId, trackProgressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientWardRoundOccurrencesControllerApi - factory interface
 * @export
 */
export const PatientWardRoundOccurrencesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientWardRoundOccurrencesControllerApiFp(configuration)
    return {
        /**
         * Answer questionnaire associated to a ward round.
         * @summary Answer questionnaire associated to a ward round.
         * @param {string} wardRoundOccurrenceId
         * @param {string} questionnaireId
         * @param {AnsweredQuestionDtoIn} answeredQuestionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerQuestionnaire(wardRoundOccurrenceId: string, questionnaireId: string, answeredQuestionDtoIn: AnsweredQuestionDtoIn, options?: any): AxiosPromise<AnsweredQuestionnaireDtoOut> {
            return localVarFp.answerQuestionnaire(wardRoundOccurrenceId, questionnaireId, answeredQuestionDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark ward round occurrence as completed.
         * @summary Mark ward round occurrence as completed.
         * @param {string} wardRoundOccurrenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complete(wardRoundOccurrenceId: string, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.complete(wardRoundOccurrenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current patient\'s ward round occurrence detail.
         * @summary Get current patient\'s ward round occurrence detail.
         * @param {string} id
         * @param {string} occurrenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWardRoundOccurrence(id: string, occurrenceId: string, options?: any): AxiosPromise<WardRoundOccurrenceDetailDtoOut> {
            return localVarFp.getMyWardRoundOccurrence(id, occurrenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get patient\'s ward round occurrence detail.
         * @summary Get patient\'s ward round occurrence detail.
         * @param {string} patientId
         * @param {string} wardRoundId
         * @param {string} occurrenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRoundOccurrence(patientId: string, wardRoundId: string, occurrenceId: string, options?: any): AxiosPromise<WardRoundOccurrenceDetailDtoOut> {
            return localVarFp.getWardRoundOccurrence(patientId, wardRoundId, occurrenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify patient\'s answer to a questionnaire associated to a ward round.
         * @summary Modify patient\'s answer to a questionnaire associated to a ward round.
         * @param {string} patientId
         * @param {string} wardRoundOccurrenceId
         * @param {string} questionnaireId
         * @param {AnsweredQuestionDtoIn} answeredQuestionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAnswer(patientId: string, wardRoundOccurrenceId: string, questionnaireId: string, answeredQuestionDtoIn: AnsweredQuestionDtoIn, options?: any): AxiosPromise<AnsweredQuestionnaireDtoOut> {
            return localVarFp.modifyAnswer(patientId, wardRoundOccurrenceId, questionnaireId, answeredQuestionDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Track patient\'s medicine intake.
         * @summary Track patient\'s medicine intake.
         * @param {string} wardRoundOccurrenceId
         * @param {MedicineIntakeDtoIn} medicineIntakeDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeMedicine(wardRoundOccurrenceId: string, medicineIntakeDtoIn: MedicineIntakeDtoIn, options?: any): AxiosPromise<MedicineActionResponseDtoOut> {
            return localVarFp.takeMedicine(wardRoundOccurrenceId, medicineIntakeDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload patient\'s photo for a ward round photo action.
         * @summary Upload patient\'s photo for a ward round photo action.
         * @param {string} wardRoundOccurrenceId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takePhoto(wardRoundOccurrenceId: string, file: any, options?: any): AxiosPromise<PhotoActionResponseDtoOut> {
            return localVarFp.takePhoto(wardRoundOccurrenceId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload patient\'s photo (as base64) for a ward round photo action.
         * @summary Upload patient\'s photo (as base64) for a ward round photo action.
         * @param {string} wardRoundOccurrenceId
         * @param {PhotoActionResponseDtoIn} photoActionResponseDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takePhotoAsBase64(wardRoundOccurrenceId: string, photoActionResponseDtoIn: PhotoActionResponseDtoIn, options?: any): AxiosPromise<PhotoActionResponseDtoOut> {
            return localVarFp.takePhotoAsBase64(wardRoundOccurrenceId, photoActionResponseDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Track patient\'s progress on a challenge within ward round.
         * @summary Track patient\'s progress on a challenge within ward round.
         * @param {string} wardRoundOccurrenceId
         * @param {TrackProgressRequest} trackProgressRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackProgress(wardRoundOccurrenceId: string, trackProgressRequest: TrackProgressRequest, options?: any): AxiosPromise<GetPatientChallenges200ResponseInner> {
            return localVarFp.trackProgress(wardRoundOccurrenceId, trackProgressRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientWardRoundOccurrencesControllerApi - object-oriented interface
 * @export
 * @class PatientWardRoundOccurrencesControllerApi
 * @extends {BaseAPI}
 */
export class PatientWardRoundOccurrencesControllerApi extends BaseAPI {
    /**
     * Answer questionnaire associated to a ward round.
     * @summary Answer questionnaire associated to a ward round.
     * @param {string} wardRoundOccurrenceId
     * @param {string} questionnaireId
     * @param {AnsweredQuestionDtoIn} answeredQuestionDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundOccurrencesControllerApi
     */
    public answerQuestionnaire(wardRoundOccurrenceId: string, questionnaireId: string, answeredQuestionDtoIn: AnsweredQuestionDtoIn, options?: AxiosRequestConfig) {
        return PatientWardRoundOccurrencesControllerApiFp(this.configuration).answerQuestionnaire(wardRoundOccurrenceId, questionnaireId, answeredQuestionDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark ward round occurrence as completed.
     * @summary Mark ward round occurrence as completed.
     * @param {string} wardRoundOccurrenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundOccurrencesControllerApi
     */
    public complete(wardRoundOccurrenceId: string, options?: AxiosRequestConfig) {
        return PatientWardRoundOccurrencesControllerApiFp(this.configuration).complete(wardRoundOccurrenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current patient\'s ward round occurrence detail.
     * @summary Get current patient\'s ward round occurrence detail.
     * @param {string} id
     * @param {string} occurrenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundOccurrencesControllerApi
     */
    public getMyWardRoundOccurrence(id: string, occurrenceId: string, options?: AxiosRequestConfig) {
        return PatientWardRoundOccurrencesControllerApiFp(this.configuration).getMyWardRoundOccurrence(id, occurrenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get patient\'s ward round occurrence detail.
     * @summary Get patient\'s ward round occurrence detail.
     * @param {string} patientId
     * @param {string} wardRoundId
     * @param {string} occurrenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundOccurrencesControllerApi
     */
    public getWardRoundOccurrence(patientId: string, wardRoundId: string, occurrenceId: string, options?: AxiosRequestConfig) {
        return PatientWardRoundOccurrencesControllerApiFp(this.configuration).getWardRoundOccurrence(patientId, wardRoundId, occurrenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify patient\'s answer to a questionnaire associated to a ward round.
     * @summary Modify patient\'s answer to a questionnaire associated to a ward round.
     * @param {string} patientId
     * @param {string} wardRoundOccurrenceId
     * @param {string} questionnaireId
     * @param {AnsweredQuestionDtoIn} answeredQuestionDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundOccurrencesControllerApi
     */
    public modifyAnswer(patientId: string, wardRoundOccurrenceId: string, questionnaireId: string, answeredQuestionDtoIn: AnsweredQuestionDtoIn, options?: AxiosRequestConfig) {
        return PatientWardRoundOccurrencesControllerApiFp(this.configuration).modifyAnswer(patientId, wardRoundOccurrenceId, questionnaireId, answeredQuestionDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Track patient\'s medicine intake.
     * @summary Track patient\'s medicine intake.
     * @param {string} wardRoundOccurrenceId
     * @param {MedicineIntakeDtoIn} medicineIntakeDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundOccurrencesControllerApi
     */
    public takeMedicine(wardRoundOccurrenceId: string, medicineIntakeDtoIn: MedicineIntakeDtoIn, options?: AxiosRequestConfig) {
        return PatientWardRoundOccurrencesControllerApiFp(this.configuration).takeMedicine(wardRoundOccurrenceId, medicineIntakeDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload patient\'s photo for a ward round photo action.
     * @summary Upload patient\'s photo for a ward round photo action.
     * @param {string} wardRoundOccurrenceId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundOccurrencesControllerApi
     */
    public takePhoto(wardRoundOccurrenceId: string, file: any, options?: AxiosRequestConfig) {
        return PatientWardRoundOccurrencesControllerApiFp(this.configuration).takePhoto(wardRoundOccurrenceId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload patient\'s photo (as base64) for a ward round photo action.
     * @summary Upload patient\'s photo (as base64) for a ward round photo action.
     * @param {string} wardRoundOccurrenceId
     * @param {PhotoActionResponseDtoIn} photoActionResponseDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundOccurrencesControllerApi
     */
    public takePhotoAsBase64(wardRoundOccurrenceId: string, photoActionResponseDtoIn: PhotoActionResponseDtoIn, options?: AxiosRequestConfig) {
        return PatientWardRoundOccurrencesControllerApiFp(this.configuration).takePhotoAsBase64(wardRoundOccurrenceId, photoActionResponseDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Track patient\'s progress on a challenge within ward round.
     * @summary Track patient\'s progress on a challenge within ward round.
     * @param {string} wardRoundOccurrenceId
     * @param {TrackProgressRequest} trackProgressRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundOccurrencesControllerApi
     */
    public trackProgress(wardRoundOccurrenceId: string, trackProgressRequest: TrackProgressRequest, options?: AxiosRequestConfig) {
        return PatientWardRoundOccurrencesControllerApiFp(this.configuration).trackProgress(wardRoundOccurrenceId, trackProgressRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientWardRoundsControllerApi - axios parameter creator
 * @export
 */
export const PatientWardRoundsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create ward round.
         * @summary Create ward round.
         * @param {string} patientId
         * @param {UpdateWardRoundRequest} updateWardRoundRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWardRound: async (patientId: string, updateWardRoundRequest: UpdateWardRoundRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('createWardRound', 'patientId', patientId)
            // verify required parameter 'updateWardRoundRequest' is not null or undefined
            assertParamExists('createWardRound', 'updateWardRoundRequest', updateWardRoundRequest)
            const localVarPath = `/api/patients/{patientId}/ward-rounds`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWardRoundRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete ward round byt id and patient id.
         * @summary Delete ward round byt id and patient id.
         * @param {string} id
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWardRound: async (id: string, patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWardRound', 'id', id)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('deleteWardRound', 'patientId', patientId)
            const localVarPath = `/api/patients/{patientId}/ward-rounds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current patient\'s ward round detail.
         * @summary Get current patient\'s ward round detail.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWardRound: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMyWardRound', 'id', id)
            const localVarPath = `/api/patients/me/ward-rounds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current patient\'s pending ward rounds.
         * @summary Get current patient\'s pending ward rounds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingWardRounds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patients/me/ward-rounds/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get patient\'s ward round detail.
         * @summary Get patient\'s ward round detail.
         * @param {string} patientId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRound: async (patientId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getWardRound', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWardRound', 'id', id)
            const localVarPath = `/api/patients/{patientId}/ward-rounds/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all patient\'s ward rounds.
         * @summary Get all patient\'s ward rounds.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRounds: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getWardRounds', 'patientId', patientId)
            const localVarPath = `/api/patients/{patientId}/ward-rounds`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current patient\'s ward rounds.
         * @summary Get current patient\'s ward rounds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRounds1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patients/me/ward-rounds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update ward round.
         * @summary Update ward round.
         * @param {string} patientId
         * @param {string} id
         * @param {UpdateWardRoundRequest} updateWardRoundRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWardRound: async (patientId: string, id: string, updateWardRoundRequest: UpdateWardRoundRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updateWardRound', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWardRound', 'id', id)
            // verify required parameter 'updateWardRoundRequest' is not null or undefined
            assertParamExists('updateWardRound', 'updateWardRoundRequest', updateWardRoundRequest)
            const localVarPath = `/api/patients/{patientId}/ward-rounds/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWardRoundRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientWardRoundsControllerApi - functional programming interface
 * @export
 */
export const PatientWardRoundsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientWardRoundsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create ward round.
         * @summary Create ward round.
         * @param {string} patientId
         * @param {UpdateWardRoundRequest} updateWardRoundRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWardRound(patientId: string, updateWardRoundRequest: UpdateWardRoundRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWardRound200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWardRound(patientId, updateWardRoundRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete ward round byt id and patient id.
         * @summary Delete ward round byt id and patient id.
         * @param {string} id
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWardRound(id: string, patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWardRound(id, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get current patient\'s ward round detail.
         * @summary Get current patient\'s ward round detail.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyWardRound(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWardRound200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyWardRound(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get current patient\'s pending ward rounds.
         * @summary Get current patient\'s pending ward rounds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingWardRounds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetWardRound200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingWardRounds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get patient\'s ward round detail.
         * @summary Get patient\'s ward round detail.
         * @param {string} patientId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWardRound(patientId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWardRound200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWardRound(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all patient\'s ward rounds.
         * @summary Get all patient\'s ward rounds.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWardRounds(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetWardRound200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWardRounds(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get current patient\'s ward rounds.
         * @summary Get current patient\'s ward rounds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWardRounds1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetWardRound200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWardRounds1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update ward round.
         * @summary Update ward round.
         * @param {string} patientId
         * @param {string} id
         * @param {UpdateWardRoundRequest} updateWardRoundRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWardRound(patientId: string, id: string, updateWardRoundRequest: UpdateWardRoundRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWardRound200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWardRound(patientId, id, updateWardRoundRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientWardRoundsControllerApi - factory interface
 * @export
 */
export const PatientWardRoundsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientWardRoundsControllerApiFp(configuration)
    return {
        /**
         * Create ward round.
         * @summary Create ward round.
         * @param {string} patientId
         * @param {UpdateWardRoundRequest} updateWardRoundRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWardRound(patientId: string, updateWardRoundRequest: UpdateWardRoundRequest, options?: any): AxiosPromise<GetWardRound200Response> {
            return localVarFp.createWardRound(patientId, updateWardRoundRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete ward round byt id and patient id.
         * @summary Delete ward round byt id and patient id.
         * @param {string} id
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWardRound(id: string, patientId: string, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.deleteWardRound(id, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current patient\'s ward round detail.
         * @summary Get current patient\'s ward round detail.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWardRound(id: string, options?: any): AxiosPromise<GetWardRound200Response> {
            return localVarFp.getMyWardRound(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current patient\'s pending ward rounds.
         * @summary Get current patient\'s pending ward rounds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingWardRounds(options?: any): AxiosPromise<Array<GetWardRound200Response>> {
            return localVarFp.getPendingWardRounds(options).then((request) => request(axios, basePath));
        },
        /**
         * Get patient\'s ward round detail.
         * @summary Get patient\'s ward round detail.
         * @param {string} patientId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRound(patientId: string, id: string, options?: any): AxiosPromise<GetWardRound200Response> {
            return localVarFp.getWardRound(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all patient\'s ward rounds.
         * @summary Get all patient\'s ward rounds.
         * @param {string} patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRounds(patientId: string, options?: any): AxiosPromise<Array<GetWardRound200Response>> {
            return localVarFp.getWardRounds(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current patient\'s ward rounds.
         * @summary Get current patient\'s ward rounds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRounds1(options?: any): AxiosPromise<Array<GetWardRound200Response>> {
            return localVarFp.getWardRounds1(options).then((request) => request(axios, basePath));
        },
        /**
         * Update ward round.
         * @summary Update ward round.
         * @param {string} patientId
         * @param {string} id
         * @param {UpdateWardRoundRequest} updateWardRoundRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWardRound(patientId: string, id: string, updateWardRoundRequest: UpdateWardRoundRequest, options?: any): AxiosPromise<GetWardRound200Response> {
            return localVarFp.updateWardRound(patientId, id, updateWardRoundRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientWardRoundsControllerApi - object-oriented interface
 * @export
 * @class PatientWardRoundsControllerApi
 * @extends {BaseAPI}
 */
export class PatientWardRoundsControllerApi extends BaseAPI {
    /**
     * Create ward round.
     * @summary Create ward round.
     * @param {string} patientId
     * @param {UpdateWardRoundRequest} updateWardRoundRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundsControllerApi
     */
    public createWardRound(patientId: string, updateWardRoundRequest: UpdateWardRoundRequest, options?: AxiosRequestConfig) {
        return PatientWardRoundsControllerApiFp(this.configuration).createWardRound(patientId, updateWardRoundRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete ward round byt id and patient id.
     * @summary Delete ward round byt id and patient id.
     * @param {string} id
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundsControllerApi
     */
    public deleteWardRound(id: string, patientId: string, options?: AxiosRequestConfig) {
        return PatientWardRoundsControllerApiFp(this.configuration).deleteWardRound(id, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current patient\'s ward round detail.
     * @summary Get current patient\'s ward round detail.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundsControllerApi
     */
    public getMyWardRound(id: string, options?: AxiosRequestConfig) {
        return PatientWardRoundsControllerApiFp(this.configuration).getMyWardRound(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current patient\'s pending ward rounds.
     * @summary Get current patient\'s pending ward rounds.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundsControllerApi
     */
    public getPendingWardRounds(options?: AxiosRequestConfig) {
        return PatientWardRoundsControllerApiFp(this.configuration).getPendingWardRounds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get patient\'s ward round detail.
     * @summary Get patient\'s ward round detail.
     * @param {string} patientId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundsControllerApi
     */
    public getWardRound(patientId: string, id: string, options?: AxiosRequestConfig) {
        return PatientWardRoundsControllerApiFp(this.configuration).getWardRound(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all patient\'s ward rounds.
     * @summary Get all patient\'s ward rounds.
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundsControllerApi
     */
    public getWardRounds(patientId: string, options?: AxiosRequestConfig) {
        return PatientWardRoundsControllerApiFp(this.configuration).getWardRounds(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current patient\'s ward rounds.
     * @summary Get current patient\'s ward rounds.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundsControllerApi
     */
    public getWardRounds1(options?: AxiosRequestConfig) {
        return PatientWardRoundsControllerApiFp(this.configuration).getWardRounds1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update ward round.
     * @summary Update ward round.
     * @param {string} patientId
     * @param {string} id
     * @param {UpdateWardRoundRequest} updateWardRoundRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientWardRoundsControllerApi
     */
    public updateWardRound(patientId: string, id: string, updateWardRoundRequest: UpdateWardRoundRequest, options?: AxiosRequestConfig) {
        return PatientWardRoundsControllerApiFp(this.configuration).updateWardRound(patientId, id, updateWardRoundRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PushTokenControllerApi - axios parameter creator
 * @export
 */
export const PushTokenControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Deletes token from the database.
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('deleteToken', 'token', token)
            const localVarPath = `/api/push-token/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *              Registers notification push token. Caller should first initialise local instance              of Firebase and obtain push token from the Firebase SDK.
         * @summary Registers notification push token per user.
         * @param {PushTokenRegistrationDto} pushTokenRegistrationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPushToken: async (pushTokenRegistrationDto: PushTokenRegistrationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pushTokenRegistrationDto' is not null or undefined
            assertParamExists('registerPushToken', 'pushTokenRegistrationDto', pushTokenRegistrationDto)
            const localVarPath = `/api/push-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushTokenRegistrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushTokenControllerApi - functional programming interface
 * @export
 */
export const PushTokenControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PushTokenControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Deletes token from the database.
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenDeletedResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *              Registers notification push token. Caller should first initialise local instance              of Firebase and obtain push token from the Firebase SDK.
         * @summary Registers notification push token per user.
         * @param {PushTokenRegistrationDto} pushTokenRegistrationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerPushToken(pushTokenRegistrationDto: PushTokenRegistrationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushTokenRegistrationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerPushToken(pushTokenRegistrationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PushTokenControllerApi - factory interface
 * @export
 */
export const PushTokenControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PushTokenControllerApiFp(configuration)
    return {
        /**
         *
         * @summary Deletes token from the database.
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken(token: string, options?: any): AxiosPromise<TokenDeletedResponseDto> {
            return localVarFp.deleteToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         *              Registers notification push token. Caller should first initialise local instance              of Firebase and obtain push token from the Firebase SDK.
         * @summary Registers notification push token per user.
         * @param {PushTokenRegistrationDto} pushTokenRegistrationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPushToken(pushTokenRegistrationDto: PushTokenRegistrationDto, options?: any): AxiosPromise<PushTokenRegistrationResponseDto> {
            return localVarFp.registerPushToken(pushTokenRegistrationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PushTokenControllerApi - object-oriented interface
 * @export
 * @class PushTokenControllerApi
 * @extends {BaseAPI}
 */
export class PushTokenControllerApi extends BaseAPI {
    /**
     *
     * @summary Deletes token from the database.
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushTokenControllerApi
     */
    public deleteToken(token: string, options?: AxiosRequestConfig) {
        return PushTokenControllerApiFp(this.configuration).deleteToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *              Registers notification push token. Caller should first initialise local instance              of Firebase and obtain push token from the Firebase SDK.
     * @summary Registers notification push token per user.
     * @param {PushTokenRegistrationDto} pushTokenRegistrationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushTokenControllerApi
     */
    public registerPushToken(pushTokenRegistrationDto: PushTokenRegistrationDto, options?: AxiosRequestConfig) {
        return PushTokenControllerApiFp(this.configuration).registerPushToken(pushTokenRegistrationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionTemplateControllerApi - axios parameter creator
 * @export
 */
export const QuestionTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create question template.
         * @summary Create question template.
         * @param {QuestionTemplateDtoIn} questionTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionTemplate: async (questionTemplateDtoIn: QuestionTemplateDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionTemplateDtoIn' is not null or undefined
            assertParamExists('createQuestionTemplate', 'questionTemplateDtoIn', questionTemplateDtoIn)
            const localVarPath = `/api/templates/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionTemplateDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete question template.
         * @summary Delete question template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteQuestionTemplate', 'id', id)
            const localVarPath = `/api/templates/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get question templates.
         * @summary Get question templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllQuestionTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/templates/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get question template.
         * @summary Get question template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionTemplateDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuestionTemplateDetail', 'id', id)
            const localVarPath = `/api/templates/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update question template.
         * @summary Update question template.
         * @param {string} id
         * @param {QuestionTemplateDtoIn} questionTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionTemplate: async (id: string, questionTemplateDtoIn: QuestionTemplateDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateQuestionTemplate', 'id', id)
            // verify required parameter 'questionTemplateDtoIn' is not null or undefined
            assertParamExists('updateQuestionTemplate', 'questionTemplateDtoIn', questionTemplateDtoIn)
            const localVarPath = `/api/templates/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionTemplateDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionTemplateControllerApi - functional programming interface
 * @export
 */
export const QuestionTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create question template.
         * @summary Create question template.
         * @param {QuestionTemplateDtoIn} questionTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQuestionTemplate(questionTemplateDtoIn: QuestionTemplateDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQuestionTemplate(questionTemplateDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete question template.
         * @summary Delete question template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuestionTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get question templates.
         * @summary Get question templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllQuestionTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionTemplateDtoOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllQuestionTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get question template.
         * @summary Get question template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionTemplateDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionTemplateDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update question template.
         * @summary Update question template.
         * @param {string} id
         * @param {QuestionTemplateDtoIn} questionTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuestionTemplate(id: string, questionTemplateDtoIn: QuestionTemplateDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuestionTemplate(id, questionTemplateDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionTemplateControllerApi - factory interface
 * @export
 */
export const QuestionTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionTemplateControllerApiFp(configuration)
    return {
        /**
         * Create question template.
         * @summary Create question template.
         * @param {QuestionTemplateDtoIn} questionTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionTemplate(questionTemplateDtoIn: QuestionTemplateDtoIn, options?: any): AxiosPromise<QuestionTemplateDtoOut> {
            return localVarFp.createQuestionTemplate(questionTemplateDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete question template.
         * @summary Delete question template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionTemplate(id: string, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.deleteQuestionTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get question templates.
         * @summary Get question templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllQuestionTemplates(options?: any): AxiosPromise<Array<QuestionTemplateDtoOut>> {
            return localVarFp.getAllQuestionTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * Get question template.
         * @summary Get question template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionTemplateDetail(id: string, options?: any): AxiosPromise<QuestionTemplateDtoOut> {
            return localVarFp.getQuestionTemplateDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update question template.
         * @summary Update question template.
         * @param {string} id
         * @param {QuestionTemplateDtoIn} questionTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionTemplate(id: string, questionTemplateDtoIn: QuestionTemplateDtoIn, options?: any): AxiosPromise<QuestionTemplateDtoOut> {
            return localVarFp.updateQuestionTemplate(id, questionTemplateDtoIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionTemplateControllerApi - object-oriented interface
 * @export
 * @class QuestionTemplateControllerApi
 * @extends {BaseAPI}
 */
export class QuestionTemplateControllerApi extends BaseAPI {
    /**
     * Create question template.
     * @summary Create question template.
     * @param {QuestionTemplateDtoIn} questionTemplateDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionTemplateControllerApi
     */
    public createQuestionTemplate(questionTemplateDtoIn: QuestionTemplateDtoIn, options?: AxiosRequestConfig) {
        return QuestionTemplateControllerApiFp(this.configuration).createQuestionTemplate(questionTemplateDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete question template.
     * @summary Delete question template.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionTemplateControllerApi
     */
    public deleteQuestionTemplate(id: string, options?: AxiosRequestConfig) {
        return QuestionTemplateControllerApiFp(this.configuration).deleteQuestionTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get question templates.
     * @summary Get question templates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionTemplateControllerApi
     */
    public getAllQuestionTemplates(options?: AxiosRequestConfig) {
        return QuestionTemplateControllerApiFp(this.configuration).getAllQuestionTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get question template.
     * @summary Get question template.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionTemplateControllerApi
     */
    public getQuestionTemplateDetail(id: string, options?: AxiosRequestConfig) {
        return QuestionTemplateControllerApiFp(this.configuration).getQuestionTemplateDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update question template.
     * @summary Update question template.
     * @param {string} id
     * @param {QuestionTemplateDtoIn} questionTemplateDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionTemplateControllerApi
     */
    public updateQuestionTemplate(id: string, questionTemplateDtoIn: QuestionTemplateDtoIn, options?: AxiosRequestConfig) {
        return QuestionTemplateControllerApiFp(this.configuration).updateQuestionTemplate(id, questionTemplateDtoIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionnaireControllerApi - axios parameter creator
 * @export
 */
export const QuestionnaireControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create question.
         * @summary Create question.
         * @param {QuestionDtoIn} questionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestion: async (questionDtoIn: QuestionDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionDtoIn' is not null or undefined
            assertParamExists('createQuestion', 'questionDtoIn', questionDtoIn)
            const localVarPath = `/api/questionnaires/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create empty questionnaire.
         * @summary Create empty questionnaire.
         * @param {QuestionnaireDtoIn} questionnaireDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaire: async (questionnaireDtoIn: QuestionnaireDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireDtoIn' is not null or undefined
            assertParamExists('createQuestionnaire', 'questionnaireDtoIn', questionnaireDtoIn)
            const localVarPath = `/api/questionnaires`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete question.
         * @summary Delete question.
         * @param {string} questionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion: async (questionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('deleteQuestion', 'questionId', questionId)
            const localVarPath = `/api/questionnaires/questions/{questionId}`
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete questionnaire.
         * @summary Delete questionnaire.
         * @param {string} questionnaireId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnaire: async (questionnaireId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('deleteQuestionnaire', 'questionnaireId', questionnaireId)
            const localVarPath = `/api/questionnaires/{questionnaireId}`
                .replace(`{${"questionnaireId"}}`, encodeURIComponent(String(questionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get questionnaire by id.
         * @summary Get questionnaire by id.
         * @param {string} questionnaireId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaire: async (questionnaireId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('getQuestionnaire', 'questionnaireId', questionnaireId)
            const localVarPath = `/api/questionnaires/{questionnaireId}`
                .replace(`{${"questionnaireId"}}`, encodeURIComponent(String(questionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update question.
         * @summary Update question.
         * @param {string} questionId
         * @param {QuestionDtoIn} questionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestion: async (questionId: string, questionDtoIn: QuestionDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('updateQuestion', 'questionId', questionId)
            // verify required parameter 'questionDtoIn' is not null or undefined
            assertParamExists('updateQuestion', 'questionDtoIn', questionDtoIn)
            const localVarPath = `/api/questionnaires/questions/{questionId}`
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update questionnaire.
         * @summary Update questionnaire.
         * @param {string} questionnaireId
         * @param {QuestionnaireDtoIn} questionnaireDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionnaire: async (questionnaireId: string, questionnaireDtoIn: QuestionnaireDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('updateQuestionnaire', 'questionnaireId', questionnaireId)
            // verify required parameter 'questionnaireDtoIn' is not null or undefined
            assertParamExists('updateQuestionnaire', 'questionnaireDtoIn', questionnaireDtoIn)
            const localVarPath = `/api/questionnaires/{questionnaireId}`
                .replace(`{${"questionnaireId"}}`, encodeURIComponent(String(questionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionnaireControllerApi - functional programming interface
 * @export
 */
export const QuestionnaireControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionnaireControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create question.
         * @summary Create question.
         * @param {QuestionDtoIn} questionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQuestion(questionDtoIn: QuestionDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQuestion(questionDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create empty questionnaire.
         * @summary Create empty questionnaire.
         * @param {QuestionnaireDtoIn} questionnaireDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQuestionnaire(questionnaireDtoIn: QuestionnaireDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQuestionnaire(questionnaireDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete question.
         * @summary Delete question.
         * @param {string} questionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuestion(questionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionDeleteDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestion(questionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete questionnaire.
         * @summary Delete questionnaire.
         * @param {string} questionnaireId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuestionnaire(questionnaireId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionnaire(questionnaireId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get questionnaire by id.
         * @summary Get questionnaire by id.
         * @param {string} questionnaireId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionnaire(questionnaireId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionnaire(questionnaireId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update question.
         * @summary Update question.
         * @param {string} questionId
         * @param {QuestionDtoIn} questionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuestion(questionId: string, questionDtoIn: QuestionDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuestion(questionId, questionDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update questionnaire.
         * @summary Update questionnaire.
         * @param {string} questionnaireId
         * @param {QuestionnaireDtoIn} questionnaireDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuestionnaire(questionnaireId: string, questionnaireDtoIn: QuestionnaireDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuestionnaire(questionnaireId, questionnaireDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionnaireControllerApi - factory interface
 * @export
 */
export const QuestionnaireControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionnaireControllerApiFp(configuration)
    return {
        /**
         * Create question.
         * @summary Create question.
         * @param {QuestionDtoIn} questionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestion(questionDtoIn: QuestionDtoIn, options?: any): AxiosPromise<QuestionDtoOut> {
            return localVarFp.createQuestion(questionDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Create empty questionnaire.
         * @summary Create empty questionnaire.
         * @param {QuestionnaireDtoIn} questionnaireDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaire(questionnaireDtoIn: QuestionnaireDtoIn, options?: any): AxiosPromise<QuestionnaireDtoOut> {
            return localVarFp.createQuestionnaire(questionnaireDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete question.
         * @summary Delete question.
         * @param {string} questionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion(questionId: string, options?: any): AxiosPromise<QuestionDeleteDtoOut> {
            return localVarFp.deleteQuestion(questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete questionnaire.
         * @summary Delete questionnaire.
         * @param {string} questionnaireId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnaire(questionnaireId: string, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.deleteQuestionnaire(questionnaireId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get questionnaire by id.
         * @summary Get questionnaire by id.
         * @param {string} questionnaireId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaire(questionnaireId: string, options?: any): AxiosPromise<QuestionnaireDtoOut> {
            return localVarFp.getQuestionnaire(questionnaireId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update question.
         * @summary Update question.
         * @param {string} questionId
         * @param {QuestionDtoIn} questionDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestion(questionId: string, questionDtoIn: QuestionDtoIn, options?: any): AxiosPromise<QuestionDtoOut> {
            return localVarFp.updateQuestion(questionId, questionDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Update questionnaire.
         * @summary Update questionnaire.
         * @param {string} questionnaireId
         * @param {QuestionnaireDtoIn} questionnaireDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionnaire(questionnaireId: string, questionnaireDtoIn: QuestionnaireDtoIn, options?: any): AxiosPromise<QuestionnaireDtoOut> {
            return localVarFp.updateQuestionnaire(questionnaireId, questionnaireDtoIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionnaireControllerApi - object-oriented interface
 * @export
 * @class QuestionnaireControllerApi
 * @extends {BaseAPI}
 */
export class QuestionnaireControllerApi extends BaseAPI {
    /**
     * Create question.
     * @summary Create question.
     * @param {QuestionDtoIn} questionDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireControllerApi
     */
    public createQuestion(questionDtoIn: QuestionDtoIn, options?: AxiosRequestConfig) {
        return QuestionnaireControllerApiFp(this.configuration).createQuestion(questionDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create empty questionnaire.
     * @summary Create empty questionnaire.
     * @param {QuestionnaireDtoIn} questionnaireDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireControllerApi
     */
    public createQuestionnaire(questionnaireDtoIn: QuestionnaireDtoIn, options?: AxiosRequestConfig) {
        return QuestionnaireControllerApiFp(this.configuration).createQuestionnaire(questionnaireDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete question.
     * @summary Delete question.
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireControllerApi
     */
    public deleteQuestion(questionId: string, options?: AxiosRequestConfig) {
        return QuestionnaireControllerApiFp(this.configuration).deleteQuestion(questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete questionnaire.
     * @summary Delete questionnaire.
     * @param {string} questionnaireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireControllerApi
     */
    public deleteQuestionnaire(questionnaireId: string, options?: AxiosRequestConfig) {
        return QuestionnaireControllerApiFp(this.configuration).deleteQuestionnaire(questionnaireId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get questionnaire by id.
     * @summary Get questionnaire by id.
     * @param {string} questionnaireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireControllerApi
     */
    public getQuestionnaire(questionnaireId: string, options?: AxiosRequestConfig) {
        return QuestionnaireControllerApiFp(this.configuration).getQuestionnaire(questionnaireId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update question.
     * @summary Update question.
     * @param {string} questionId
     * @param {QuestionDtoIn} questionDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireControllerApi
     */
    public updateQuestion(questionId: string, questionDtoIn: QuestionDtoIn, options?: AxiosRequestConfig) {
        return QuestionnaireControllerApiFp(this.configuration).updateQuestion(questionId, questionDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update questionnaire.
     * @summary Update questionnaire.
     * @param {string} questionnaireId
     * @param {QuestionnaireDtoIn} questionnaireDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireControllerApi
     */
    public updateQuestionnaire(questionnaireId: string, questionnaireDtoIn: QuestionnaireDtoIn, options?: AxiosRequestConfig) {
        return QuestionnaireControllerApiFp(this.configuration).updateQuestionnaire(questionnaireId, questionnaireDtoIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionnaireTemplateControllerApi - axios parameter creator
 * @export
 */
export const QuestionnaireTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create questionnaire template.
         * @summary Create questionnaire template.
         * @param {QuestionnaireTemplateDtoIn} questionnaireTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaireTemplate: async (questionnaireTemplateDtoIn: QuestionnaireTemplateDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireTemplateDtoIn' is not null or undefined
            assertParamExists('createQuestionnaireTemplate', 'questionnaireTemplateDtoIn', questionnaireTemplateDtoIn)
            const localVarPath = `/api/templates/questionnaires`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireTemplateDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete questionnaire template.
         * @summary Delete questionnaire template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnaireTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteQuestionnaireTemplate', 'id', id)
            const localVarPath = `/api/templates/questionnaires/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get questionnaire templates.
         * @summary Get questionnaire templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllQuestionnaireTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/templates/questionnaires`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get questionnaire template.
         * @summary Get questionnaire template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaireTemplateDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuestionnaireTemplateDetail', 'id', id)
            const localVarPath = `/api/templates/questionnaires/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update questionnaire template.
         * @summary Update questionnaire template.
         * @param {string} id
         * @param {QuestionnaireTemplateDtoIn} questionnaireTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionnaireTemplate: async (id: string, questionnaireTemplateDtoIn: QuestionnaireTemplateDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateQuestionnaireTemplate', 'id', id)
            // verify required parameter 'questionnaireTemplateDtoIn' is not null or undefined
            assertParamExists('updateQuestionnaireTemplate', 'questionnaireTemplateDtoIn', questionnaireTemplateDtoIn)
            const localVarPath = `/api/templates/questionnaires/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireTemplateDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionnaireTemplateControllerApi - functional programming interface
 * @export
 */
export const QuestionnaireTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionnaireTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create questionnaire template.
         * @summary Create questionnaire template.
         * @param {QuestionnaireTemplateDtoIn} questionnaireTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQuestionnaireTemplate(questionnaireTemplateDtoIn: QuestionnaireTemplateDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQuestionnaireTemplate(questionnaireTemplateDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete questionnaire template.
         * @summary Delete questionnaire template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuestionnaireTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionnaireTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get questionnaire templates.
         * @summary Get questionnaire templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllQuestionnaireTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionnaireTemplateDtoOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllQuestionnaireTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get questionnaire template.
         * @summary Get questionnaire template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionnaireTemplateDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionnaireTemplateDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update questionnaire template.
         * @summary Update questionnaire template.
         * @param {string} id
         * @param {QuestionnaireTemplateDtoIn} questionnaireTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuestionnaireTemplate(id: string, questionnaireTemplateDtoIn: QuestionnaireTemplateDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuestionnaireTemplate(id, questionnaireTemplateDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionnaireTemplateControllerApi - factory interface
 * @export
 */
export const QuestionnaireTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionnaireTemplateControllerApiFp(configuration)
    return {
        /**
         * Create questionnaire template.
         * @summary Create questionnaire template.
         * @param {QuestionnaireTemplateDtoIn} questionnaireTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaireTemplate(questionnaireTemplateDtoIn: QuestionnaireTemplateDtoIn, options?: any): AxiosPromise<QuestionnaireTemplateDtoOut> {
            return localVarFp.createQuestionnaireTemplate(questionnaireTemplateDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete questionnaire template.
         * @summary Delete questionnaire template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnaireTemplate(id: string, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.deleteQuestionnaireTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get questionnaire templates.
         * @summary Get questionnaire templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllQuestionnaireTemplates(options?: any): AxiosPromise<Array<QuestionnaireTemplateDtoOut>> {
            return localVarFp.getAllQuestionnaireTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * Get questionnaire template.
         * @summary Get questionnaire template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaireTemplateDetail(id: string, options?: any): AxiosPromise<QuestionnaireTemplateDtoOut> {
            return localVarFp.getQuestionnaireTemplateDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update questionnaire template.
         * @summary Update questionnaire template.
         * @param {string} id
         * @param {QuestionnaireTemplateDtoIn} questionnaireTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionnaireTemplate(id: string, questionnaireTemplateDtoIn: QuestionnaireTemplateDtoIn, options?: any): AxiosPromise<QuestionnaireTemplateDtoOut> {
            return localVarFp.updateQuestionnaireTemplate(id, questionnaireTemplateDtoIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionnaireTemplateControllerApi - object-oriented interface
 * @export
 * @class QuestionnaireTemplateControllerApi
 * @extends {BaseAPI}
 */
export class QuestionnaireTemplateControllerApi extends BaseAPI {
    /**
     * Create questionnaire template.
     * @summary Create questionnaire template.
     * @param {QuestionnaireTemplateDtoIn} questionnaireTemplateDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireTemplateControllerApi
     */
    public createQuestionnaireTemplate(questionnaireTemplateDtoIn: QuestionnaireTemplateDtoIn, options?: AxiosRequestConfig) {
        return QuestionnaireTemplateControllerApiFp(this.configuration).createQuestionnaireTemplate(questionnaireTemplateDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete questionnaire template.
     * @summary Delete questionnaire template.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireTemplateControllerApi
     */
    public deleteQuestionnaireTemplate(id: string, options?: AxiosRequestConfig) {
        return QuestionnaireTemplateControllerApiFp(this.configuration).deleteQuestionnaireTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get questionnaire templates.
     * @summary Get questionnaire templates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireTemplateControllerApi
     */
    public getAllQuestionnaireTemplates(options?: AxiosRequestConfig) {
        return QuestionnaireTemplateControllerApiFp(this.configuration).getAllQuestionnaireTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get questionnaire template.
     * @summary Get questionnaire template.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireTemplateControllerApi
     */
    public getQuestionnaireTemplateDetail(id: string, options?: AxiosRequestConfig) {
        return QuestionnaireTemplateControllerApiFp(this.configuration).getQuestionnaireTemplateDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update questionnaire template.
     * @summary Update questionnaire template.
     * @param {string} id
     * @param {QuestionnaireTemplateDtoIn} questionnaireTemplateDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireTemplateControllerApi
     */
    public updateQuestionnaireTemplate(id: string, questionnaireTemplateDtoIn: QuestionnaireTemplateDtoIn, options?: AxiosRequestConfig) {
        return QuestionnaireTemplateControllerApiFp(this.configuration).updateQuestionnaireTemplate(id, questionnaireTemplateDtoIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagCategoryControllerApi - axios parameter creator
 * @export
 */
export const TagCategoryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new tag category.
         * @summary Create a new tag category.
         * @param {TagCategoryDtoIn} tagCategoryDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagCategory: async (tagCategoryDtoIn: TagCategoryDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagCategoryDtoIn' is not null or undefined
            assertParamExists('createTagCategory', 'tagCategoryDtoIn', tagCategoryDtoIn)
            const localVarPath = `/api/tags/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagCategoryDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a tag category.
         * @summary Delete a tag category.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagCategory: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTagCategory', 'id', id)
            const localVarPath = `/api/tags/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all tag categories.
         * @summary Get all tag categories.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tags/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific tag category by its ID.
         * @summary Get a tag category by ID.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagCategoryById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTagCategoryById', 'id', id)
            const localVarPath = `/api/tags/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing tag category.
         * @summary Update an existing tag category.
         * @param {string} id
         * @param {TagCategoryDtoIn} tagCategoryDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagCategory: async (id: string, tagCategoryDtoIn: TagCategoryDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTagCategory', 'id', id)
            // verify required parameter 'tagCategoryDtoIn' is not null or undefined
            assertParamExists('updateTagCategory', 'tagCategoryDtoIn', tagCategoryDtoIn)
            const localVarPath = `/api/tags/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagCategoryDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagCategoryControllerApi - functional programming interface
 * @export
 */
export const TagCategoryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagCategoryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new tag category.
         * @summary Create a new tag category.
         * @param {TagCategoryDtoIn} tagCategoryDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTagCategory(tagCategoryDtoIn: TagCategoryDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagCategoryDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTagCategory(tagCategoryDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a tag category.
         * @summary Delete a tag category.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTagCategory(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTagCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all tag categories.
         * @summary Get all tag categories.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTagCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagCategoryDtoOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTagCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a specific tag category by its ID.
         * @summary Get a tag category by ID.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagCategoryById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagCategoryDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagCategoryById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing tag category.
         * @summary Update an existing tag category.
         * @param {string} id
         * @param {TagCategoryDtoIn} tagCategoryDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTagCategory(id: string, tagCategoryDtoIn: TagCategoryDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagCategoryDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTagCategory(id, tagCategoryDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagCategoryControllerApi - factory interface
 * @export
 */
export const TagCategoryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagCategoryControllerApiFp(configuration)
    return {
        /**
         * Create a new tag category.
         * @summary Create a new tag category.
         * @param {TagCategoryDtoIn} tagCategoryDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagCategory(tagCategoryDtoIn: TagCategoryDtoIn, options?: any): AxiosPromise<TagCategoryDtoOut> {
            return localVarFp.createTagCategory(tagCategoryDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a tag category.
         * @summary Delete a tag category.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagCategory(id: string, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.deleteTagCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all tag categories.
         * @summary Get all tag categories.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagCategories(options?: any): AxiosPromise<Array<TagCategoryDtoOut>> {
            return localVarFp.getAllTagCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific tag category by its ID.
         * @summary Get a tag category by ID.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagCategoryById(id: string, options?: any): AxiosPromise<TagCategoryDtoOut> {
            return localVarFp.getTagCategoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing tag category.
         * @summary Update an existing tag category.
         * @param {string} id
         * @param {TagCategoryDtoIn} tagCategoryDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagCategory(id: string, tagCategoryDtoIn: TagCategoryDtoIn, options?: any): AxiosPromise<TagCategoryDtoOut> {
            return localVarFp.updateTagCategory(id, tagCategoryDtoIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagCategoryControllerApi - object-oriented interface
 * @export
 * @class TagCategoryControllerApi
 * @extends {BaseAPI}
 */
export class TagCategoryControllerApi extends BaseAPI {
    /**
     * Create a new tag category.
     * @summary Create a new tag category.
     * @param {TagCategoryDtoIn} tagCategoryDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagCategoryControllerApi
     */
    public createTagCategory(tagCategoryDtoIn: TagCategoryDtoIn, options?: AxiosRequestConfig) {
        return TagCategoryControllerApiFp(this.configuration).createTagCategory(tagCategoryDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a tag category.
     * @summary Delete a tag category.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagCategoryControllerApi
     */
    public deleteTagCategory(id: string, options?: AxiosRequestConfig) {
        return TagCategoryControllerApiFp(this.configuration).deleteTagCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all tag categories.
     * @summary Get all tag categories.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagCategoryControllerApi
     */
    public getAllTagCategories(options?: AxiosRequestConfig) {
        return TagCategoryControllerApiFp(this.configuration).getAllTagCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a specific tag category by its ID.
     * @summary Get a tag category by ID.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagCategoryControllerApi
     */
    public getTagCategoryById(id: string, options?: AxiosRequestConfig) {
        return TagCategoryControllerApiFp(this.configuration).getTagCategoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing tag category.
     * @summary Update an existing tag category.
     * @param {string} id
     * @param {TagCategoryDtoIn} tagCategoryDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagCategoryControllerApi
     */
    public updateTagCategory(id: string, tagCategoryDtoIn: TagCategoryDtoIn, options?: AxiosRequestConfig) {
        return TagCategoryControllerApiFp(this.configuration).updateTagCategory(id, tagCategoryDtoIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagControllerApi - axios parameter creator
 * @export
 */
export const TagControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new tag.
         * @summary Create a new tag.
         * @param {TagDtoIn} tagDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag: async (tagDtoIn: TagDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagDtoIn' is not null or undefined
            assertParamExists('createTag', 'tagDtoIn', tagDtoIn)
            const localVarPath = `/api/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a tag.
         * @summary Delete a tag.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTag', 'id', id)
            const localVarPath = `/api/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all tags.
         * @summary Get all tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific tag by its ID.
         * @summary Get a tag by ID.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTagById', 'id', id)
            const localVarPath = `/api/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing tag.
         * @summary Update an existing tag.
         * @param {string} id
         * @param {TagDtoIn} tagDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: async (id: string, tagDtoIn: TagDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTag', 'id', id)
            // verify required parameter 'tagDtoIn' is not null or undefined
            assertParamExists('updateTag', 'tagDtoIn', tagDtoIn)
            const localVarPath = `/api/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagControllerApi - functional programming interface
 * @export
 */
export const TagControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new tag.
         * @summary Create a new tag.
         * @param {TagDtoIn} tagDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTag(tagDtoIn: TagDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTag(tagDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a tag.
         * @summary Delete a tag.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTag(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTag(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all tags.
         * @summary Get all tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagDtoOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a specific tag by its ID.
         * @summary Get a tag by ID.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing tag.
         * @summary Update an existing tag.
         * @param {string} id
         * @param {TagDtoIn} tagDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTag(id: string, tagDtoIn: TagDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTag(id, tagDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagControllerApi - factory interface
 * @export
 */
export const TagControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagControllerApiFp(configuration)
    return {
        /**
         * Create a new tag.
         * @summary Create a new tag.
         * @param {TagDtoIn} tagDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag(tagDtoIn: TagDtoIn, options?: any): AxiosPromise<TagDtoOut> {
            return localVarFp.createTag(tagDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a tag.
         * @summary Delete a tag.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag(id: string, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.deleteTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all tags.
         * @summary Get all tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags(options?: any): AxiosPromise<Array<TagDtoOut>> {
            return localVarFp.getAllTags(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific tag by its ID.
         * @summary Get a tag by ID.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagById(id: string, options?: any): AxiosPromise<TagDtoOut> {
            return localVarFp.getTagById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing tag.
         * @summary Update an existing tag.
         * @param {string} id
         * @param {TagDtoIn} tagDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag(id: string, tagDtoIn: TagDtoIn, options?: any): AxiosPromise<TagDtoOut> {
            return localVarFp.updateTag(id, tagDtoIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagControllerApi - object-oriented interface
 * @export
 * @class TagControllerApi
 * @extends {BaseAPI}
 */
export class TagControllerApi extends BaseAPI {
    /**
     * Create a new tag.
     * @summary Create a new tag.
     * @param {TagDtoIn} tagDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagControllerApi
     */
    public createTag(tagDtoIn: TagDtoIn, options?: AxiosRequestConfig) {
        return TagControllerApiFp(this.configuration).createTag(tagDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a tag.
     * @summary Delete a tag.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagControllerApi
     */
    public deleteTag(id: string, options?: AxiosRequestConfig) {
        return TagControllerApiFp(this.configuration).deleteTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all tags.
     * @summary Get all tags.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagControllerApi
     */
    public getAllTags(options?: AxiosRequestConfig) {
        return TagControllerApiFp(this.configuration).getAllTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a specific tag by its ID.
     * @summary Get a tag by ID.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagControllerApi
     */
    public getTagById(id: string, options?: AxiosRequestConfig) {
        return TagControllerApiFp(this.configuration).getTagById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing tag.
     * @summary Update an existing tag.
     * @param {string} id
     * @param {TagDtoIn} tagDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagControllerApi
     */
    public updateTag(id: string, tagDtoIn: TagDtoIn, options?: AxiosRequestConfig) {
        return TagControllerApiFp(this.configuration).updateTag(id, tagDtoIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WardRoundTemplateControllerApi - axios parameter creator
 * @export
 */
export const WardRoundTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create ward round template.
         * @summary Create ward round template.
         * @param {WardRoundTemplateDtoIn} wardRoundTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWardRoundTemplate: async (wardRoundTemplateDtoIn: WardRoundTemplateDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wardRoundTemplateDtoIn' is not null or undefined
            assertParamExists('createWardRoundTemplate', 'wardRoundTemplateDtoIn', wardRoundTemplateDtoIn)
            const localVarPath = `/api/templates/ward-rounds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wardRoundTemplateDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete ward round template.
         * @summary Delete ward round template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWardRoundTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWardRoundTemplate', 'id', id)
            const localVarPath = `/api/templates/ward-rounds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get ward round templates.
         * @summary Get ward round templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWardRoundTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/templates/ward-rounds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get ward round template.
         * @summary Get ward round template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRoundTemplateDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWardRoundTemplateDetail', 'id', id)
            const localVarPath = `/api/templates/ward-rounds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update ward round template.
         * @summary Update ward round template.
         * @param {string} id
         * @param {WardRoundTemplateDtoIn} wardRoundTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWardRoundTemplate: async (id: string, wardRoundTemplateDtoIn: WardRoundTemplateDtoIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWardRoundTemplate', 'id', id)
            // verify required parameter 'wardRoundTemplateDtoIn' is not null or undefined
            assertParamExists('updateWardRoundTemplate', 'wardRoundTemplateDtoIn', wardRoundTemplateDtoIn)
            const localVarPath = `/api/templates/ward-rounds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "ApiKey", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wardRoundTemplateDtoIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WardRoundTemplateControllerApi - functional programming interface
 * @export
 */
export const WardRoundTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WardRoundTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create ward round template.
         * @summary Create ward round template.
         * @param {WardRoundTemplateDtoIn} wardRoundTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWardRoundTemplate(wardRoundTemplateDtoIn: WardRoundTemplateDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WardRoundTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWardRoundTemplate(wardRoundTemplateDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete ward round template.
         * @summary Delete ward round template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWardRoundTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWardRoundTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get ward round templates.
         * @summary Get ward round templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWardRoundTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WardRoundTemplateDtoOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWardRoundTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get ward round template.
         * @summary Get ward round template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWardRoundTemplateDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WardRoundTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWardRoundTemplateDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update ward round template.
         * @summary Update ward round template.
         * @param {string} id
         * @param {WardRoundTemplateDtoIn} wardRoundTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWardRoundTemplate(id: string, wardRoundTemplateDtoIn: WardRoundTemplateDtoIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WardRoundTemplateDtoOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWardRoundTemplate(id, wardRoundTemplateDtoIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WardRoundTemplateControllerApi - factory interface
 * @export
 */
export const WardRoundTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WardRoundTemplateControllerApiFp(configuration)
    return {
        /**
         * Create ward round template.
         * @summary Create ward round template.
         * @param {WardRoundTemplateDtoIn} wardRoundTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWardRoundTemplate(wardRoundTemplateDtoIn: WardRoundTemplateDtoIn, options?: any): AxiosPromise<WardRoundTemplateDtoOut> {
            return localVarFp.createWardRoundTemplate(wardRoundTemplateDtoIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete ward round template.
         * @summary Delete ward round template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWardRoundTemplate(id: string, options?: any): AxiosPromise<ResponseDto> {
            return localVarFp.deleteWardRoundTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get ward round templates.
         * @summary Get ward round templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWardRoundTemplates(options?: any): AxiosPromise<Array<WardRoundTemplateDtoOut>> {
            return localVarFp.getAllWardRoundTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * Get ward round template.
         * @summary Get ward round template.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWardRoundTemplateDetail(id: string, options?: any): AxiosPromise<WardRoundTemplateDtoOut> {
            return localVarFp.getWardRoundTemplateDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update ward round template.
         * @summary Update ward round template.
         * @param {string} id
         * @param {WardRoundTemplateDtoIn} wardRoundTemplateDtoIn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWardRoundTemplate(id: string, wardRoundTemplateDtoIn: WardRoundTemplateDtoIn, options?: any): AxiosPromise<WardRoundTemplateDtoOut> {
            return localVarFp.updateWardRoundTemplate(id, wardRoundTemplateDtoIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WardRoundTemplateControllerApi - object-oriented interface
 * @export
 * @class WardRoundTemplateControllerApi
 * @extends {BaseAPI}
 */
export class WardRoundTemplateControllerApi extends BaseAPI {
    /**
     * Create ward round template.
     * @summary Create ward round template.
     * @param {WardRoundTemplateDtoIn} wardRoundTemplateDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WardRoundTemplateControllerApi
     */
    public createWardRoundTemplate(wardRoundTemplateDtoIn: WardRoundTemplateDtoIn, options?: AxiosRequestConfig) {
        return WardRoundTemplateControllerApiFp(this.configuration).createWardRoundTemplate(wardRoundTemplateDtoIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete ward round template.
     * @summary Delete ward round template.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WardRoundTemplateControllerApi
     */
    public deleteWardRoundTemplate(id: string, options?: AxiosRequestConfig) {
        return WardRoundTemplateControllerApiFp(this.configuration).deleteWardRoundTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get ward round templates.
     * @summary Get ward round templates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WardRoundTemplateControllerApi
     */
    public getAllWardRoundTemplates(options?: AxiosRequestConfig) {
        return WardRoundTemplateControllerApiFp(this.configuration).getAllWardRoundTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get ward round template.
     * @summary Get ward round template.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WardRoundTemplateControllerApi
     */
    public getWardRoundTemplateDetail(id: string, options?: AxiosRequestConfig) {
        return WardRoundTemplateControllerApiFp(this.configuration).getWardRoundTemplateDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update ward round template.
     * @summary Update ward round template.
     * @param {string} id
     * @param {WardRoundTemplateDtoIn} wardRoundTemplateDtoIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WardRoundTemplateControllerApi
     */
    public updateWardRoundTemplate(id: string, wardRoundTemplateDtoIn: WardRoundTemplateDtoIn, options?: AxiosRequestConfig) {
        return WardRoundTemplateControllerApiFp(this.configuration).updateWardRoundTemplate(id, wardRoundTemplateDtoIn, options).then((request) => request(this.axios, this.basePath));
    }
}


