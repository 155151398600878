import { Box, Stack, SxProps } from "@mui/material";
import { dataGridHideHeader, dataGridStyles, outerBoxStyles } from "../../../theme/overrides/MuiDatagridOverrides";
import { DataGrid, GridColDef, GridRowId, GridRowIdGetter, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { PaginationProps } from "../../../model/PaginationProps";

interface Props<R extends GridValidRowModel> extends Partial<PaginationProps> {
  rows?: GridRowsProp<R>;
  columns: GridColDef<R>[];
  selectedIds?: GridRowId[];
  onSelectedIdsChange?: (ids: string[]) => void;
  hideHeader?: boolean;
  getRowId?: GridRowIdGetter<R>;
  noRowsMessage?: string;
  showCheckbox?: boolean;
}

export const AbstractDataGrid = <R,>({
  rows,
  columns,
  hideHeader = false,
  showCheckbox = false,
  ...props
}: Props<R>) => {
  rows = rows ?? [];
  let gridStyle = dataGridStyles;

  if (hideHeader) {
    gridStyle = { ...gridStyle, ...dataGridHideHeader } as SxProps;
  }

  if (!rows.length) {
    return (
      <Stack justifyContent={"center"} alignItems={"center"} height={"50px"} bgcolor={"white"} borderRadius={1}>
        {props.noRowsMessage ?? "Žádná data"}
      </Stack>
    );
  }

  return (
    <Box sx={outerBoxStyles}>
      <DataGrid
        rows={rows}
        columns={columns}
        sx={gridStyle}
        getRowSpacing={() => ({
          top: 5,
          bottom: 5,
        })}
        rowSelectionModel={props.selectedIds}
        onRowSelectionModelChange={(rowIds) => {
          if (props.onSelectedIdsChange) {
            const parsedRowIds = rowIds.map((id) => String(id));
            props.onSelectedIdsChange(parsedRowIds);
          }
        }}
        getRowId={props.getRowId}
        checkboxSelection={showCheckbox}
        autoHeight
        disableColumnMenu
        disableColumnSorting
        disableRowSelectionOnClick
        hideFooter={props.page === undefined}
        columnHeaderHeight={40}
        {...(props.page !== undefined
          ? {
              pagination: true,
              paginationMode: "server",
              rowCount: props.totalElements,
              paginationModel: { page: props.page, pageSize: props.pageSize ?? 10 },
              onPaginationModelChange: props.onPaginationChange,
              pageSizeOptions: [10, 20, 50, 100],
            }
          : {})}
      />
    </Box>
  );
};
